import { Fragment, useMemo, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BadgeCheckIcon, ClipboardCopyIcon, RefreshIcon, SearchIcon, UserAddIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { transformResponseToSingle } from '../../../Utility/transformer'
import { useCreateCompanySigner } from 'Data/CompanySigner'

export default function ModalCreateOfficer({ show, company, onSuccess, onClose }) {
    const cancelButtonRef = useRef(null)
    const [officerName, setOfficerName] = useState('')
    const [officerPosition, setOfficerPosition] = useState('')
    const [officerEmail, setOfficerEmail] = useState('')
    const [officerPhone, setOfficerPhone] = useState('')
    const [password, setPassword] = useState('')
    const createCompanySigner = useCreateCompanySigner({ onSuccess: (response) => onSuccess(transformResponseToSingle(response)) })

    const submit = () => {
        createCompanySigner.mutate({
            officerName, officerPosition, officerPhone,
            officerEmail, password
        })
    }

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <UserAddIcon className="h-6 w-6 text-cyan-500" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Membuat Penanggung Jawab
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Pastikan email yang akan digunakan belum pernah digunakan sebelumnya dalam sistem. jika gagal membuat, silakan hubungi administrator.
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='py-5 px-3'>
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <span className="px-3 bg-white text-lg font-medium text-gray-900">Informasi Dasar</span>
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            Nama Lengkap*
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={'Silakan isi nama lengkap PJ'}
                                            value={officerName}
                                            onChange={({ target: { value } }) => setOfficerName(value)}
                                        />
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            Posisi/Jabatan
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={`Posisi atau jabatan PJ di perusahaan ${company.name}`}
                                            value={officerPosition}
                                            onChange={({ target: { value } }) => setOfficerPosition(value)}
                                        />
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            No. Telp/HP
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={`No telepon/HP PJ di ${company.name}, diawali dengan +62/Kode Negara`}
                                            value={officerPhone}
                                            onChange={({ target: { value } }) => setOfficerPhone(value)}
                                        />
                                    </div>
                                </div>
                                <div className="relative mt-10">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <span className="px-3 bg-white text-lg font-medium text-gray-900">Akses</span>
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            E-mail*
                                        </label>
                                        <input
                                            type="email"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={`Email PJ di ${company.name}`}
                                            value={officerEmail}
                                            onChange={({ target: { value } }) => setOfficerEmail(value)}
                                        />
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            Kata Sandi*
                                        </label>
                                        <input
                                            type="password"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={`Kata sandi untuk masuk ke dalam sistem`}
                                            value={password}
                                            onChange={({ target: { value } }) => setPassword(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    <button
                                        type="button"
                                        disabled={createCompanySigner.isLoading}
                                        className={classNames(
                                            "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:w-auto sm:text-sm",
                                            createCompanySigner.isLoading ? "bg-gray-600 text-white hover:bg-gray-700" : "bg-cyan-600 text-white hover:bg-cyan-700"
                                        )}
                                        onClick={submit}
                                    >
                                        {createCompanySigner.isLoading && <RefreshIcon className='h-4 w-4 animate-spin text-white' />}
                                        {createCompanySigner.isLoading ? 'Memproses ...' : 'Membuat'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
