import axios from "axios"
import { stringify } from 'query-string'
import { authenticationProvider } from "./Authentication"

export function requestPost(path, payload, headers = {}) {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_BASE_URL_API}${path}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...(authenticationProvider.hasRoleThen((role) => ({ Role: role }), {})),
                ...(authenticationProvider.hasTokenThen((token) => ({ Authorization: "Bearer " + token }), {})),
                ...headers
            }
        })
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}

export function requestDelete(path, parameter = {}, headers = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.REACT_APP_BASE_URL_API}${path}?${stringify(parameter)}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...(authenticationProvider.hasRoleThen((role) => ({ Role: role }), {})),
                ...(authenticationProvider.hasTokenThen((token) => ({ Authorization: "Bearer " + token }), {})),
                ...headers
            },
        })
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}

export function requestGet(path, parameter, headers = {}) {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_BASE_URL_API}${path}?${stringify(parameter)}`, {
            headers: {
                ...(authenticationProvider.hasRoleThen((role) => ({ Role: role }), {})),
                ...(authenticationProvider.hasTokenThen((token) => ({ Authorization: "Bearer " + token }), {})),
                ...headers
            }
        })
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}