import ContractEditorLayout from "Layout/ContractEditorLayout"
import { useContractContext } from "../ContractEditor"
import { CheckCircleIcon, CodeIcon, RefreshIcon } from '@heroicons/react/outline'
import classNames from "classnames"
import { useState } from "react"
import { useAssignContractNumber } from "Data/Contract"
import { authenticationProvider } from "Provider/Authentication"



export default function AssignContractNumber() {
    const { dataContract, contract } = useContractContext()
    const first = contract.contractSigners.find(contractSigner => contractSigner.order == 1)
    const second = contract.contractSigners.find(contractSigner => contractSigner.order == 2)


    return (
        <ContractEditorLayout contract={contract} appendBreadcrumbs={[{ name: 'Buat Naskah Baru', 'to': '/dashboard/contract/new' }]} className="">
            <div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-2">
                    <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden flex">
                        <ContractNumberForSigner
                            signer={first}
                            contract={contract}
                            order={1}
                            onSuccess={() => dataContract.refetch()}

                        />
                    </div>
                    <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden flex">
                        <ContractNumberForSigner
                            signer={second}
                            contract={contract}
                            order={2}
                            onSuccess={() => dataContract.refetch()}
                        />
                    </div>
                </div>
            </div>
        </ContractEditorLayout>
    )
}


const ContractNumberForSigner = ({ signer, contract, order, onSuccess }) => {
    const assignContractNumber = useAssignContractNumber({ onSuccess: onSuccess })
    const [contractNumber, setContractNumber] = useState(signer.contractNumber)

    const submit = (event) => {
        event.preventDefault()
        assignContractNumber.mutate({ ID: contract.ID, order, number: contractNumber })
    }


    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-row">
                <div className={classNames("rounded-md p-3", signer.contractNumber ? "bg-indigo-500" : "bg-rose-500")}>
                    <CodeIcon
                        className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div className="ml-2">
                    <p className="text-sm font-medium text-gray-500 truncate">
                        {signer.companySigner.officerName}
                    </p>
                    <p className="text-sm font-medium text-gray-500 truncate">
                        {signer.companySigner.officerPosition} di  {signer.companySigner.company.name}
                    </p>
                </div>
            </div>
            <form onSubmit={submit} className="flex pt-10">
                <div className="relative border w-full border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                    <label
                        htmlFor="Nomor Kontrak"
                        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                    >
                        Nomor Kontrak
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-3xl"
                        placeholder={`Nomor Kontrak untuk Pihak ${order}`}
                        disabled={!authenticationProvider.isContractOperator()}
                        value={contractNumber}
                        onChange={({ target: { value } }) => setContractNumber(value)}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                        {!assignContractNumber.isLoading && signer.contractNumber && signer.contractNumber === contractNumber && (
                            <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        )}
                        {!assignContractNumber.isLoading && signer.contractNumber && signer.contractNumber !== contractNumber && (
                            <CheckCircleIcon className="h-5 w-5 text-yellow-500" />
                        )}
                        {!assignContractNumber.isLoading && !signer.contractNumber && contractNumber && (
                            <button type="submit" className="cursor-pointer">
                                <CheckCircleIcon className="h-5 w-5 text-gray-500 cursor-pointer" />
                            </button>
                        )}
                        {assignContractNumber.isLoading && <RefreshIcon className="h-5 w-5 text-blue-500 animate-spin" />}
                    </div>
                </div>
            </form>
        </div>
    )
}