import { Fragment, useEffect, useState } from "react";
import { useSubmitContactUs } from "Data/Support"
import { ExclamationCircleIcon, PaperAirplaneIcon, PlusIcon, RefreshIcon } from "@heroicons/react/outline";
import { WhileFormError } from "Component";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { set } from "lodash";

export default function ContactForm() {
    const { t } = useTranslation()
    const [waitSubmit, setWaitSubmit] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const submitContactUs = useSubmitContactUs({
        onError: (error) => {
            if (error.response.status === 429) {
                toast.error('Tunggu beberapa saat, anda terlalu banyak mengirim data.')
            }
            return
        },
        onSuccess: () => {
            toast.success('Pesan sudah terkirim')
            setName('')
            setPhone('')
            setEmail('')
            setSubject('')
            setMessage('')
            setWaitSubmit(30)
            return
        }
    });

    function submit(event) {
        submitContactUs.mutate({ name, email, phone, subject, message })
        event.preventDefault()
    }
    useEffect(() => {
        if (waitSubmit !== null && waitSubmit > 0) {
            setTimeout(() => setWaitSubmit(waitSubmit - 1), 1000)
        }
    }, [waitSubmit])

    return (
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12 rounded-2xl">
            <div className="max-w-lg mx-auto lg:max-w-none">
                <Transition
                    show={waitSubmit !== null}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="text-center">
                        <div className="flex justify-center mb-10">
                            <PaperAirplaneIcon className="w-10 h-10 rotate-45" />
                        </div>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">Pesan Terkirim</h3>
                        <p className="mt-1 text-sm text-gray-500">Silahkan tunggu beberapa saat lagi agar dapat mengirim kembali.</p>
                        <div className="mt-6">
                            <button
                                type="button"
                                disabled={waitSubmit > 0}
                                onClick={() => setWaitSubmit(null)}
                                className={classNames(
                                    "inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2",
                                    waitSubmit > 0 ? "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500" : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                                )}
                            >
                                {waitSubmit > 0 && <RefreshIcon className="-ml-1 mr-2 h-5 w-5 animate-spin" aria-hidden="true" />}
                                {waitSubmit <= 0 && <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />}

                                {waitSubmit > 0 && `Tunggu ${waitSubmit} detik ...`}
                                {waitSubmit <= 0 && 'Kirim lagi'}
                            </button>
                        </div>
                    </div>
                </Transition>
                <Transition
                    show={waitSubmit === null}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <form onSubmit={submit} className="grid grid-cols-1 gap-y-6">
                        <div>
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                <label
                                    htmlFor="name"
                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                >
                                    Nama Lengkap
                                </label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="name"
                                        className="block w-full border-0 py-2 px-2  text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                        placeholder="Nama lengkap"
                                        value={name}
                                        onChange={({ target: { value } }) => setName(value)}
                                    />
                                    <WhileFormError form={submitContactUs} attribute="name">
                                        {({ message }) =>
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center justify-center pointer-events-none">
                                                <p className="text-sm text-red-600 mr-2 bg-white">
                                                    {t(message)}
                                                </p>
                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                            </div>
                                        }
                                    </WhileFormError>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                <label
                                    htmlFor="phone"
                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                >
                                    Telepon/HP
                                </label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="phone"
                                        className="block w-full border-0 py-2 px-2  text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                        placeholder="Nomor diawali dengan +Kode Negara atau 0"
                                        value={phone}
                                        onChange={({ target: { value } }) => setPhone(value)}
                                    />
                                    <WhileFormError form={submitContactUs} attribute="phone">
                                        {({ message }) =>
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center justify-center pointer-events-none">
                                                <p className="text-sm text-red-600 mr-2 bg-white">
                                                    {t(message)}
                                                </p>
                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                            </div>
                                        }
                                    </WhileFormError>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                <label
                                    htmlFor="email"
                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                >
                                    E-Mail
                                </label>
                                <div className="relative">
                                    <input
                                        type="email"
                                        name="phone"
                                        className="block w-full border-0 py-2 px-2  text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                        placeholder="Alamat email yang akan digunakan sebagai media kontak utama anda"
                                        value={email}
                                        onChange={({ target: { value } }) => setEmail(value)}
                                    />
                                    <WhileFormError form={submitContactUs} attribute="email">
                                        {({ message }) =>
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center justify-center pointer-events-none">
                                                <p className="text-sm text-red-600 mr-2 bg-white">
                                                    {t(message)}
                                                </p>
                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                            </div>
                                        }
                                    </WhileFormError>

                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center">
                                <span className="px-2 bg-white text-sm text-gray-500">Pesan</span>
                            </div>
                        </div>
                        <div>
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                <label
                                    htmlFor="subject"
                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                >
                                    Subyek
                                </label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="subject"
                                        className="block w-full border-0 py-2 px-2  text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                        placeholder="Prihal pesan yang akan anda sampaikan"
                                        value={subject}
                                        onChange={({ target: { value } }) => setSubject(value)}
                                    />
                                    <WhileFormError form={submitContactUs} attribute="subject">
                                        {({ message }) =>
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center justify-center pointer-events-none">
                                                <p className="text-sm text-red-600 mr-2 bg-white">
                                                    {t(message)}
                                                </p>
                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                            </div>
                                        }
                                    </WhileFormError>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                <label
                                    htmlFor="message"
                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                >
                                    Isi Pesan
                                </label>
                                <div className="relative">
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={4}
                                        className="block w-full  py-3 px-4 placeholder-gray-500 focus:border-0 focus-within:border-0 border-0 focus:ring-0 ring-0"
                                        placeholder="Message"
                                        value={message}
                                        onChange={({ target: { value } }) => setMessage(value)}
                                    />
                                    <WhileFormError form={submitContactUs} attribute="message">
                                        {({ message }) =>
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-start mt-3 justify-center pointer-events-none">
                                                <p className="text-sm text-red-600 mr-2 bg-white">
                                                    {t(message)}
                                                </p>
                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                            </div>
                                        }
                                    </WhileFormError>
                                </div>
                            </div>

                        </div>
                        <div>
                            <button
                                type="submit"
                                disabled={submitContactUs.isLoading}
                                className={classNames(
                                    "inline-flex justify-center py-3 items-center px-6 border border-transparent shadow-sm text-base font-medium rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2",
                                    submitContactUs.isLoading ? "text-gray-200 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500" : "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                                )}
                            >
                                {submitContactUs.isLoading && <RefreshIcon className="w-4 h-4 text-white animate-spin mr-2" />}
                                {submitContactUs.isLoading ? 'Memproses ...' : 'Kirim'}
                            </button>
                        </div>
                    </form>
                </Transition>
            </div>
        </div>
    )
}