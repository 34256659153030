import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { RadioGroup } from '@headlessui/react'
import { BadgeCheckIcon, CheckCircleIcon, FolderOpenIcon, PaperAirplaneIcon, PlusCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import { useDataTemplateClauseDocuments, useSaveTemplate } from 'Data/TemplateClauseDocument'
import { useContractContext } from '../ContractEditor'
import classNames from 'classnames'
import { authenticationProvider } from 'Provider/Authentication'
import { transformResponseToList } from '../../../Utility/transformer'
import { SaveAsIcon } from '@heroicons/react/solid'

export default function ModalSaveTemplate({ saveClauseGroups, onClose, onSuccess }) {
    const cancelButtonRef = useRef(null)
    const { contract } = useContractContext()
    const saveTemplate = useSaveTemplate({ onSuccess })
    const dataTemplateClauseDocuments = useDataTemplateClauseDocuments({})
    const templates = transformResponseToList(dataTemplateClauseDocuments.data)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [templateName, setTemplateName] = useState('')

    useEffect(() => {
        if (saveClauseGroups) {
            dataTemplateClauseDocuments.refetch()
        }
    }, [saveClauseGroups])
    const submit = () => {
        saveTemplate.mutate({ templateID: selectedTemplate?.ID, templateName: selectedTemplate === null ? templateName : null, clauseGroups: saveClauseGroups })
    }

    return (
        <Transition.Root show={saveClauseGroups !== null} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <SaveAsIcon className="h-6 w-6 text-cyan-500" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Simpan Template
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Dengan memilih salah satu template di bawah ini dan menyetujuinya, maka semua semua pasal yang anda konstruksi akan disimpan ke template tersebut.
                                            Atau anda dapat menyimpan dengan nama baru
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='py-5 px-3'>
                                <div className='pb-5'>
                                    <RadioGroup value={selectedTemplate} onChange={setSelectedTemplate}>
                                        <RadioGroup.Label className="bg-white text-md font-medium text-gray-900"></RadioGroup.Label>

                                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                                            {templates.map((template) => (
                                                <RadioGroup.Option
                                                    key={template.ID}
                                                    value={template}
                                                    className={({ checked, active }) =>
                                                        classNames(
                                                            checked ? 'border-transparent' : 'border-gray-300',
                                                            active ? 'ring-2 ring-indigo-500' : '',
                                                            'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                                        )
                                                    }
                                                >
                                                    {({ checked, active }) => (
                                                        <>
                                                            <div className="flex-1 flex">
                                                                <div className="flex flex-col">
                                                                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                        {template.name}
                                                                    </RadioGroup.Label>
                                                                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">

                                                                    </RadioGroup.Description>
                                                                    <RadioGroup.Description as="span" className="mt-6 text-sm text-gray-500">
                                                                        {template.clauseGroups.length} {'Pasal,  '}
                                                                        {
                                                                            template.clauseGroups.map(clauseGroup => clauseGroup?.clausal?.length || 0).length >= 1 ?
                                                                                template.clauseGroups.map(clauseGroup => clauseGroup?.clausal?.length || 0).reduce((prev, current) => prev + current)
                                                                                : 0
                                                                        } Ayat
                                                                    </RadioGroup.Description>
                                                                </div>
                                                            </div>
                                                            <CheckCircleIcon
                                                                className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                                aria-hidden="true"
                                                            />
                                                            <div
                                                                className={classNames(
                                                                    active ? 'border' : 'border-2',
                                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                                    'absolute -inset-px rounded-lg pointer-events-none'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    )}
                                                </RadioGroup.Option>
                                            ))}
                                            <div onClick={() => setSelectedTemplate(null)} className={classNames(
                                                selectedTemplate === null ? 'ring-2 ring-indigo-500' : '',
                                                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                            )}>
                                                <div className="flex-1 flex">
                                                    <div className="flex flex-col">
                                                        <div as="span" className="block text-sm font-medium text-gray-900">
                                                            Buat template baru
                                                        </div>
                                                        <div as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                            Pilih ini jika ingin membuat template baru
                                                        </div>

                                                    </div>
                                                </div>
                                                <CheckCircleIcon
                                                    className={classNames(selectedTemplate != null ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                    aria-hidden="true"
                                                />
                                                <div
                                                    className={classNames(
                                                        selectedTemplate != null ? 'border' : 'border-2',
                                                        'absolute -inset-px rounded-lg pointer-events-none'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                    {selectedTemplate === null && (
                                        <div className='py-4 mt-4'>
                                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                                <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                                    Nama Template
                                                </label>
                                                <input
                                                    type="text"
                                                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                                    placeholder="Masukan nama template yang baru"
                                                    value={templateName}
                                                    onChange={({ target: { value } }) => setTemplateName(value)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='py-4 '>
                                    {selectedTemplate && (
                                        <div className='border-gray-300 border rounded-lg shadow-sm py-4'>
                                            <div className='mx-2'>
                                                {selectedTemplate.clauseGroups.map((clauseGroup, clauseGroupIndex) => (
                                                    <div className='ml-2 mb-3'>
                                                        <div className="flex items-center">
                                                            <span className="text-lg h-10 w-10 rounded-full bg-indigo-600 justify-center items-center flex text-white mr-3">
                                                                {clauseGroupIndex + 1}
                                                            </span>
                                                            <span className="w-full border-0 border-b border-transparent focus:ring-0 sm:text-sm md:text-sm">
                                                                {clauseGroup.title}
                                                            </span>
                                                        </div>
                                                        <div className='ml-10'>
                                                            {clauseGroup.clausal.map((clausal, clausalIndex) => (
                                                                <div className="flex items-start space-x-4  mb-3">
                                                                    <div className="flex-shrink-0">
                                                                        <span className="h-10 w-10 rounded-full bg-indigo-600 justify-center items-center flex text-xs text-white">
                                                                            {clauseGroupIndex + 1}.{clausalIndex + 1}
                                                                        </span>
                                                                    </div>
                                                                    <div className="min-w-0 flex-1">
                                                                        <div className="relative">
                                                                            <div
                                                                                className="border border-white rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                                                                                {clausal.content}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    <button
                                        type="button"
                                        disabled={saveTemplate.isLoading || (selectedTemplate === null && !templateName)}
                                        className={classNames(
                                            "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:w-auto sm:text-sm",
                                            saveTemplate.isLoading ? "bg-gray-600 text-white hover:bg-gray-700" : "bg-cyan-600 text-white hover:bg-cyan-700"
                                        )}
                                        onClick={() => submit()}
                                    >
                                        {saveTemplate.isLoading && <RefreshIcon className='h-3 w-3 animate-spin text-white' />}
                                        Simpan
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}