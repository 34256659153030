import { useQuery, useMutation } from "react-query"
import { requestGet, requestPost } from "Provider/RestClient"

export function useSubmitDraftJoin({ onSuccess, onError }) {
    const mutation = useMutation(({ companySectorID, companySectorName, companyName, companyCode, companyTaxIdentification, officerName, officerEmail, officerPhone }) => requestPost('/contract/company/draft-join', { companySectorID, companySectorName, companyName, companyCode, companyTaxIdentification, officerName, officerEmail, officerPhone }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useInviteDraftJoin({ onSuccess, onError }) {
    const mutation = useMutation(({ ID, type, location, date, employeeID }) => requestPost(`/contract/company/draft-join/${ID}/invite`, { type, location, date, employeeID }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function usePublishDraftJoin({ onSuccess, onError }) {
    const mutation = useMutation(({ ID, address, logo, password, about }) => requestPost(`/contract/company/draft-join/${ID}/publish`, { address, logo, password, about }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useDataDraftJoin(ID, { expands, onFetched = () => null }) {
    const path = `/contract/company/draft-join/${ID}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onFetched })
}

export function useDataDraftJoins({ sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1 }) {
    const path = '/contract/company/draft-join'
    return useQuery(path, () => requestGet(path, { sort, order, pageSize, currentPage, expand: expands.join(',') }))
}

export const InviteType = [
    { id: 'Offline', name: 'Tatap Muka', description: 'Audiensi akan dilakukan secara tatap muka di lokasi yang ditentukan' },
    { id: 'Online', name: 'Online', description: 'Audiensi akan dilakukan secara virtual, lokasi akan diisi dengan link tautan meeting' },
]

