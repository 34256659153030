import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusIcon, PlusSmIcon, SearchIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/outline'
import { useDataCompanySigners } from 'Data/CompanySigner'
import { transformResponseToList } from '../../../Utility/transformer'

export default function ModalAssignContractSigner({ show, onSelected, onClose, assignOrder }) {
    const [keyword, setKeyword] = useState('')
    const dataCompanySigners = useDataCompanySigners({ keyword, companyID: assignOrder == 2 ? 1 : null })
    const cancelButtonRef = useRef(null)
    const companySigners = transformResponseToList(dataCompanySigners.data)


    useEffect(() => {
        dataCompanySigners.refetch()
    }, [assignOrder])
    
    const search = (event) => {
        event.preventDefault()
        dataCompanySigners.refetch()
    }

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <UsersIcon className="h-6 w-6 text-cyan-500" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Menunjuk Pihak-{assignOrder}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Silakan gunakan pencarian di bawah untuk mencari penanggung jawab / pihak yang akan di tunjuk menjadi pihak ke-{assignOrder}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="mx-auto">
                                <div>
                                    <form onSubmit={search} action="#" className="mt-6 flex">
                                        <label htmlFor="email" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            type="text"
                                            name="keyword"
                                            id="keyword"
                                            value={keyword}
                                            onChange={({ target: { value } }) => setKeyword(value)}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="Masukan nama perusaahan, mitra atau penanggung jawab yang sudah terdaftar"
                                        />
                                        <button
                                            type="submit"
                                            className="ml-4 flex-shrink-0 px-4 py-2 border border-transparent text-sm flex-row flex items-center gap-2 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <SearchIcon className='w-4 h-4' />
                                            Cari
                                        </button>
                                    </form>
                                </div>
                                <div className="mt-10">
                                    <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide">
                                        Penanggung Jawab yang ditemukan di sistem
                                    </h3>
                                    {companySigners.length === 0 && (
                                        <div className="text-center py-10  items-center flex flex-col">
                                            <UserGroupIcon className='h-10 w-10' />
                                            <h3 className="mt-2 text-sm font-medium text-gray-900">Tidak ketemu</h3>
                                            <p className="mt-1 text-sm text-gray-500">Get started by creating a new project.</p>
                                            <div className="mt-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                    Buat PJ Baru
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <ul className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">

                                        {companySigners.map(companySigner => (
                                            <li key={companySigner.ID}>
                                                <div className="group p-2 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    <span className="min-w-0 flex-1 flex items-center space-x-3">
                                                        <span className="block flex-shrink-0">
                                                            <img className="h-10 w-10 rounded-full" src={companySigner.company.urlLogo} alt="" />
                                                        </span>
                                                        <span className="block min-w-0 flex-1">
                                                            <span className="block text-sm font-medium text-gray-900 truncate">{companySigner.officerName} di {companySigner.company.name}</span>
                                                            <span className="block text-sm font-medium text-gray-500 truncate">{companySigner.officerPosition}</span>
                                                        </span>
                                                    </span>
                                                    <button type='button' onClick={() => onSelected(companySigner)} className="flex-shrink-0 h-10 w-10 inline-flex items-center justify-center">
                                                        <PlusSmIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className='flex justify-end'>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}