import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import PortalLayout from "Layout/PortalLayout";
import classNames from "classnames";
import { CheckIcon, FingerPrintIcon, PaperClipIcon } from "@heroicons/react/outline";
import { useDataContractSigner } from "Data/ContractSigner";
import { transformResponseToSingle } from "../../../Utility/transformer";
import { ReactComponent as Logo } from 'Asset/Logo/department-logo-small.svg'
import moment from "moment";



export default function DisplaySignatureWithToken() {
    const { id, token } = useParams()
    const dataContractSigner = useDataContractSigner(id, token, { expands: [] })
    const contractSigner = transformResponseToSingle(dataContractSigner.data)
    const contract = contractSigner?.contract

    if (!contractSigner) return null

    return (
        <PortalLayout>
            <div className="my-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
                <div className="max-w-3xl mx-auto">

                    <div className="flex flex-col items-center gap-2 mb-10">
                        <Logo className="h-30" />
                        <h1 className="text-xl leading-6 font-medium text-gray-900">Tanda Tangan Dijital</h1>
                    </div>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Informasi Kerjasama</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi dasar terkait naskah Kerjasama.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Judul Naskah</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{contract.name}</dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Klasifikasi</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {contract.classifications.map(classification =>
                                            <span key={classification.ID} className={classNames("mr-1 mb-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full", "bg-green-100 text-green-800")}>
                                                {classification.name}
                                            </span>
                                        )}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Tipe Kerjasama</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {contract.typeLabel}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Tanggal Kerjasama</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {moment(contract.contractStart, 'YYYY-MM-DD').format('dddd, D MMM (YYYY)')}
                                        <span className="font-light">{' s/d '}</span>
                                        {moment(contract.contractEnd, 'YYYY-MM-DD').format('dddd, D MMM (YYYY)')}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <ContractSignerInfo contract={contract} order={1} contractSigner={contractSigner} />
                    <ContractSignerInfo contract={contract} order={2} contractSigner={contractSigner} />
                </div>
            </div>
        </PortalLayout>
    )
}


const ContractSignerInfo = ({ contract, order, contractSigner }) => {
    const signer = contract.contractSigners.find(contractSigner => contractSigner.order == order)
    return (
        <div className="bg-white shadow overflow-hidden mt-10 sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 relative">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Informasi Pihak {order}</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi terkait pihak ke-{order}.</p>

                <div className="absolute right-5 bottom-10">
                    <div
                        className={classNames(
                            contractSigner.ID !== signer.ID ? 'hidden' : '',
                            contractSigner.ID === signer.ID ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                            'flex px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 items-center'
                        )}
                    >
                        <CheckIcon className="h-4 w-4 mr-1" />
                        Penandatangan
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Nama Instansi</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{signer.companySigner.company.name}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Alamat instansi</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{signer.companySigner.company.address}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Nama PJ</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{signer.companySigner.officerName}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Posisi/Jabatan PJ</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{signer.companySigner.officerPosition}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Nomor Kontrak</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{signer.contractNumber ?? '-'}</dd>
                    </div>

                </dl>
            </div>

        </div>
    )
}