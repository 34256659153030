import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import PortalLayout from "Layout/PortalLayout";
import EmptyContractSigner from "./EmptyContractSigner";
import SignatureInput from "./SignatureInput";
import classNames from "classnames";
import { FingerPrintIcon } from "@heroicons/react/outline";
import { useDataContractSigner } from "Data/ContractSigner";
import { transformResponseToSingle } from "../../../Utility/transformer";



export default function SignatureWithToken() {
    const { id, token } = useParams()
    const dataContractSigner = useDataContractSigner(id, token, { expands: [] })
    const contractSigner = transformResponseToSingle(dataContractSigner.data)



    return (
        <PortalLayout>

            <div className="my-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
                <div className="max-w-3xl mx-auto">
                    <ContractSigning
                        signer={contractSigner}
                        token={token}
                        onSuccess={() => dataContractSigner.refetch()}
                    />
                </div>
            </div>
        </PortalLayout>
    )
}



const ContractSigning = ({ signer, token, onSuccess, disabled = false }) => {
    return (
        <Fragment>
            {
                signer && (
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row">
                            <div className={classNames("rounded-md p-3", signer.signingContract ? "bg-indigo-500" : "bg-rose-500")}>
                                <FingerPrintIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className="ml-2">
                                <p className="text-sm font-medium text-gray-500 truncate">
                                    {signer.companySigner.officerName}
                                </p>
                                <p className="text-sm font-medium text-gray-500 truncate">
                                    {signer.companySigner.officerPosition} di  {signer.companySigner.company.name}
                                </p>
                            </div>
                        </div>
                        <div className="flex pt-10">
                            <div className="relative w-full flex items-center justify-center py-10 border border-gray-300 rounded-md px-3 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                <label htmlFor="name" className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                    Tanda Tangan
                                </label>
                                <div className="w-full border-0 flex items-center justify-center" >
                                    <img src={signer.signingContract?.urlSigning} style={{ height: 224 }} />
                                </div>
                            </div>
                        </div>
                        {!disabled && (
                            <div className="pb-10 flex w-full">
                                <SignatureInput
                                    signer={signer}
                                    token={token}
                                    onSuccess={onSuccess}
                                />
                            </div>
                        )}
                    </div>
                )
            }
        </Fragment>
    )
}