import { Fragment } from "react";
import { Menu } from '@headlessui/react'
import classNames from 'classnames'

export default function ProfileMenuItems({ setIsNeedConfirmationForLogout }) {
    return (
        <Fragment>
            <div className="py-1">
                <Menu.Item>
                    {({ active }) => (
                        <button
                            type="button"
                            className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'line-through opacity-25 block px-4 py-2 text-sm'
                            )}
                        >
                            Lihat Profile
                        </button>
                    )}
                </Menu.Item>
                <Menu.Item>
                    {({ active }) => (
                        <button
                            type="button"
                            className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                                'line-through opacity-25 block px-4 py-2 text-sm'
                            )}
                        >
                            Pengaturan
                        </button>
                    )}
                </Menu.Item>
                <Menu.Item>
                    {({ active }) => (
                        <button
                            type="button"
                            className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                                'line-through opacity-25 block px-4 py-2 text-sm'
                            )}
                        >
                            Notifikasi
                        </button>
                    )}
                </Menu.Item>
            </div>
            <div className="py-1">
                <Menu.Item>
                    {({ active }) => (
                        <button
                            type="button"
                            className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                                'line-through opacity-25 block px-4 py-2 text-sm'
                            )}
                        >
                            Dapatkan aplikasinya
                        </button>
                    )}
                </Menu.Item>
                <Menu.Item>
                    {({ active }) => (
                        <button
                            type="button"
                            className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                                'line-through opacity-25 block px-4 py-2 text-sm'
                            )}
                        >
                            Bantuan
                        </button>
                    )}
                </Menu.Item>
            </div>
            <div className="py-1">
                <button
                    onClick={() => setIsNeedConfirmationForLogout(true)}
                    type="button"
                    className="block px-4 py-2 text-sm text-gray-700"
                >
                    Logout
                </button>
            </div>
        </Fragment>
    )
}
