import {
    CheckIcon,
    DocumentTextIcon,
    PaperClipIcon,
    QuestionMarkCircleIcon,
    SwitchHorizontalIcon,
    ThumbUpIcon,
    UserIcon,
} from '@heroicons/react/solid'
import { Link, useParams } from "react-router-dom";
import { transformResponseToList, transformResponseToSingle } from "../../../Utility/transformer";
import { useDataContract, useDataExportContracts, useExportContract } from "Data/Contract";
import classNames from 'classnames'
import moment from "moment";
import { Fragment } from "react";
import { CalendarIcon, CheckCircleIcon, ClockIcon, LibraryIcon, LocationMarkerIcon, PaperAirplaneIcon, PlusCircleIcon, PlusIcon, RefreshIcon, SearchIcon, SpeakerphoneIcon } from "@heroicons/react/outline";
import ContractLayout from "Layout/ContractLayout";
import { useContractContext } from '../ContractEditor';
import { Progress } from '../../../Component';
import { authenticationProvider, ROLE_COMPANY_SIGNER, ROLE_CONTRACT_OPERATOR, ROLE_CONTRACT_VALIDATOR } from 'Provider/Authentication';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const user = {
    name: 'Whitney Francis',
    email: 'whitney@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
}
const attachments = [
    { name: 'resume_front_end_developer.pdf', href: '#' },
    { name: 'coverletter_front_end_developer.pdf', href: '#' },
]
const eventTypes = {
    applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
    advanced: { icon: ThumbUpIcon, bgColorClass: 'bg-blue-500' },
    completed: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
}
const timeline = [
    {
        id: 1,
        type: eventTypes.applied,
        content: 'Applied to',
        target: 'Front End Developer',
        date: 'Sep 20',
        datetime: '2020-09-20',
    },
    {
        id: 2,
        type: eventTypes.advanced,
        content: 'Advanced to phone screening by',
        target: 'Bethany Blake',
        date: 'Sep 22',
        datetime: '2020-09-22',
    },
    {
        id: 3,
        type: eventTypes.completed,
        content: 'Completed phone screening with',
        target: 'Martha Gardner',
        date: 'Sep 28',
        datetime: '2020-09-28',
    },
    {
        id: 4,
        type: eventTypes.advanced,
        content: 'Advanced to interview by',
        target: 'Bethany Blake',
        date: 'Sep 30',
        datetime: '2020-09-30',
    },
    {
        id: 5,
        type: eventTypes.completed,
        content: 'Completed interview with',
        target: 'Katherine Snyder',
        date: 'Oct 4',
        datetime: '2020-10-04',
    },
]
const comments = [
    {
        id: 1,
        name: 'Leslie Alexander',
        date: '4d ago',
        imageId: '1494790108377-be9c29b29330',
        body: 'Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.',
    },
    {
        id: 2,
        name: 'Michael Foster',
        date: '4d ago',
        imageId: '1519244703995-f4e0f30006d5',
        body: 'Et ut autem. Voluptatem eum dolores sint necessitatibus quos. Quis eum qui dolorem accusantium voluptas voluptatem ipsum. Quo facere iusto quia accusamus veniam id explicabo et aut.',
    },
    {
        id: 3,
        name: 'Dries Vincent',
        date: '4d ago',
        imageId: '1506794778202-cad84cf45f1d',
        body: 'Expedita consequatur sit ea voluptas quo ipsam recusandae. Ab sint et voluptatem repudiandae voluptatem et eveniet. Nihil quas consequatur autem. Perferendis rerum et.',
    },
]

const people = [
    {
        name: 'Lindsay Walton',
        handle: 'lindsaywalton',
        email: 'lindsaywalton@example.com',
        role: 'Front-end Developer',
        imageId: '1517841905240-472988babdf9',
        imageUrl:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Courtney Henry',
        handle: 'courtneyhenry',
        email: 'courtneyhenry@example.com',
        role: 'Designer',
        imageId: '1438761681033-6461ffad8d80',
        imageUrl:
            'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Tom Cook',
        handle: 'tomcook',
        email: 'tomcook@example.com',
        role: 'Director, Product Development',
        imageId: '1472099645785-5658abf4ff4e',
        imageUrl:
            'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Whitney Francis',
        handle: 'whitneyfrancis',
        email: 'whitneyfrancis@example.com',
        role: 'Copywriter',
        imageId: '1517365830460-955ce3ccd263',
        imageUrl:
            'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Leonard Krasner',
        handle: 'leonardkrasner',
        email: 'leonardkrasner@example.com',
        role: 'Senior Designer',
        imageId: '1519345182560-3f2917c472ef',
        imageUrl:
            'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Floyd Miles',
        handle: 'floydmiles',
        email: 'floydmiles@example.com',
        role: 'Principal Designer',
        imageId: '1463453091185-61582044d556',
        imageUrl:
            'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
]
export default function DetailContract() {
    const { dataContract, contract } = useContractContext()
    const dataExportContracts = useDataExportContracts(contract.ID, {})
    const exportContract = useExportContract({ onSuccess: () => dataExportContracts.refetch() })
    const exportContracts = transformResponseToList(dataExportContracts.data)

    const created = moment(contract.created, 'YYYY-MM-DD')
    const updated = moment(contract.updated, 'YYYY-MM-DD')

    return (
        <ContractLayout appendBreadcrumbs={[{ name: <span>Naskah: <span className="font-bold">"{contract.name}"</span></span>, to: `/dashboard/contract/${contract.ID}` }]}>
            <div className="py-10">
                <header></header>
                <main>
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <div className="mb-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Naskah Kerjasama{contract ? `: ${contract.name}` : ''}</h3>

                                    <div className='flex flex-row text-xs'>
                                        Dibuat pada
                                        <time className="mx-2 flex flex-row items-center" dateTime={created.format('YYYY-MM-DD HH:mm:ss')}>
                                            <CalendarIcon className="w-4 h-4 mr-1" /> {created.format('D MMMM, YY')}
                                            <ClockIcon className="w-4 h-4 mr-1 ml-2" /> {created.format('HH:mm')}
                                        </time>

                                        <Fragment>
                                            Diubah terakhir pada
                                            <time className="ml-2 flex flex-row items-center" dateTime={updated.format('YYYY-MM-DD HH:mm:ss')}>
                                                <CalendarIcon className="w-4 h-4 mr-1" /> {updated.format('D MMMM, YY')}
                                                <ClockIcon className="w-4 h-4 mr-1 ml-2" /> {updated.format('HH:mm')}
                                            </time>
                                        </Fragment>
                                    </div>
                                </div>
                                <Progress
                                    currentStep={contract.currentPhase}
                                    items={
                                        ({
                                            [ROLE_COMPANY_SIGNER]: [
                                                { name: 'Informasi Dasar', to: contract ? `/dashboard/contract/${contract.ID}/update` : '/dashboard/contract/new' },
                                                { name: 'Penunjukan', to: `/dashboard/contract/${contract?.ID}/update/assign-company` },
                                                { name: 'Konstruksi Pasal', to: `/dashboard/contract/${contract?.ID}/update/assign-clause` },
                                                { name: 'Validasi', to: `/dashboard/contract/${contract?.ID}/update/validation`, disabled: contract === null },
                                                { name: 'Tanda Tangan', to: `/dashboard/contract/${contract?.ID}/update/assign-signature` },
                                                { name: 'Nomor Akhir', to: `/dashboard/contract/${contract?.ID}/update/assign-number` },
                                            ],
                                            [ROLE_CONTRACT_OPERATOR]: [
                                                { name: 'Informasi Dasar', to: contract ? `/dashboard/contract/${contract.ID}/update` : '/dashboard/contract/new' },
                                                { name: 'Penunjukan', to: `/dashboard/contract/${contract?.ID}/update/assign-company`, disabled: contract === null },
                                                { name: 'Konstruksi Pasal', to: `/dashboard/contract/${contract?.ID}/update/assign-clause`, disabled: contract === null },
                                                { name: 'Validasi', to: `/dashboard/contract/${contract?.ID}/update/validation`, disabled: contract === null },
                                                { name: 'Tanda Tangan', to: `/dashboard/contract/${contract?.ID}/update/assign-signature`, disabled: contract === null },
                                                { name: 'Nomor Akhir', to: `/dashboard/contract/${contract?.ID}/update/assign-number`, disabled: contract === null },
                                            ],
                                            [ROLE_CONTRACT_VALIDATOR]: [
                                                { name: 'Informasi Dasar', to: contract ? `/dashboard/contract/${contract.ID}/update` : '/dashboard/contract/new' },
                                                { name: 'Penunjukan', to: `/dashboard/contract/${contract?.ID}/update/assign-company`, disabled: contract === null },
                                                { name: 'Konstruksi Pasal', to: `/dashboard/contract/${contract?.ID}/update/assign-clause`, disabled: contract === null },
                                                { name: 'Validasi', to: `/dashboard/contract/${contract?.ID}/update/validation`, disabled: contract === null },
                                                { name: 'Tanda Tangan', to: `/dashboard/contract/${contract?.ID}/update/assign-signature`, disabled: contract === null },
                                                { name: 'Nomor Akhir', to: `/dashboard/contract/${contract?.ID}/update/assign-number`, disabled: contract === null },
                                            ],
                                        })[authenticationProvider.role()]
                                    }
                                />
                                {/* <ContractProgress contract={contract} /> */}
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <div className="min-h-full">

                                    <main className="py-10">
                                        {/* Page header */}
                                        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                                            {
                                                contract.contractSigners.length >= 1 &&
                                                <CompanyInfo company={contract.contractSigners[0].companySigner.company} />
                                            }
                                            {
                                                contract.contractSigners.length >= 2 &&
                                                <Fragment>
                                                    <SwitchHorizontalIcon className="h-10 w-10" />
                                                    <CompanyInfo company={contract.contractSigners[1].companySigner.company} />
                                                </Fragment>
                                            }
                                            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                                                <Link
                                                    to={`/dashboard/contract/${contract.ID}/update`}
                                                    className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                                                >
                                                    Ubah
                                                </Link>
                                                <button
                                                    onClick={() => exportContract.mutate({ ID: contract.ID })}
                                                    type="button"

                                                    disabled={exportContract.isLoading}
                                                    className={classNames(
                                                        "inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100",
                                                        exportContract.isLoading ? "bg-gray-600 hover:bg-gray-700  focus:ring-gray-500" : "bg-blue-600 hover:bg-blue-700  focus:ring-blue-500"
                                                    )}
                                                >
                                                    {exportContract.isLoading && (<RefreshIcon className="w-4 h-4 text-white animate-spin mr-2" />)}
                                                    Cetak
                                                </button>
                                            </div>
                                        </div>


                                        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                                            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                                                {/* Description list*/}
                                                <section aria-labelledby="applicant-information-title">
                                                    <div className="bg-white shadow sm:rounded-lg">
                                                        <div className="px-4 py-5 sm:px-6">
                                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                                Informasi Dasar Kontrak
                                                            </h2>
                                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                                Semua informasi dasar terkait dengan kontrak ini. semua informasi dasar ini dapat digunakan saat proses
                                                                cetak dokumen.
                                                            </p>
                                                        </div>
                                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-medium text-gray-500">Nama/Judul Kerjasama</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">{contract.name}</dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-medium text-gray-500">Klasifikasi</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {contract.classifications.map(classification =>
                                                                            <span key={classification.ID} className={classNames("mr-1 mb-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full", "bg-green-100 text-green-800")}>
                                                                                {classification.name}
                                                                            </span>
                                                                        )}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-medium text-gray-500">Tipe Kerjasama</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">{contract.typeLabel}</dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-medium text-gray-500">Pi</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {contract.deleted === 1 && (
                                                                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                                                                Terhapus
                                                                            </span>
                                                                        )}
                                                                        {contract.deleted === 0 && (
                                                                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                                                Belum Terhapus
                                                                            </span>
                                                                        )}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-2">
                                                                    <dt className="text-sm font-medium text-gray-500">Revisi hasil Export</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                                            {exportContracts.map((exportContract, index) => (
                                                                                <li key={index} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                                                                    <div className="w-0 flex-1 flex items-center">
                                                                                        <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                                        <div className='flex flex-col flex-1 ml-2'>
                                                                                            <span className="truncate text-md">
                                                                                                Export.docx
                                                                                                {index === 0 && (
                                                                                                    <span className="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                                                                        Terbaru
                                                                                                    </span>
                                                                                                )}
                                                                                            </span>
                                                                                            <time className="text-xs truncate">
                                                                                                {moment(exportContract.lastModified, 'YYYY-MM-DD HH:mm:ss').toNow()}{' • '}
                                                                                                {moment(exportContract.lastModified, 'YYYY-MM-DD HH:mm:ss').format('D MMMM, YYYY')} {' pada '}
                                                                                                {moment(exportContract.lastModified, 'YYYY-MM-DD HH:mm:ss').format('H:m')}
                                                                                            </time>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="ml-4 flex-shrink-0">

                                                                                        <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                                                                            <a
                                                                                                href={exportContract.URL}
                                                                                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                                                            >
                                                                                                <span className="sr-only">Unduh</span>
                                                                                                <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </a>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                                                            >
                                                                                                <span className="sr-only">Pratinjau</span>
                                                                                                <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            ))}


                                                                        </ul>
                                                                    </dd>
                                                                </div>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </section>

                                                {/* Comments*/}
                                                <section aria-labelledby="notes-title" className='hidden'>
                                                    <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                                                        <div className="divide-y divide-gray-200">
                                                            <div className="px-4 py-5 sm:px-6">
                                                                <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                                                    Notes
                                                                </h2>
                                                            </div>
                                                            <div className="px-4 py-6 sm:px-6">
                                                                <ul className="space-y-8">
                                                                    {comments.map((comment) => (
                                                                        <li key={comment.id}>
                                                                            <div className="flex space-x-3">
                                                                                <div className="flex-shrink-0">
                                                                                    <img
                                                                                        className="h-10 w-10 rounded-full"
                                                                                        src={`https://images.unsplash.com/photo-${comment.imageId}?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <div className="text-sm">
                                                                                        <span className="font-medium text-gray-900">
                                                                                            {comment.name}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="mt-1 text-sm text-gray-700">
                                                                                        <p>{comment.body}</p>
                                                                                    </div>
                                                                                    <div className="mt-2 text-sm space-x-2">
                                                                                        <span className="text-gray-500 font-medium">{comment.date}</span>{' '}
                                                                                        <span className="text-gray-500 font-medium">&middot;</span>{' '}
                                                                                        <button type="button" className="text-gray-900 font-medium">
                                                                                            Reply
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                                                            <div className="flex space-x-3">
                                                                <div className="flex-shrink-0">
                                                                    <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                                                                </div>
                                                                <div className="min-w-0 flex-1">
                                                                    <form action="#">
                                                                        <div>
                                                                            <label htmlFor="comment" className="sr-only">
                                                                                About
                                                                            </label>
                                                                            <textarea
                                                                                id="comment"
                                                                                name="comment"
                                                                                rows={3}
                                                                                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                                                                                placeholder="Add a note"
                                                                                defaultValue={''}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-3 flex items-center justify-between">
                                                                            <span

                                                                                className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                                                                            >
                                                                                <QuestionMarkCircleIcon
                                                                                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <span>Some HTML is okay.</span>
                                                                            </span>
                                                                            <button
                                                                                type="submit"
                                                                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                            >
                                                                                Comment
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>

                                            <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                                                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                                    <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                                                        Progress Konstruksi Pasal
                                                    </h2>

                                                    {contract.phaseClauseConstruction.reviews.length === 0 && (
                                                        <div className="text-center mt-5">
                                                            <svg
                                                                className="mx-auto h-12 w-12 text-gray-400"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                aria-hidden="true"
                                                            >
                                                                <path
                                                                    vectorEffect="non-scaling-stroke"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                                />
                                                            </svg>
                                                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                                                                Belum ada konstruksi pasal
                                                            </h3>
                                                            <p className="mt-1 text-sm text-gray-500">Silahkan tunggu sehingga tahapan konstruksi pasal selesai atau dimulai</p>
                                                        </div>
                                                    )}
                                                    {/* Activity Feed */}
                                                    <div className="mt-6 flow-root">
                                                        <ul className="-mb-8">
                                                            {contract.phaseClauseConstruction.reviews.map((review, index) => (
                                                                <li key={review.ID}>
                                                                    <div className="relative pb-8">
                                                                        {index !== (contract.phaseClauseConstruction.reviews.length - 1) && (
                                                                            <span
                                                                                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                                                aria-hidden="true"
                                                                            />
                                                                        )}
                                                                        <div className="relative flex space-x-3">
                                                                            <div>
                                                                                <span
                                                                                    className={classNames(
                                                                                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                                                                                        ({
                                                                                            Send: 'bg-gray-500',
                                                                                            Approve: 'bg-green-500',
                                                                                            Reject: 'bg-rose-500',
                                                                                            Update: 'bg-gray-500',
                                                                                        })[review.type]
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        ({
                                                                                            Send: <PaperAirplaneIcon className="w-5 h-5 text-white" aria-hidden="true" />,
                                                                                            Approve: <CheckCircleIcon className="w-5 h-5 text-white" aria-hidden="true" />,
                                                                                            Reject: <PlusCircleIcon className="w-5 h-5 rotate-45 text-white" aria-hidden="true" />,
                                                                                            Update: <SpeakerphoneIcon className="w-5 h-5 text-white" aria-hidden="true" />,
                                                                                        })[review.type]
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                                                <div>
                                                                                    <p className="text-sm text-gray-500">
                                                                                        <span className="font-medium text-gray-900">
                                                                                            {
                                                                                                ({
                                                                                                    Send: 'Revisi dikirim',
                                                                                                    Approve: 'Revisi diterima',
                                                                                                    Reject: 'Revisi ditolak',
                                                                                                    Update: 'Ada info: ',
                                                                                                })[review.type]
                                                                                            }
                                                                                        </span>
                                                                                        {review.note && (
                                                                                            <span className="">
                                                                                                {' '}"{review.note}"
                                                                                            </span>
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                                                    <time dateTime={review.created}>
                                                                                        {moment(review.created, 'YYYY-MM-DD HH:mm:ss').format('MMM D')}<br />
                                                                                        <span className='text-xs font-light'>{moment(review.created, 'YYYY-MM-DD HH:mm:ss').format('H:mm')}</span>
                                                                                    </time>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div className="mt-6 flex flex-col justify-stretch">

                                                    </div>
                                                </div>
                                                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mt-4">
                                                    <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                                                        Progress Validasi
                                                    </h2>

                                                    {contract.phaseValidation.validations.length === 0 && (
                                                        <div className="text-center mt-5">
                                                            <svg
                                                                className="mx-auto h-12 w-12 text-gray-400"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                aria-hidden="true"
                                                            >
                                                                <path
                                                                    vectorEffect="non-scaling-stroke"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                                />
                                                            </svg>
                                                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                                                                Belum ada validasi pasal
                                                            </h3>
                                                            <p className="mt-1 text-sm text-gray-500">Silahkan tunggu sehingga tahapan validasi selesai atau dimulai</p>
                                                        </div>
                                                    )}
                                                    {/* Activity Feed */}
                                                    <div className="mt-6 flow-root">
                                                        <ul className="-mb-8">
                                                            {contract.phaseValidation.validations.map((validation, index) => (
                                                                <li key={validation.ID}>
                                                                    <div className="relative pb-8">
                                                                        {index !== (contract.phaseValidation.validations.length - 1) && (
                                                                            <span
                                                                                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                                                aria-hidden="true"
                                                                            />
                                                                        )}
                                                                        <div className="relative flex space-x-3">
                                                                            <div>
                                                                                <span
                                                                                    className={classNames(
                                                                                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                                                                                        ({
                                                                                            Send: 'bg-gray-500',
                                                                                            Approve: 'bg-green-500',
                                                                                            Reject: 'bg-rose-500',
                                                                                            Update: 'bg-gray-500',
                                                                                        })[validation.type]
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        ({
                                                                                            Send: <PaperAirplaneIcon className="w-5 h-5 text-white" aria-hidden="true" />,
                                                                                            Approve: <CheckCircleIcon className="w-5 h-5 text-white" aria-hidden="true" />,
                                                                                            Reject: <PlusCircleIcon className="w-5 h-5 rotate-45 text-white" aria-hidden="true" />,
                                                                                            Update: <SpeakerphoneIcon className="w-5 h-5 text-white" aria-hidden="true" />,
                                                                                        })[validation.type]
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                                                <div>
                                                                                    <p className="text-sm text-gray-500">
                                                                                        <span className="font-medium text-gray-900">
                                                                                            {
                                                                                                ({
                                                                                                    Send: `Validasi untuk ${validation.by} dibatalkan`,
                                                                                                    Approve: `Validasi untuk ${validation.by} diterima`,
                                                                                                    Reject: `Validasi untuk ${validation.by} ditolak`,
                                                                                                    Update: `Ada info terkait Validasi ${validation.by}: `,
                                                                                                })[validation.type]
                                                                                            }
                                                                                        </span>
                                                                                        {validation.note && (
                                                                                            <span className="">
                                                                                                {' '}"{validation.note}"
                                                                                            </span>
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                                                    <time dateTime={validation.created}>
                                                                                        {moment(validation.created, 'YYYY-MM-DD HH:mm:ss').format('MMM D')}<br />
                                                                                        <span className='text-xs font-light'>{moment(validation.created, 'YYYY-MM-DD HH:mm:ss').format('H:mm')}</span>
                                                                                    </time>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div className="mt-6 flex flex-col justify-stretch">

                                                    </div>
                                                </div>
                                            </section>

                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </ContractLayout>
    )
}


const CompanyInfo = ({ company }) => (
    <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
            <div className="relative">
                <img
                    className="h-16 w-16 rounded-full"
                    src={company.urlLogo}
                    alt=""
                />
                <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
            </div>
        </div>
        <div>
            <h1 className="text-2xl font-bold text-gray-900">{company.name}</h1>
            <p className="text-sm font-light text-gray-500">
                terdata {moment(company.created, 'YYYY-MM-DD HH:mm:ss').fromNow()} sistem pada

                <time dateTime={company.created} className="font-medium">{moment(company.created, 'YYYY-MM-DD HH:mm:ss').format(' D MMMM, YYYY')}</time>
            </p>
        </div>
    </div>
)