import { Fragment, useState } from "react"
import { useParams } from "react-router-dom"
import { RadioGroup } from '@headlessui/react'
import ContractEditorLayout from "Layout/ContractEditorLayout"
import { ContractTypes, ContractClassifications, useUpdateContract, useDataContractClassifications } from "Data/Contract";
import classNames from "classnames"
import { CheckCircleIcon, ExclamationCircleIcon, RefreshIcon, XIcon } from "@heroicons/react/outline"
import { classNamesWhileError, WhileFormError, WhileFormErrors } from "../../../Component"
import CalenderSelection from "../../../Component/CalenderSelection"
import { useContractContext } from "../ContractEditor"
import { authenticationProvider } from "Provider/Authentication";
import { transformResponseToList } from "../../../Utility/transformer";

export default function UpdateContract() {
    const { id } = useParams()
    const { dataContract, contract } = useContractContext()

    const [name, setName] = useState(contract.name)
    const [location, setLocation] = useState(contract.location)
    const [dates, setDates] = useState([contract.contractStart, contract.contractEnd])
    const [contractStart, contractEnd] = dates
    const [contractType, setContractType] = useState(ContractTypes.find(type => type.id == contract.type))
    const [selectedContractClassifications, setSelectedContractClassifications] = useState(contract.classifications)

    const dataContractClassifications = useDataContractClassifications({})
    const contractClassifications = transformResponseToList(dataContractClassifications.data)
    const [contractClassification, setContractClassification] = useState(ContractClassifications.find(classification => classification.id == contract.classificationType))
    const updateContract = useUpdateContract({
        onSuccess: () => dataContract.refetch(),
        onError: () => null
    })

    const submit = (event) => {
        event.preventDefault()
        if (authenticationProvider.role())
            updateContract.mutate({
                ID: id,
                type: contractType.id,
                classificationIDs: selectedContractClassifications.map(selectedContractClassification => selectedContractClassification.ID).join(','),
                contractStart, contractEnd,
                name, location
            })
    }

    if (!contract) {
        return null
    }

    return (
        <ContractEditorLayout contract={contract} appendBreadcrumbs={[{ name: <span>Naskah: <span className="font-bold">{contract.name}</span></span>, 'to': `/dashboard/contract/${contract.ID}` }]} className="">
            <form onSubmit={submit} className="flex flex-col gap-4">
                <RadioGroup value={contractType} onChange={setContractType}>
                    <RadioGroup.Label className="text-base font-medium text-gray-900">Pilih Tipe Kontrak</RadioGroup.Label>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                        {ContractTypes.map((contractType) => (
                            <RadioGroup.Option
                                key={contractType.id}
                                value={contractType}
                                disabled={!authenticationProvider.isContractOperator()}
                                className={({ checked, active }) =>
                                    classNames(
                                        checked ? 'border-transparent' : 'border-gray-300',
                                        active ? 'ring-2 ring-indigo-500' : '',
                                        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <Fragment>
                                        <div className="flex-1 flex">
                                            <div className="flex flex-col">
                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                    {contractType.title}
                                                </RadioGroup.Label>
                                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                    {contractType.description}
                                                </RadioGroup.Description>
                                            </div>
                                        </div>
                                        <CheckCircleIcon
                                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                            aria-hidden="true"
                                        />
                                        <div
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                'absolute -inset-px rounded-lg pointer-events-none'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Fragment>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>


                <div>
                    <div className="flex items-center justify-between">
                        <h2 className="text-sm font-medium text-gray-900">Klasifikasi</h2>

                    </div>

                    <div className="mt-2">
                        <span className="sr-only">Pilih Klasifikasi</span>
                        <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                            {contractClassifications.map((contractClassification, index) => (
                                <div
                                    key={contractClassification.ID}
                                    onClick={() => {
                                        if (selectedContractClassifications.find(selectedContractClassification => selectedContractClassification.ID === contractClassification.ID)) {
                                            const cloned = [...selectedContractClassifications]
                                            const modified = cloned.filter(search => search.ID !== contractClassification.ID)
                                            setSelectedContractClassifications(modified)
                                        } else {
                                            setSelectedContractClassifications([...selectedContractClassifications, contractClassification])
                                        }
                                    }}
                                    className={classNames(
                                        selectedContractClassifications.find(selectedContractClassification => selectedContractClassification.ID === contractClassification.ID)
                                            ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
                                            : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                        'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer'
                                    )}
                                >
                                    <p>{contractClassification.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="col-span-6 sm:col-span-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Nama
                    </label>

                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            disabled={!authenticationProvider.isContractOperator()}
                            onChange={({ target: { value } }) => setName(value)}
                            className={classNamesWhileError(updateContract, 'name', 'border-red-300',
                                "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md",
                            )}
                        />
                        <WhileFormError form={updateContract} attribute="name">
                            {() => (
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </WhileFormError>
                    </div>

                    <WhileFormError form={updateContract} attribute="name">
                        {({ message }) => (
                            <p className="mt-2 text-sm text-red-600" id="email-error">
                                {message}
                            </p>
                        )}
                    </WhileFormError>
                </div>

                <div className="col-span-6 sm:col-span-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Lokasi
                    </label>

                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type="text"
                            name="location"
                            id="location"
                            disabled={!authenticationProvider.isContractOperator()}
                            value={location}
                            onChange={({ target: { value } }) => setLocation(value)}
                            className={classNamesWhileError(updateContract, 'location', 'border-red-300',
                                "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md",
                            )}
                        />
                        <WhileFormError form={updateContract} attribute="location">
                            {() => (
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </WhileFormError>
                    </div>

                    <WhileFormError form={updateContract} attribute="location">
                        {({ message }) => (
                            <p className="mt-2 text-sm text-red-600" id="email-error">
                                {message}
                            </p>
                        )}
                    </WhileFormError>
                </div>


                <div className="col-span-6 sm:col-span-3 lg:col-span-6">
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <label htmlFor="start" className="block text-sm font-medium text-gray-700">
                            Tanggal Mulai
                        </label>
                        <p className="mt-2 text-sm text-gray-500">Silakan pilih tanggal mulai dan tanggal berakhirnya form ini akan tayang</p>


                        <WhileFormErrors form={updateContract} attributes={["contractStart", "contractEnd"]}>
                            {() => (
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </WhileFormErrors>


                    </div>
                    <div className="mt-6">
                        <CalenderSelection defaultValues={dates} disabled={!authenticationProvider.isContractOperator()} onChange={([dateStart, dateEnd]) => setDates([dateStart, dateEnd])} />
                    </div>
                </div>

                <div className="mt-5 flex justify-end gap-3">
                    {
                        updateContract.error && (
                            <div className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                <svg className="-ml-0.5 mr-1 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                                    <circle cx={4} cy={4} r={3} />
                                </svg>
                                {`${Object.keys(updateContract.error.response.data.errors).length} Kesalahan, tolong diperbaiki `}
                                <button onClick={updateContract.reset} className="ml-2" type="button">
                                    <XIcon className="h-3 w-3" />
                                </button>
                            </div>
                        )
                    }
                    {
                        authenticationProvider.isContractOperator() && (
                            <button
                                type="submit"
                                disabled={updateContract.isLoading}
                                className={classNames(
                                    "inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                                    updateContract.isLoading ? "bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-500" : 'bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500'

                                )}
                            >
                                {updateContract.isLoading && (<RefreshIcon className="h-3 w-3 animate-spin mr-2" />)}
                                {updateContract.isLoading ? 'Memproses' : 'Simpan'}
                            </button>
                        )
                    }
                </div>
            </form>
        </ContractEditorLayout>
    )
}