import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDoubleLeftIcon, ChevronRightIcon, SelectorIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import { Fragment, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { generatePagination } from "Utility/generator"
import { transformResponseError, transformResponseToSingle } from "Utility/transformer"

export function WhileFormError({ form, attribute, children }) {
    const errorMessage = transformResponseError(form.error)[attribute]
    return (
        errorMessage && (
            children({ message: Array.isArray(errorMessage) ? errorMessage[0] : errorMessage })
        )
    )
}

export function WhileFormErrors({ form, attributes = [], operator = 'or', children }) {
    return (
        (
            operator === 'or' ?
                (attributes.filter(attribute => form.error?.response?.data?.errors[attribute] !== undefined).length) :
                attributes.filter(attribute => form.error?.response?.data?.errors[attribute] !== undefined).length === attributes.length
        ) ? (
            children({
                messages: attributes
                    .filter(attribute => form.error?.response?.data?.errors[attribute] !== undefined)
                    .map(attribute => form.error?.response?.data?.errors[attribute])
            })
        ) : null
    )
}

export function Pagination({ pagination, onPaginationChange }) {
    const { currentPage, itemCount, pageSize, pageCount } = pagination
    const pages = generatePagination(currentPage, pageCount)

    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
                <button
                    onClick={() => onPaginationChange({ page: currentPage - 1, show: pageSize })}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Sebelumnya
                </button>
                <button
                    onClick={() => onPaginationChange({ page: currentPage + 1, show: pageSize })}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Selanjutnya
                </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Menampilkan halaman <span className="font-medium">{((currentPage * pageSize) - pageSize + 1)}</span> dari <span className="font-medium">
                            {(currentPage * pageSize)}
                        </span> dari {' '}
                        <span className="font-medium">{itemCount}</span> semua data
                    </p>
                </div>
                <div className="flex flex-row gap-1">
                    <div>
                        <Listbox value={pageSize} onChange={(value) => onPaginationChange({ page: currentPage, show: value })}>
                            {({ open }) => (
                                <>
                                    <div className="relative">
                                        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                            <span className="flex items-center">
                                                <span className="ml-3 block truncate">{pageSize}</span>
                                            </span>
                                            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute  mt-1 bottom-14 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                {[5, 10, 20, 50, 100].map((size) => (
                                                    <Listbox.Option
                                                        key={size}
                                                        className={({ active }) =>
                                                            classNames(
                                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                                            )
                                                        }
                                                        value={size}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <div className="flex items-center">
                                                                    <span
                                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block')}
                                                                    >
                                                                        {size}
                                                                    </span>
                                                                </div>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'text-white' : 'text-indigo-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                        )}
                                                                    >
                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                    <div>

                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            <button
                                onClick={() => onPaginationChange({ page: currentPage - 1, show: pageSize })}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Sebelumnya</span>
                                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            {
                                pages.map(page =>
                                    <button
                                        onClick={() => onPaginationChange({ page: page, show: pageSize })}
                                        aria-current="page"
                                        className={classNames(
                                            "relative inline-flex items-center px-4 py-2 border text-sm font-medium",
                                            currentPage === page ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                                        )}
                                    >
                                        {page}
                                    </button>
                                )
                            }
                            <button
                                onClick={() => onPaginationChange({ page: currentPage + 1, show: pageSize })}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Selanjutnya</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function classNamesWhileError(form, attribute, className, ...restClassNames) {
    return classNames(...restClassNames, form.error?.response?.data?.errors[attribute] ? className : "")
}

export const ProgressVertical = ({ items = [], currentStep = null }) => {
    return (
        <nav aria-label="Progress">
            <ol className="overflow-hidden">
                {items.map((item, index) => (
                    <ProgressItemVertical
                        key={index}
                        name={item.name}
                        description={item.description}
                        to={item.to}
                        disabled={item.disabled}
                        current={currentStep == (index + 1)}
                        completed={currentStep >= (index + 1)}
                        end={items.length === (index + 1)}
                    />
                ))}
            </ol>
        </nav>
    )
}

const ProgressItemVertical = ({ completed = false, name, description = null, to = null, current = false, end = false }) => (
    <li className={classNames(!end ? 'pb-10' : '', 'relative')}>
        {completed && (
            <Fragment>
                {!end && (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600" aria-hidden="true" />
                )}
                <Link to={to} className="relative flex items-start group">
                    <span className="h-9 flex items-center">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                            <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                        </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase">{name}</span>
                        <span className="text-sm text-gray-500">{description}</span>
                    </span>
                </Link>
            </Fragment>
        )}


        {!completed && current && (
            <Fragment>
                {!end && (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                )}
                <Link to={to} className="relative flex items-start group" aria-current="step">
                    <span className="h-9 flex items-center" aria-hidden="true">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                            <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                        </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">{name}</span>
                        <span className="text-sm text-gray-500">{description}</span>
                    </span>
                </Link>
            </Fragment>
        )}

        {!completed && !current && (
            <Fragment>
                {!end && (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                )}
                <Link to={to} className="relative flex items-start group">
                    <span className="h-9 flex items-center" aria-hidden="true">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                            <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                        </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">{name}</span>
                        <span className="text-sm text-gray-500">{description}</span>
                    </span>
                </Link>
            </Fragment>
        )}
    </li>
)

export const Progress = ({ items = [], currentStep = null }) => {
    return (
        <nav aria-label="Progress">
            <ol className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                {
                    items.map((item, index) => (
                        <ProgressItem
                            key={index}
                            number={index + 1}
                            name={item.name}
                            to={item.to}
                            disabled={item.disabled}
                            end={items.length === (index + 1)}
                            completed={currentStep >= (index + 1)}
                        />
                    ))
                }
            </ol>
        </nav>
    )
}

const ProgressItem = ({ number, completed = false, end = false, name, to = null, disabled }) => (
    <li className="relative md:flex-1 md:flex">
        {completed && (
            <NavLink end to={to} className="group flex items-center w-full" onClick={(event) => {
                if (disabled) {
                    event.preventDefault()
                }
            }}>
                {({ isActive }) => (
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                            <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                        </span>
                        <span className={classNames("ml-4 text-sm font-medium ", isActive ? 'text-indigo-600' : 'text-gray-900')}>{name}</span>
                    </span>
                )}
            </NavLink>
        )}

        {!completed && (
            <NavLink end to={to} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step" onClick={(event) => {
                if (disabled) {
                    event.preventDefault()
                }
            }}>
                {({ isActive }) => (
                    <Fragment>
                        <span className={classNames(isActive ? "flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full" : "flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400")}>
                            <span className={classNames(isActive ? "text-indigo-600" : "text-gray-500 group-hover:text-gray-900")}>{number}</span>
                        </span>
                        <span className={classNames(isActive ? "ml-4 text-sm font-medium text-indigo-600" : "ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900")}>{name}</span>
                    </Fragment>
                )}
            </NavLink>
        )}

        {end ? null : (
            <Fragment>
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                    <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </Fragment>
        )}
    </li>
)