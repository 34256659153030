import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html";

export function transformResponseToList(data, defaultValue = []) {
    return data?.data?.data?.list || defaultValue
}

export function transformResponseToPagination(data, defaultValue = { currentPage: 1, pageSize: 10, pageCount: 0, isMore: true, itemCount: 0 }) {
    return data?.data?.data?.pagination || defaultValue
}

export function transformResponseToSingle(data, defaultValue = null) {
    return data?.data?.data || defaultValue
}

export function transformResponseError(error, defaultValue = {}) {
    return error?.response?.data?.errors || defaultValue
}

export function transformErrorNetwork(error) {
    if (error.response) {
        switch (parseInt(error.response.status)) {
            case 500: return 'Sedang terjadi kesalahan pada Server'
            case 404: return 'Koneksi ke server tidak temukan'
            case 403: return 'Kamu tidak memiliki akses'
            case 422: {
                const errors = Object.values(error.response.data?.errors || {}).flat()
                return errors.length > 0 ? errors[0] : 'Ada salah input, periksa kembali'
            }
        }
    }
    if (error.request) {
        return error.request._response || 'Error on Request'
    }
    if (error.message) {
        return error.message
    }
    return typeof error === 'object' ? JSON.stringify(error) : error
}

export function transformDraftEditorStateToHTML(editorState) {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    return draftToHtml(rawContentState)
}

export function sanitizeClauseGroups(clauseGroups) {
    return clauseGroups.map(clauseGroup => ({
        ...clauseGroup,
        clausal: clauseGroup.clausal.map(clausal => {
            const { editorState, ...rest } = clausal
            return rest
        })
    }))
}

export function transformClauseGroupsRaw(clauseGroups) {
    return clauseGroups.map(clauseGroup => ({
        ...clauseGroup,
        clausal: clauseGroup.clausal.map(clausal => {
            const blocksFromHTML = convertFromHTML(clausal.content)
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            return {
                ...clausal,
                editorState: EditorState.createWithContent(state)
            }
        })
    }))
}