import { Fragment, useState } from 'react'
import { Switch } from '@headlessui/react'
import { RadioGroup } from '@headlessui/react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, SpeakerphoneIcon, XIcon } from '@heroicons/react/solid'
import PortalLayout from 'Layout/PortalLayout'
import { useSubmitDraftJoin } from 'Data/DraftJoin'
import { CompanySectorType, useDataCompanySectors } from 'Data/Company'
import classNames from 'classnames'
import { transformResponseToList, transformResponseToSingle } from '../../../Utility/transformer'
import { useRef } from 'react'

export default function Join() {
    const [agreed, setAgreed] = useState(false)
    const [selectedCompanySector, setSelectedCompanySector] = useState(null)
    const [companyName, setCompanyName] = useState('')
    const [companyCode, setCompanyCode] = useState('')
    const [companyTaxIdentification, setCompanyTaxIdentification] = useState('')
    const [officerName, setOfficerName] = useState('')
    const [officerEmail, setOfficerEmail] = useState('')
    const [officerPhone, setOfficerPhone] = useState('')
    const [savedDraftJoin, setSavedDraftJoin] = useState(null)
    const [companySectorName, setCompanySectorName] = useState('')
    const dataCompanySectors = useDataCompanySectors({})
    const companySectors = transformResponseToList(dataCompanySectors.data).slice(0, 5)
    const submitDraftJoin = useSubmitDraftJoin({
        onSuccess: (response) => {
            if (response.status === 200) {
                const draftJoin = transformResponseToSingle(response)
                setSavedDraftJoin(draftJoin)

                setCompanyCode('')
                setCompanyName('')
                setCompanyTaxIdentification('')
                setOfficerName('')
                setOfficerEmail('')
                setOfficerPhone('')
                setSelectedCompanySector(CompanySectorType[0])
            }
        }
    })

    const submit = (event) => {
        event.preventDefault()
        submitDraftJoin.mutate({
            companySectorID: selectedCompanySector === null ? null : selectedCompanySector.ID,
            companySectorName: selectedCompanySector === null ? companySectorName : null,
            companyName, companyCode, companyTaxIdentification,
            officerName, officerEmail, officerPhone: officerPhone.startsWith('+62') ? officerPhone : `+62${officerPhone}`
        })
    }

    return (
        <PortalLayout>
            <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
                <div className="relative max-w-xl mx-auto">
                    <svg
                        className="absolute left-full transform translate-x-1/2"
                        width={404}
                        height={404}
                        fill="none"
                        viewBox="0 0 404 404"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                    </svg>
                    <svg
                        className="absolute right-full bottom-0 transform -translate-x-1/2"
                        width={404}
                        height={404}
                        fill="none"
                        viewBox="0 0 404 404"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                    </svg>
                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Gabung DUDI</h2>
                        <p className="mt-4 text-lg leading-6 text-gray-500">
                            Silakan isi formulir di bawah ini untuk selanjutnya dapat kita proses naskah kemitraan secara bersama
                        </p>
                    </div>
                    <div className="mt-12">
                        <form onSubmit={submit} method="POST" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                            <div className='sm:col-span-2'>
                                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                    Nama DUDI
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={companyName}
                                        onChange={({ target: { value } }) => setCompanyName(value)}
                                        className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="companyCode" className="block text-sm font-medium text-gray-700">
                                    NIB DUDI
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="companyCode"
                                        name="companyCode"
                                        type="text"
                                        value={companyCode}
                                        onChange={({ target: { value } }) => setCompanyCode(value)}
                                        className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="companyTaxIdentification" className="block text-sm font-medium text-gray-700">
                                    NPWP DUDI
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="companyTaxIdentification"
                                        name="companyTaxIdentification"
                                        value={companyTaxIdentification}
                                        type="text"
                                        onChange={({ target: { value } }) => setCompanyTaxIdentification(value)}
                                        className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <RadioGroup value={selectedCompanySector} onChange={setSelectedCompanySector}>
                                    <RadioGroup.Label className="block text-sm font-medium text-gray-700">Bidang DUDI</RadioGroup.Label>
                                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                        {companySectors.map((companySector) => (
                                            <RadioGroup.Option
                                                key={companySector.ID}
                                                value={companySector}
                                                className={({ checked, active }) =>
                                                    classNames(
                                                        checked ? 'border-transparent' : 'border-gray-300',
                                                        active ? 'ring-2 ring-indigo-500' : '',
                                                        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none max-h-36'
                                                    )
                                                }
                                            >
                                                {({ checked, active }) => (
                                                    <>
                                                        <div className="flex-1 flex">
                                                            <div className="flex flex-col">
                                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                    {companySector.name}
                                                                </RadioGroup.Label>
                                                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500 overflow-y-hidden">
                                                                    {companySector.description}
                                                                </RadioGroup.Description>
                                                            </div>
                                                        </div>
                                                        <CheckCircleIcon
                                                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                            aria-hidden="true"
                                                        />
                                                        <div
                                                            className={classNames(
                                                                active ? 'border' : 'border-2',
                                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                                'absolute -inset-px rounded-lg pointer-events-none'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        ))}
                                        <div
                                            onClick={() => setSelectedCompanySector(null)}
                                            className={classNames(
                                                selectedCompanySector === null ? 'ring-2 ring-indigo-500' : '',
                                                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                            )}
                                        >
                                            <div className="flex-1 flex">
                                                <div className="flex flex-col">
                                                    <div as="span" className="block text-sm font-medium text-gray-900">
                                                        Lainnya
                                                    </div>
                                                    <div as="span" className="mt-1 flex items-center text-sm text-gray-500">

                                                    </div>
                                                </div>
                                            </div>
                                            <CheckCircleIcon
                                                className={classNames(selectedCompanySector !== null ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                aria-hidden="true"
                                            />
                                            <div
                                                className={classNames(
                                                    selectedCompanySector === null ? 'border-indigo-500' : 'border-transparent',
                                                    'absolute -inset-px rounded-lg pointer-events-none'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </RadioGroup>
                            </div>
                            {selectedCompanySector === null && (
                                <div className='sm:col-span-2'>
                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                        Bidang DUDI Lainnya
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            value={companySectorName}
                                            onChange={({ target: { value } }) => setCompanySectorName(value)}
                                            className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                            )}
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Nama PIC
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="officerName"
                                        name="officerName"
                                        type="text"
                                        autoComplete="name"
                                        value={officerName}
                                        onChange={({ target: { value } }) => setOfficerName(value)}
                                        className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="officerEmail"
                                        name="officerEmail"
                                        type="email"
                                        autoComplete="email"
                                        value={officerEmail}
                                        onChange={({ target: { value } }) => setOfficerEmail(value)}
                                        className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                                    No. Telp
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 flex items-center">
                                        <label htmlFor="country" className="sr-only">
                                            Negara Asal
                                        </label>
                                        <select
                                            id="country"
                                            name="country"
                                            className="h-full py-0 pl-4 pr-8 border-transparent bg-transparent text-gray-500 focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                                        >
                                            <option>ID</option>
                                        </select>
                                    </div>
                                    <input
                                        type="text"
                                        name="officerPhone"
                                        id="officerPhone"
                                        autoComplete="tel"
                                        value={officerPhone}
                                        onChange={({ target: { value } }) => setOfficerPhone(value)}
                                        className="py-3 px-4 block w-full pl-20 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        placeholder="+62 81111-45100"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <Switch
                                            checked={agreed}
                                            onChange={setAgreed}
                                            className={classNames(
                                                agreed ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                            )}
                                        >
                                            <span className="sr-only">Agree to policies</span>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    agreed ? 'translate-x-5' : 'translate-x-0',
                                                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                )}
                                            />
                                        </Switch>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-base text-gray-500">
                                            Dengan menyetujui pilihan ini maka anda telah menerima {' '}
                                            <a href="#" className="font-medium text-gray-700 underline">
                                                Privacy Policy
                                            </a>{' '}
                                            dan{' '}
                                            <a href="#" className="font-medium text-gray-700 underline">
                                                Cookie Policy
                                            </a>
                                            .
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <button
                                    type="submit"
                                    disabled={!agreed || submitDraftJoin.isLoading}
                                    className={classNames(
                                        "w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                                        (!agreed || submitDraftJoin.isLoading) ? "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500" : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                                    )}
                                >
                                    {submitDraftJoin.isLoading ? 'Memproses ...' : 'Bergabung'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SuccessSave draftJoin={savedDraftJoin} onClose={() => setSavedDraftJoin(null)} />
        </PortalLayout>

    )
}


const SuccessSave = ({ draftJoin, onClose }) => {
    const cancelButtonRef = useRef(null)
    return (
        <Transition.Root show={draftJoin !== null} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
                            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                                <div className="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
                                    <div className="flex items-center justify-between flex-wrap">
                                        <div className="w-0 flex-1 flex items-center">
                                            <span className="flex p-2 rounded-lg bg-indigo-800">
                                                <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </span>
                                            <p className="ml-3 font-medium text-white truncate">
                                                <span className="md:hidden">Selamat anda sudah terdaftar!</span>
                                                <span className="hidden md:inline">Selamat anda sudah terdaftar!, akan ada lagi tahapan verifikasi dari kami dan audiensi, silahkan ditunggu kabarnya.</span>
                                            </p>
                                        </div>
                                        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                                            <a
                                                href="#"
                                                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
                                            >
                                                Pelajari lebih lanjut
                                            </a>
                                        </div>
                                        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                            <button
                                                onClick={onClose}
                                                type="button"
                                                className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <span className="sr-only">Dismiss</span>
                                                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}