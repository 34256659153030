import { Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { SearchIcon, SelectorIcon } from '@heroicons/react/solid'
import { DocumentTextIcon as DocumentTextIconOutline, TemplateIcon as TemplateIconOutline, LibraryIcon as LibraryIconOutline } from '@heroicons/react/outline'
import { DocumentTextIcon as DocumentTextIconSolid, TemplateIcon as TemplateIconSolid, LibraryIcon as LibraryIconSolid } from '@heroicons/react/solid'
import { useAuth } from '../../App'
import classNames from 'classnames'
import SidebarMenu from './SidebarMenu'
import ProfileMenuItems from './ProfileMenuItem'
import { ReactComponent as Logo } from 'Asset/Logo/department-logo-small.svg'
import { authenticationProvider } from 'Provider/Authentication'

export default function Sidebar({ sidebarOpen, setSidebarOpen, setIsNeedConfirmationForLogout }) {
    const { user } = useAuth()

    return (
        <Fragment>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <Logo
                                    className="h-8 w-auto"
                                    alt="Mitras DUDI"
                                />
                            </div>
                            <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                <nav className="px-2">
                                    <div className="space-y-1">
                                        <SidebarMenus />
                                    </div>
                                    <div className="mt-8">
                                        <h3
                                            className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                                            id="mobile-teams-headline"
                                        >
                                            Status Naskah
                                        </h3>
                                        <div className="mt-1 space-y-1" role="group" aria-labelledby="mobile-teams-headline">
                                            <ContractStatusBadges />
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-100">
                <div className="flex items-center flex-shrink-0 px-6">
                    <Logo
                        className="h-8 w-auto"
                        alt="Mitras DUDI"
                    />
                    <div className='ml-4'>
                        <h1 className='text-sm -mb-1.5'>
                            Kemitraan
                        </h1>
                        <h2 className='text-xl'>
                            Mitras DUDI
                        </h2>
                    </div>
                </div>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
                    {/* User account dropdown */}
                    <Menu as="div" className="px-3 relative inline-block text-left">
                        <div>
                            <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                                <span className="flex w-full justify-between items-center">
                                    <span className="flex min-w-0 items-center justify-between space-x-3">
                                        <img
                                            className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                                            src={user.avatarURL || 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80'}
                                            alt=""
                                        />
                                        <span className="flex-1 flex flex-col min-w-0">
                                            <span className="text-gray-900 text-sm font-medium truncate">{user.email}</span>
                                            <span className="text-gray-500 text-sm truncate">{authenticationProvider.roleLabel()}</span>
                                        </span>
                                    </span>
                                    <SelectorIcon
                                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                <ProfileMenuItems setIsNeedConfirmationForLogout={(value) => setIsNeedConfirmationForLogout(value)} />
                            </Menu.Items>

                        </Transition>
                    </Menu>
                    {/* Sidebar Search */}
                    <div className="px-3 mt-5">
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div
                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                                aria-hidden="true"
                            >
                                <SearchIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                name="search"
                                id="search"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm border-gray-300 rounded-md"
                                placeholder="Pencarian"
                            />
                        </div>
                    </div>
                    {/* Navigation */}
                    <nav className="px-3 mt-6">
                        <div className="space-y-1">
                            <SidebarMenus />
                        </div>
                        <div className="mt-8 hidden">
                            {/* Secondary navigation */}
                            <h3
                                className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                                id="desktop-teams-headline"
                            >
                                Status Naskah
                            </h3>
                            <div className="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
                                <ContractStatusBadges />
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </Fragment>
    )
}

const ContractStatusBadge = ({ colorClass, name }) => (
    <button className="group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
        <span className={classNames(colorClass, 'w-2.5 h-2.5 mr-4 rounded-full')} aria-hidden="true" />
        <span className="truncate">
            {name}
        </span>
    </button>
)

const ContractStatusBadges = () => [
    <ContractStatusBadge key={1} colorClass="bg-fuchsia-400" name="Konstruksi Pasal" />,
    <ContractStatusBadge key={2} colorClass="bg-indigo-400" name="Tanda Tangan" />,
    <ContractStatusBadge key={3} colorClass="bg-lime-400" name="Selesai" />,
]

const SidebarMenus = () => (({
    'Contract-Operator': [
        <SidebarMenu key={1} end to="/dashboard" name="Beranda" />,
        <SidebarMenu key={2} to="/dashboard/contract" name="Naskah" icons={[DocumentTextIconOutline, DocumentTextIconSolid]} />,
        <SidebarMenu key={3} to="/dashboard/template" name="Template" icons={[TemplateIconOutline, TemplateIconSolid]} />,
        <SidebarMenu key={4} to="/dashboard/company" name="DUDI" icons={[LibraryIconOutline, LibraryIconSolid]} />,
    ],
    'Contract-Validator': [
        <SidebarMenu key={1} end to="/dashboard" name="Beranda" />,
        <SidebarMenu key={2} to="/dashboard/contract" name="Naskah" icons={[DocumentTextIconOutline, DocumentTextIconSolid]} />,
    ],
    'Company-Signer': [
        <SidebarMenu key={1} end to="/dashboard" name="Beranda" />,
        <SidebarMenu key={2} to="/dashboard/contract" name="Naskah" icons={[DocumentTextIconOutline, DocumentTextIconSolid]} />,
    ],
})[authenticationProvider.role()])