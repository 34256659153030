export const descriptions = [
    {
        title: 'Visi',
        content: 'Mewujudkan Indonesia Maju yang berdaulat,mandiri, dan berkepribadian melalui terciptanya Pelajar Pancasila yang bernalar kritis, kreatif, mandiri, beriman, bertakwa kepada Tuhan YME, dan berakhlak mulia, bergotong-royong, dan berkebhinekaan global.',
        imageSource: require('Asset/Content/About-Us/6327e721bd11271597dce19a_WhatsApp Image 2022-09-19 at 10.50.15 AM (2).jpeg'),
    },
    {
        title: 'Misi',
        content: (
            <ol className="list-[roman] ml-5">
                <li>Mewujudkan Pendidikan yang relevan dan berkualitas tinggi, merata dan berkelanjutan, didukung oleh infrastruktur dan teknologi.</li>
                <li>Mewujudkan pelestarian dan pemajuan kebudayaan serta pengembangan Bahasa dan sastra Bergotong royong.</li>
                <li>Mengoptimalkan peran serta seluruh pemangku kepentingan untuk mendukung transformasi dan reformasi pengelolaan Pendidikan dan kebudayaan.</li>
            </ol>
        ),
        imageSource: require('Asset/Content/About-Us/6327e721f8acef029d7bebdd_WhatsApp Image 2022-09-19 at 10.37.10 AM.jpeg'),
    },
    {
        title: 'Tujuan',
        content: (
            <div>
                <span>Sebagai sub sistem dari Ditjen Pendidikan Vokasi (sesuai Peraturan Menteri Pendidikan, Kebudayaan,Riset, dan Teknologi Republik Indonesia Nomor 28 Tahun 2021 Tentang Organisasi dan Tata Kerja Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi), kerangka kerja Dit. Mitras DUDI merupakan penjabaran dari Tujuan Strategis Ditjen Pendidikan Vokasi, yaitu:</span>
                <ol className="list-[roman] ml-5 mt-3">
                    <li>Meningkatnya kualitas pembelajaran dan relevansi pendidikan di seluruh jenjang.</li>
                    <li>Meningkatnya kualitas pendidik dan tenaga kependidikan di seluruh jenjang.</li>
                </ol>
            </div>
        ),
        imageSource: require('Asset/Content/About-Us/6327e72194be2a80ae45e00d_WhatsApp Image 2022-09-19 at 10.50.15 AM (3).jpeg'),
    },
    {
        title: 'Tugas & Fungsi',
        content: 'Melaksanakan perumusan dan pelaksanaan kebijakan, penyusunan norma, standar, prosedur, dan kriteria, fasilitasi, pemberian bimbingan teknis dan supervisi, pemantauan, evaluasi, dan pelaporan di bidang kemitraan dan penyelarasan dunia usaha dan dunia industri dengan sekolah menengah kejuruan, pendidikan tinggi vokasi dan profesi, dan pendidikan keterampilan dan pelatihan kerja serta urusan ketatausahaan Direktorat.',
        imageSource: require('Asset/Content/About-Us/6327e72123118e96b620c14d_WhatsApp Image 2022-09-19 at 10.37.11 AM.jpeg'),
    },
    {
        title: 'Struktur',
        content: 'Struktur organisasi Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri',
        imageSource: require('Asset/Content/About-Us/6327ebd5978cc4f5704a2deb_Struktur.png'),
    },
    {
        title: 'Pencapaian Program',
        content: (
            <ol className="list-disc ml-5">
                <li>Gerakan Nasional Bangga Buatan Indonesia (Gernas BBI)</li>
                <li><span className="italic">Upskilling</span> & <span className="italic">Reskilling</span> Guru Kejuruan</li>
                <li>PjBL siswa dan guru SMK</li>
                <li>Kemitraan & Penyelarasan SMK PK</li>
                <li>Dudi <span className="italic">Awards</span></li>
                <li><span className="italic">CEO</span> SMK<span className="italic">Awards</span></li>
                <li><span className="italic">Super Tax Deductions</span>(<span className="italic">StD</span>)</li>
            </ol>
        ),
        imageSource: require('Asset/Content/About-Us/6327e721acd3d827ae7d24f5_WhatsApp Image 2022-09-19 at 10.42.52 AM (2).jpeg'),
    },
]