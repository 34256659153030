import { Fragment } from "react";
import {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
} from '@heroicons/react/solid'
import { useDraftJoinContext } from "../DetailDraftCompany";
import moment from "moment";
import { useDataCompanySigners } from "Data/CompanySigner";
import { transformResponseToList } from "../../../Utility/transformer";

export default function DetailDraftCompanyBrowseOfficer() {
    const { draftJoin } = useDraftJoinContext()
    const dataCompanySigners = useDataCompanySigners({ companyID: draftJoin?.published?.companyID })
    const companySigners = transformResponseToList(dataCompanySigners.data)
    return (
        <Fragment>
            <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
                {!draftJoin.published && (
                    <li>
                        <span className="group block">
                            <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="flex-shrink-0">
                                        <div className="h-12 w-12 bg-gray-500 rounded-full group-hover:opacity-75">

                                        </div>
                                    </div>
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                            <p className="text-sm font-medium text-purple-600 truncate">{draftJoin.officerName}</p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span className="truncate">{draftJoin.officerEmail}</span>
                                            </p>
                                        </div>
                                        <div className="hidden md:block">
                                            <div>
                                                <p className="text-sm text-gray-900">
                                                    Applied on <time dateTime={draftJoin.created}>{moment(draftJoin.created, 'YYYY-MM-DD HH:mm:ss').format('D MMMM, YY')}</time>
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <CheckCircleIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                        aria-hidden="true"
                                                    />
                                                    {draftJoin.published ? 'Aktif' : 'Belum Aktif'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ChevronRightIcon
                                        className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </span>
                    </li>
                )}
                {draftJoin.published && companySigners.map(companySigner => (
                    <li key={companySigner.ID}>
                        <span className="group block">
                            <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="flex-shrink-0">
                                        <div className="h-12 w-12 bg-gray-500 rounded-full group-hover:opacity-75">

                                        </div>
                                    </div>
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                            <p className="text-sm font-medium text-purple-600 truncate">{companySigner.officerName}</p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span className="truncate">{companySigner.officerEmail}</span>
                                            </p>
                                        </div>
                                        <div className="hidden md:block">
                                            <div> 
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <CheckCircleIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                        aria-hidden="true"
                                                    />
                                                   Aktif
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ChevronRightIcon
                                        className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </span>
                    </li>
                ))}
            </ul>
        </Fragment>
    )
}