import { PlusIcon, SwitchHorizontalIcon, SwitchVerticalIcon, TrashIcon } from "@heroicons/react/outline"
import { useState } from "react"
import ContractEditorLayout from "Layout/ContractEditorLayout"
import ModalAssignContractSigner from "./ModalAssignContractSigner"
import { useContractContext } from "../ContractEditor"
import { useAssignContractSigner, useRemoveContractSigner } from "Data/Contract"
import { authenticationProvider } from "Provider/Authentication"

export default function AssignCompany() {
    const { dataContract, contract } = useContractContext()
    const assignContractSigner = useAssignContractSigner({ onSuccess: () => dataContract.refetch() })
    const removeContractSigner = useRemoveContractSigner({ onSuccess: () => dataContract.refetch() })
    const [assignOrder, setAssignOrder] = useState(null)

    const first = contract.contractSigners.find(contractSigner => contractSigner.order == 1)
    const second = contract.contractSigners.find(contractSigner => contractSigner.order == 2)

    const onSelectedCompanySigner = (companySigner) => {
        assignContractSigner.mutate({
            ID: contract.ID, order: assignOrder,
            companySignerID: companySigner.ID
        })
        setAssignOrder(null)
        dataContract.refetch()
    }

    const removeContractAssignByOrder = (order) => {
        removeContractSigner.mutate({ ID: contract.ID, order })
        dataContract.refetch()
    }


    return (
        <ContractEditorLayout contract={contract} appendBreadcrumbs={[{ name: <span>Naskah: <span className="font-bold">{contract.name}</span></span>, 'to': `/dashboard/contract/${contract.ID}` }]} className="">
            <div role="list" className="flex flex-row gap-10 justify-center items-center">
                {first && <ContractSigner contractSigner={first} onChangeSelection={() => !authenticationProvider.isContractOperator() ? null : setAssignOrder(1)} onRemoveSelection={() => removeContractAssignByOrder(1)} />}
                {!first && <EmptyContractSigner order={1} onClick={() => !authenticationProvider.isContractOperator() ? null : setAssignOrder(1)} />}
                <SwitchHorizontalIcon className="h-10 w-10 animate-pulse" />
                {second && <ContractSigner contractSigner={second} onChangeSelection={() => !authenticationProvider.isContractOperator() ? null : setAssignOrder(2)} onRemoveSelection={() => removeContractAssignByOrder(2)} />}
                {!second && <EmptyContractSigner order={2} onClick={() => !authenticationProvider.isContractOperator() ? null : setAssignOrder(2)} />}
            </div>
            <ModalAssignContractSigner
                show={assignOrder !== null}
                assignOrder={assignOrder}
                onClose={() => setAssignOrder(null)}
                onSelected={onSelectedCompanySigner}
            />
        </ContractEditorLayout>
    )
}


const ContractSigner = ({ contractSigner, onChangeSelection, onRemoveSelection }) => (
    <div className="w-4/12 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div className="flex-1 flex flex-col p-8">
            <h1>Pihak Ke-{contractSigner.order}</h1>
            <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={contractSigner.companySigner.company.urlLogo} alt="" />
            <h3 className="mt-6 text-gray-900 text-sm font-medium">{contractSigner.companySigner.company.name}</h3>
            <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Nama Pejabat</dt>
                <dd className="text-gray-500 text-sm">{contractSigner.companySigner.officerName}</dd>
                <dt className="sr-only">Role</dt>
                <dd className="mt-3">
                    <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                        {contractSigner.companySigner.officerPosition}
                    </span>
                </dd>
            </dl>
        </div>
        <div>
            <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                    <button
                        onClick={() => authenticationProvider.isCompanySigner() ? null : onRemoveSelection()}
                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                    >
                        <TrashIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        <span className="ml-3">Lepas</span>
                    </button>
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                    <button

                        onClick={() => authenticationProvider.isCompanySigner() ? null : onChangeSelection()}
                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                    >
                        <SwitchVerticalIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        <span className="ml-3">Ganti</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
)


const EmptyContractSigner = ({ order = 1, onClick }) => (
    <div onClick={onClick} className="w-4/12 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 py-20 px-4">
        <div className="text-center">
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
            >
                <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Belum ada yang menjadi Pihak-{order}</h3>
            <p className="mt-1 text-sm text-gray-500">Silakan pilih yang sudah terdaftar dalam database atau buat baru.</p>
            <div className="mt-6">
                <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Tambah
                </button>
            </div>
        </div>
    </div>
)