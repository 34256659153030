import DashboardLayout from "Layout/DashboardLayout";
import { ArrowSmUpIcon } from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { BadgeCheckIcon, CursorClickIcon, LibraryIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'
import classNames from "classnames";
import { useDataCompanies } from "Data/Company";
import { useDataDraftJoins } from "Data/DraftJoin";
import { transformResponseToList, transformResponseToPagination } from "../../../Utility/transformer";
import { Link } from "react-router-dom";
import InviteDraftJoin from "../BrowseDraftCompany/InviteDraftJoin";
import PublishDraftJoin from "./PublishDraftJoin";
import moment from "moment";
import { Fragment, useState } from "react";

export default function BrowseCompany() {
    const dataPublishedCompanies = useDataCompanies({ published: true })
    const dataNonPublishedCompanies = useDataCompanies({ published: false })
    const dataDraftJoins = useDataDraftJoins({ sort: 'ID', order: 'desc' })
    const draftJoins = transformResponseToList(dataDraftJoins.data)
    const dataPublishedCompaniesPagination = transformResponseToPagination(dataPublishedCompanies.data)
    const dataNonPublishedCompaniesPagination = transformResponseToPagination(dataNonPublishedCompanies.data)
    const dataDraftJoinsPagination = transformResponseToPagination(dataDraftJoins.data)
    const [selectedInvite, setSelectedInvite] = useState(null)
    const [selectedPublish, setSelectedPublish] = useState(null)


    const composeMessage = (draftJoin) => {
        const message = `
            Halo, ${draftJoin.officerName} anda diundang audiens oleh Mitras DUDI secara ${draftJoin.invited.type} pada ${draftJoin.invited.date} di ${draftJoin.invited.location}. 
            Untuk informasi lebih lanjjut dapat menghubungi ${draftJoin.invited.contactName} di ${draftJoin.invited.contactPhone}. Terima kasih
        `
        return message
    }
    return (
        <DashboardLayout>
            <div className="container mx-auto sm:px-6 lg:px-8 mt-20">
                <div>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                            <dt>
                                <div className="absolute bg-indigo-500 rounded-md p-3">
                                    <BadgeCheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                                    Total DUDI terverifikasi
                                </p>
                            </dt>
                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">
                                    {dataPublishedCompaniesPagination.itemCount}
                                </p>
                                <p
                                    className={classNames(
                                        'text-green-600 hidden',
                                        'ml-2 flex items-baseline text-sm font-semibold'
                                    )}
                                >
                                    <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />

                                    <span className="sr-only">Bertambah sebanyak</span>
                                    2
                                </p>
                                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                        <Link to="/dashboard/company" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            {' '}
                                            Liat semua<span className="sr-only">Total Instansi terverifikasi</span>
                                        </Link>
                                    </div>
                                </div>
                            </dd>
                        </div>
                        <div className="relative bg-indigo-500 pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                            <dt>
                                <div className="absolute bg-white rounded-md p-3">
                                    <LibraryIcon className="h-6 w-6 text-indigo-500" aria-hidden="true" />
                                </div>
                                <p className="ml-16 text-sm font-medium text-white truncate">
                                    Total DUDI mendaftar
                                </p>
                            </dt>
                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                <p className="text-2xl font-semibold text-white">
                                    {dataDraftJoinsPagination.itemCount}
                                </p>
                                <p
                                    className={classNames(
                                        'text-green-600 hidden',
                                        'ml-2 flex items-baseline text-sm font-semibold'
                                    )}
                                >
                                    <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />

                                    <span className="sr-only">Bertambah sebanyak</span>
                                    2
                                </p>
                                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                        <span className="font-medium text-gray-600 hover:text-gray-500">
                                            {' '}
                                            Liat semua<span className="sr-only">Total Instansi terverifikasi</span>
                                        </span>
                                    </div>
                                </div>
                            </dd>
                        </div>
                        <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                            <dt>
                                <div className="absolute bg-indigo-500 rounded-md p-3">
                                    <LibraryIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                                    Total non-DUDI
                                </p>
                            </dt>
                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">
                                    {dataNonPublishedCompaniesPagination.itemCount}
                                </p>
                                <p
                                    className={classNames(
                                        'text-green-600 hidden',
                                        'ml-2 flex items-baseline text-sm font-semibold'
                                    )}
                                >
                                    <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />

                                    <span className="sr-only">Bertambah sebanyak</span>
                                    2
                                </p>
                                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                        <Link to="/dashboard/company/non" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            {' '}
                                            Liat semua<span className="sr-only">Total Instansi terverifikasi</span>
                                        </Link>
                                    </div>
                                </div>
                            </dd>
                        </div>
                    </dl>
                </div>
                <div className="flex flex-col mt-10">
                    <h1 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Daftar DUDI Mendaftar</h1>
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-2">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                DUDI
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Terbuat
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                PIC
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Status Audiensi
                                            </th>
                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {draftJoins.map((draftJoin) => (
                                            <tr key={draftJoin.ID}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-10 w-10">
                                                            <BadgeCheckIcon className={classNames("h-7 w-7 rounded-full", draftJoin.published ? "text-green-400" : "opacity-10")} src={draftJoin.urlLogo} alt="" />
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">{draftJoin.companyName}</div>
                                                            <div className="text-sm text-gray-500">{draftJoin.companySector.name}</div>
                                                            <div className="text-sm text-gray-500">{draftJoin.companyCode}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">{draftJoin.created}</div>
                                                    <div className="text-xs text-gray-900">{moment(draftJoin.created, 'YYYY-MM-DD HH:mm:ss').fromNow()}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">
                                                        <div className="flex items-center">
                                                            <div className="ml-4">
                                                                <div className="text-sm font-medium text-gray-900">{draftJoin.officerName}</div>
                                                                <div className="text-sm text-gray-500">{draftJoin.officeEmail}</div>
                                                                <div className="text-sm text-gray-500">{draftJoin.officerPhone}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className={classNames("px-2 inline-flex text-xs leading-5 font-semibold rounded-full", draftJoin.invited ? "bg-green-100 text-green-800" : "bg-gray-100 text-gray-800")}>
                                                        {draftJoin.invited ? 'Sudah diundang' : 'Belum diundang'}
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                                        <Link
                                                            to={`/dashboard/company/draft/${draftJoin.ID}`}
                                                            className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                        >
                                                            Lihat
                                                        </Link>
                                                        <Menu as="span" className="-ml-px relative block">
                                                            <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                                <span className="sr-only">Open options</span>
                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                            </Menu.Button>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75" F
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            !draftJoin.invited && (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            onClick={() => setSelectedInvite(draftJoin)}
                                                                                            type="default"
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'block px-4 py-2 text-sm'
                                                                                            )}
                                                                                        >
                                                                                            Undang
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        }
                                                                        {
                                                                            draftJoin.invited && (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            type="default"
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'block px-4 py-2 text-sm'
                                                                                            )}
                                                                                        >
                                                                                            <a href={`https://wa.me/${draftJoin.officerPhone}/?text=${encodeURIComponent(composeMessage(draftJoin))}`}>
                                                                                                Kirim Pesan WhatsApp
                                                                                            </a>
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        }

                                                                        {
                                                                            draftJoin.invited && (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            type="default"
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'block px-4 py-2 text-sm'
                                                                                            )}
                                                                                        >
                                                                                            <a href={`mailto:${draftJoin.officerEmail}?subject=${encodeURIComponent('Anda diundang')}&body=${encodeURIComponent(composeMessage(draftJoin))}`}>
                                                                                                Kirim Pesan Email
                                                                                            </a>
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        }
                                                                        {
                                                                            !draftJoin.published && (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            onClick={() => setSelectedPublish(draftJoin)}
                                                                                            type="default"
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'block px-4 py-2 text-sm'
                                                                                            )}
                                                                                        >
                                                                                            Verfikasi
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <InviteDraftJoin
                    draftJoin={selectedInvite}

                    onInvited={() => {
                        dataDraftJoins.refetch()
                        setSelectedInvite(null)
                    }}
                    onClose={() => setSelectedInvite(null)}
                />
                <PublishDraftJoin
                    draftJoin={selectedPublish}
                    onInvited={() => {
                        dataDraftJoins.refetch()
                        setSelectedPublish(null)
                    }}
                    onClose={() => setSelectedPublish(null)}
                />
            </div>
        </DashboardLayout>
    )
}