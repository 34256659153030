import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ReactComponent as Logo } from 'Asset/Logo/department-logo-small.svg'
import { useAuth } from 'App'
import { Link, NavLink } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'
import {navigation } from './content'

const currentYear = moment()

export default function PortalLayout({ backgroundColor='#faf6ef', children, navBarTextClassName = { invert: 'text-indigo-500 hover:text-indigo-900', base: 'text-base' } }) {
    const { user, signOut } = useAuth()

    return (
        <div style={{ backgroundColor: backgroundColor }} className="min-h-full w-full flex-1">
            <div className='flex-1'>
                <div className="relative bg-transparent z-10">
                    <Popover className="relative bg-transparent">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6">
                            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                                <div className="flex justify-start lg:w-0 lg:flex-1">
                                    <Link to="/">
                                        <span className="sr-only">Mitras DUDI</span>
                                        <Logo className='h-36 absolute -top-3 left-32' />
                                        <img className='absolute h-24 top-4 left-3' src={require('Asset/Content/Home/6321837383827a04a6ad2147_KEMENDIKBUD.png')} />
                                    </Link>
                                </div>
                                <div className="-mr-2 -my-2 md:hidden">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open menu</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-10">

                                    <Popover.Group as="nav" className="hidden md:flex space-x-10">
                                        <NavLink to="/" end className={({ isActive }) => classNames(navBarTextClassName.base, "font-medium", isActive ? navBarTextClassName.invert : "text-gray-500 hover:text-gray-900")}>
                                            Beranda
                                        </NavLink>
                                        <NavLink to="/about-us" end className={({ isActive }) => classNames(navBarTextClassName.base, "font-medium", isActive ? navBarTextClassName.invert : "text-gray-500 hover:text-gray-900")}>
                                            Tentang
                                        </NavLink>
                                        <NavLink to="/contact-us" end className={({ isActive }) => classNames(navBarTextClassName.base, "font-medium", isActive ? navBarTextClassName.invert : "text-gray-500 hover:text-gray-900")}>
                                            Kontak
                                        </NavLink>
                                        {
                                            user && (
                                                <NavLink to="/dashboard" end className={({ isActive }) => classNames(navBarTextClassName.base, "font-medium", isActive ? "text-indigo-500 hover:text-indigo-900" : "text-gray-500 hover:text-gray-900")}>
                                                    Dashboard
                                                </NavLink>
                                            )
                                        }
                                    </Popover.Group>
                                    {
                                        user ?
                                            <span className={classNames(navBarTextClassName.base, "whitespace-nowrap font-medium text-gray-500 hover:text-gray-900")}>
                                                Hi,
                                                <span className="font-medium text-indigo-600 hover:text-indigo-500 mx-1">
                                                    {user.email}
                                                </span>
                                                (<button onClick={() => signOut()}>Keluar</button>)
                                            </span> :
                                            <NavLink to="/login" className={({ isActive }) => classNames("whitespace-nowrap text-base font-medium", isActive ? navBarTextClassName.invert : navBarTextClassName.base)}>
                                                Masuk
                                            </NavLink>
                                    }
                                </div>
                            </div>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="duration-200 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
                            >
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                    <div className="pt-5 pb-6 px-5">
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <Logo />
                                            </div>
                                            <div className="-mr-2">
                                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                    <span className="sr-only">Tutup menu</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </Popover.Button>
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <nav className="grid gap-y-8">

                                            </nav>
                                        </div>
                                    </div>
                                    <div className="py-6 px-5 space-y-6">
                                        <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                                            <NavLink to="/" end className={({ isActive }) => classNames("text-base font-medium ", isActive ? "text-indigo-900 hover:text-indigo-700" : "text-gray-900 hover:text-gray-700")}>
                                                Beranda
                                            </NavLink>
                                            <NavLink to="/about-us" end className={({ isActive }) => classNames("text-base font-medium ", isActive ? "text-indigo-900 hover:text-indigo-700" : "text-gray-900 hover:text-gray-700")}>
                                                Tentang
                                            </NavLink>
                                            <NavLink to="/contact-us" end className={({ isActive }) => classNames("text-base font-medium ", isActive ? "text-indigo-900 hover:text-indigo-700" : "text-gray-900 hover:text-gray-700")}>
                                                Kontak
                                            </NavLink>
                                        </div>
                                        <div>
                                            <Link
                                                to="/join"
                                                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                Bergabung
                                            </Link>
                                            <p className="mt-6 text-center text-base font-medium text-gray-500">
                                                {
                                                    user ?
                                                        <span className="w">
                                                            Hi,
                                                            <span className="font-medium text-indigo-600 hover:text-indigo-500 mx-1">
                                                                {user.email}
                                                            </span>
                                                            (<button onClick={() => signOut()}>Keluar</button>)
                                                        </span> :
                                                        <NavLink to="/login" className="">
                                                            Masuk
                                                        </NavLink>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                </div>
                {children}
                <footer className="bg-gray-800" aria-labelledby="footer-heading">
                    <h2 id="footer-heading" className="sr-only">
                        Catatan Kaki
                    </h2>
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <div className="border-t border-gray-700 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Berlangganan dengan buletin kita
                                </h3>
                                <p className="mt-2 text-base text-gray-300">
                                    Untuk dapat mendapatkan informasi terkini dari Mitras DUDI
                                </p>
                            </div>
                            <form className="mt-4 sm:flex sm:max-w-md lg:mt-0">
                                <label htmlFor="email-address" className="sr-only">
                                    Surat elektronik
                                </label>
                                <input
                                    type="email"
                                    name="email-address"
                                    id="email-address"
                                    autoComplete="email"
                                    required
                                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400 sm:max-w-xs"
                                    placeholder="Masukan surel anda"
                                />
                                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                    <button
                                        type="submit"
                                        className="w-full bg-indigo-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                                    >
                                        Berlangganan
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
                            <div className="flex space-x-6 md:order-2">
                                {navigation.social.map((item) => (
                                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                                        <span className="sr-only">{item.name}</span>
                                        <item.icon className="h-6 w-6" aria-hidden="true" />
                                    </a>
                                ))}
                            </div>
                            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                                &copy; {currentYear.format('YYYY') !== '2022' && '2022 - '}{currentYear.format('YYYY')} Mitras DUDI. Semua hak dilindungi.
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}