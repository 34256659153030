import './index.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import toast, { Toaster } from 'react-hot-toast';

import Home from './Static/Home';
import AboutUs from './Static/AboutUs';
import ContactUs from './Static/ContactUs';
import DashboardHome from './Dashboard/DashboardHome';
import CompanyDetail from './Company/CompanyDetail';
import BrowseContract from './Contract/BrowseContract';
import BrowseTemplate from './Template/BrowseTemplate';
import CreateContract from './Contract/CreateContract';
import ContractEditor from './Contract/ContractEditor';
import BrowseCompanyPublished from './Company/BrowseCompanyPublished';
import BrowseDraftCompany from './Company/BrowseDraftCompany';
import Login from './Authentication/Login'
import Join from './Authentication/Join';
import NotFound from './Static/NotFound';
import DetailDraftCompany from './Company/DetailDraftCompany';
import DetailCompany from './Company/DetailCompany';
import BrowseCompanyNonPublished from './Company/BrowseCompanyNonPublished';
import SignatureWithToken from './Contract/SignatureWithToken';
import DisplaySignatureWithToken from './Contract/DisplaySignatureWithToken';

import { createContext, Fragment, useContext, useState } from 'react';
import { authenticationProvider } from 'Provider/Authentication';
import { useLoggedUser } from 'Data/Account';
import { CheckCircleIcon, PlusCircleIcon, XIcon } from '@heroicons/react/outline';
import useEffectOnce from '../Utility/useEffectOnce';
import { Transition } from '@headlessui/react';
import { transformErrorNetwork, transformResponseError } from 'Utility/transformer';

const queryClient = new QueryClient()
const AuthContext = createContext()

function AuthProvider({ children }) {
  let [user, setUser] = useState(false);
  const dataLoggedUser = useLoggedUser({
    enabled: false,
    onSuccess: ({ data: response }) => {
      const data = response.data
      signIn(data.account, authenticationProvider.role(), authenticationProvider.token())
    }, 
    onError: (error)=> {
      authenticationProvider.out(() => {
        setUser(null)
      })
      toast.error(transformErrorNetwork(error))
    }
  })

  let signIn = (loggedInUser, role, token) => {
    return authenticationProvider.in(token, role, () => {
      setUser(loggedInUser)
    })
  }

  let signOut = () => {
    return authenticationProvider.out(() => {
      setUser(null)
    })
  }


  useEffectOnce(function init() {
    if (authenticationProvider.authenticated()) {
      dataLoggedUser.refetch()
    } else {
      setUser(null)
    }

  })

  return <AuthContext.Provider value={{ user, signIn, signOut }}>{children}</AuthContext.Provider>
}

function useAuth() {
  return useContext(AuthContext)
}

function Router() {
  const auth = useAuth()

  if (auth.user === false) {
    return null
  }

  return (
    <Fragment>
      {
        auth.user ?
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="/signature/:id/:token" element={<SignatureWithToken />} />
            <Route path="/signature/:id/:token/view" element={<DisplaySignatureWithToken />} />
            <Route
              path="login"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route path="/dashboard">
              {(authenticationProvider.isContractOperator() || authenticationProvider.isContractValidator()) && <Route index element={<DashboardHome />} />}
              {authenticationProvider.isCompanySigner() && <Route index element={<CompanyDetail />} />}
              {authenticationProvider.isCompanySigner() && <Route path="my/*" element={<CompanyDetail />} />}
              <Route path="contract">
                <Route index element={<BrowseContract />} />
                <Route path="new" element={<CreateContract />} />
                <Route path=":id/*" element={<ContractEditor />} />
              </Route>
              <Route path="template">
                <Route index element={<BrowseTemplate />} />
              </Route>
              <Route path="company">
                <Route index element={<BrowseCompanyPublished />} />
                <Route path=":id/*" element={<DetailCompany />} />
                <Route path="non" element={<BrowseCompanyNonPublished />} />
                <Route path="draft" element={<BrowseDraftCompany />} />
                <Route path="draft/:id/*" element={<DetailDraftCompany />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes> :
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="/signature/:id/:token" element={<SignatureWithToken />} />
              <Route path="/signature/:id/:token/view" element={<DisplaySignatureWithToken />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="login" element={<Login />} />
              <Route path="join" element={<Join />} />
              <Route
                path="dashboard"
                element={<Navigate to="/login" replace />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
      }
      <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Toaster position="top-right">
            {(toaster) => (
              <Transition
                show={toaster.visible}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {({
                          error: <PlusCircleIcon className="h-6 w-6 text-rose-400 rotate-45" aria-hidden="true" />,
                          success: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
                        })[toaster.type]}

                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          {({
                            error: 'Gagal',
                            success: 'Berhasil',
                          })[toaster.type]}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">{toaster.message}</p>
                      </div>
                      <div className="ml-4 flex-shrink-0 flex">
                        <button
                          className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            toast.dismiss(toaster.id)
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            )}
          </Toaster>
        </div>
      </div>


    </Fragment>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}


export { useAuth }
export default App;
