
import { useQuery } from "react-query"
import { requestGet } from "Provider/RestClient"

export function useDataEmployee(ID, { expands, onFetched = () => null }) {
    const path = `/employee/employee/${ID}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onFetched })
}

export function useDataEmployees({ keyword, sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1 }) {
    const path = '/employee/employee'
    return useQuery(path, () => requestGet(path, { keyword, sort, order, pageSize, currentPage, expand: expands.join(',') }))
}
