import { useState } from "react"
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { RadioGroup } from '@headlessui/react'
import { useMutationLogin } from "Data/Account"
import { useAuth } from "../.."
import classNames from "classnames"
import { ReactComponent as Logo } from 'Asset/Logo/department-logo.svg'
import { authenticationProvider } from "Provider/Authentication"

const ROLES = [
  { id: 1, code: 'Company-Signer', name: 'DUDI' },
  { id: 2, code: 'Contract-Operator', name: 'Operator Kontrak' },
  { id: 3, code: 'Contract-Validator', name: 'Validator Kontrak' },
]

export default function Login() {
  const auth = useAuth()
  const [role, setRole] = useState(ROLES[0])
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const mutateLogin = useMutationLogin({
    onSuccess: ({ data: response }) => {
      if (response.code === 200) {
        auth.signIn(response.data.account, role.code, response.data.token)
      }
    }
  })

  const submit = (event) => {
    event.preventDefault()
    authenticationProvider.setRole(role.code, () => {
      mutateLogin.mutate({ id: email, password: password })
    })
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Logo
            className="mx-auto h-12 w-auto"
            alt="Mitras DUDI"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Masuk sebagai {role.name}</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={submit} className="space-y-6" action="#" method="POST">

              <div>
                <div className="flex items-center justify-between">
                  <h2 className="text-sm font-medium text-gray-900">Login Sebagai</h2>
                </div>

                <RadioGroup value={role} onChange={setRole} className="mt-2">
                  <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
                  <div className="grid grid-cols-3 gap-3 sm:grid-cols-3">
                    {ROLES.map((availableRole) => (
                      <RadioGroup.Option
                        key={availableRole.id}
                        value={availableRole}
                        className={({ active, checked }) =>
                          classNames(
                            active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                            checked
                              ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
                              : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                            'border rounded-md py-3 px-3 flex items-center text-center justify-center text-sm font-medium uppercase sm:flex-1'
                          )
                        }
                      >
                        <RadioGroup.Label as="p">{availableRole.name}</RadioGroup.Label>
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Surat Elektronik (Surel)
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Kata Sandi
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="relative">
                    <input id="password"
                      name="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      value={password}
                      onChange={({ target: { value } }) => setPassword(value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <div onMouseUp={() => setIsPasswordVisible(false)} onMouseDown={() => setIsPasswordVisible(true)} className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">

                      {
                        isPasswordVisible ?
                          <EyeOffIcon className="h-5 w-5" aria-hidden="true" /> :
                          <EyeIcon className="h-5 w-5" aria-hidden="true" />
                      }

                    </div>
                  </div>
                </div>

              </div>



              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <button className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </button>
                </div>
              </div>

              <div className="flex flex-row gap-2">
                <button
                  type="submit"
                  className={classNames(
                    "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                    mutateLogin.isLoading ? "bg-gray-200 hover:bg-gray-300 focus:ring-gray-100" : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                  )}
                >
                  {mutateLogin.isLoading ? 'Memproses' : 'Masuk'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
