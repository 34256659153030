import { createContext, useContext } from "react";
import { Route, Routes, useParams } from "react-router-dom"
import { useDataContract } from "Data/Contract"

import DetailContract from './DetailContract'
import UpdateContract from './UpdateContract';
import AssignCompany from "./AssignCompany";
import AssignClauseOnContract from './AssignClauseOnContract';
import AssignContractSignature from "./AssignContractSignature";
import AssignContractNumber from "./AssignContractNumber";
import NotFound from "../Static/NotFound";
import { transformResponseToSingle } from "../../Utility/transformer";
import ContractValidation from "./ContractValidation";

export const ContractContext = createContext()

export function ContractProvider({ children, contract, dataContract }) {
    return <ContractContext.Provider value={{ contract, dataContract }}>{children}</ContractContext.Provider>
}

export function useContractContext() {
    return useContext(ContractContext)
}

export default function ContractEditor() {
    const { id } = useParams()
    const dataContract = useDataContract(id, { expands: ['phaseClauseConstruction', 'phaseValidation', 'contractSigners', 'clauseGroups'] })
    const contract = transformResponseToSingle(dataContract.data)
    if (!contract) {
        return null
    }
    return (
        <ContractProvider contract={contract} dataContract={dataContract}>
            <Routes>
                <Route index element={<DetailContract />} />
                <Route path="update" element={<UpdateContract />} />
                <Route path="update/assign-company" element={<AssignCompany />} />
                <Route path="update/assign-clause" element={<AssignClauseOnContract />} />
                <Route path="update/validation" element={<ContractValidation />} />
                <Route path="update/assign-signature" element={<AssignContractSignature />} />
                <Route path="update/assign-number" element={<AssignContractNumber />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </ContractProvider>
    )
}