import ContractEditorLayout from "Layout/ContractEditorLayout"
import { useContractContext } from "../ContractEditor"
import { CheckCircleIcon, CodeIcon, PlusCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import classNames from "classnames"
import { useState } from "react"
import { authenticationProvider } from "Provider/Authentication"
import ModalPostValidation from "./ModalPostValidation"

export default function ContractValidation() {
    const { dataContract, contract } = useContractContext()
    const [selectedValidationBy, setSelectedValidationBy] = useState(null)


    return (
        <ContractEditorLayout contract={contract} appendBreadcrumbs={[{ name: 'Buat Naskah Baru', 'to': '/dashboard/contract/new' }]} className="">
            <div className="flex flex-col">
                <div className="text-base font-medium text-gray-900">
                    Validasi
                </div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-3">
                    <Validation
                        onClick={() => !authenticationProvider.isContractValidator() ? null : setSelectedValidationBy(contract.phaseValidation.validationBy.coordinatorLAP)}
                        validationBy={contract.phaseValidation.validationBy.coordinatorLAP}
                    />
                    <Validation
                        onClick={() => !authenticationProvider.isContractValidator() ? null : setSelectedValidationBy(contract.phaseValidation.validationBy.coordinatorHRAR)}
                        validationBy={contract.phaseValidation.validationBy.coordinatorHRAR}
                    />
                    <Validation
                        onClick={() => !authenticationProvider.isContractValidator() ? null : setSelectedValidationBy(contract.phaseValidation.validationBy.directorateGeneral)}
                        validationBy={contract.phaseValidation.validationBy.directorateGeneral}
                    />
                    <Validation
                        onClick={() => !authenticationProvider.isContractValidator() ? null : setSelectedValidationBy(contract.phaseValidation.validationBy.secretary)}
                        validationBy={contract.phaseValidation.validationBy.secretary}
                    />
                </div>
            </div>
            <ModalPostValidation
                validationBy={selectedValidationBy}
                onClose={() => setSelectedValidationBy(null)}
                onSuccess={() => setSelectedValidationBy(null) || dataContract.refetch()}
            />
        </ContractEditorLayout>
    )
}

const Validation = ({ validationBy, onClick }) => {
    return (
        <div onClick={onClick} className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-4">
            <div className={classNames(
                validationBy.validation?.type === 'Approve' ? 'ring-2 ring-indigo-500' : '',
                validationBy.validation?.type === 'Reject' ? 'ring-2 ring-rose-500' : '',
                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
            )}>
                <div className="flex-1 flex">
                    <div className="flex flex-col">
                        <span className="block text-sm font-medium text-gray-900">
                            {validationBy.name}
                        </span>
                        <span className="mt-6 text-sm font-light text-gray-900">
                            {validationBy.validation?.note && (
                                <span>Cataan terakhir: "{validationBy.validation?.note}"</span>
                            )}
                        </span>
                    </div>
                </div>
                <CheckCircleIcon
                    className={classNames(validationBy.validation?.type !== 'Approve' ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                    aria-hidden="true"
                />
                <PlusCircleIcon
                    className={classNames(validationBy.validation?.type !== 'Reject' ? 'invisible' : '', 'h-5 w-5 text-rose-600 rotate-45')}
                    aria-hidden="true"
                />
                <div
                    className={classNames(
                        validationBy.approval ? 'border-indigo-500' : 'border-transparent',
                        'absolute -inset-px rounded-lg pointer-events-none'
                    )}
                    aria-hidden="true"
                />
            </div>
        </div>
    )
}