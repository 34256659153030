import { HomeIcon } from "@heroicons/react/outline";
import { Link, NavLink } from "react-router-dom";
import DashboardLayout from "../DashboardLayout";

export default function ContractLayout({ children, appendBreadcrumbs = [], contextHeader, className = null }) {

    const breadcrumbs = [
        { name: 'Kelola', to: '/dashboard/contract' },
        ...appendBreadcrumbs
    ]

    return (
        <DashboardLayout>
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 flex">
                <div className="min-w-0 flex flex-row">
                    <div className="mr-10">
                        <h1 className="text-lg font-medium leading-6 antialiased text-gray-900 sm:truncate">
                            Manajemen Naskah
                        </h1>
                    </div>

                    <nav className="flex" aria-label="Breadcrumb">
                        <ol className="flex items-center space-x-4">
                            <li>
                                <div>
                                    <Link to="/" className="text-gray-400 hover:text-gray-500">
                                        <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                        <span className="sr-only">Home</span>
                                    </Link>
                                </div>
                            </li>
                            {breadcrumbs.map((breadcrumb) => (
                                <li key={breadcrumb.name}>
                                    <div className="flex items-center">
                                        <svg
                                            className="flex-shrink-0 h-5 w-5 text-gray-300"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                        >
                                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                                        </svg>
                                        <NavLink
                                            to={breadcrumb.to}
                                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                        >
                                            {breadcrumb.name}
                                        </NavLink>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </nav>
                </div>
                <div className="mt-4 flex sm:mt-0 sm:ml-4 pl-10">
                    <div>
                        {contextHeader}
                    </div>
                </div>
            </div>
            <div className={className}>
                {children}
            </div>
        </DashboardLayout>
    )
}