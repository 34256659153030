import { Link, NavLink } from 'react-router-dom'
import { useAuth } from 'App'
import PortalLayout from 'Layout/PortalLayout'
import Pattern from 'Asset/Content/Home/632923be6b256531325d837e_flower.svg'
import classNames from 'classnames'
import ContactForm from './ContactForm'

export default function ContactUs() {
    const { user, signOut } = useAuth()

    return (
        <PortalLayout navBarTextClassName={{ invert: 'text-indigo-800', base: 'text-gray-600' }}>
            <div className='px-2 md:px-32 pt-10'>
                <ContactForm />
            </div>
        </PortalLayout>
    )
}