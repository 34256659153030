import { Fragment, useState } from "react";
import {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
} from '@heroicons/react/solid'
import { Popover, Transition } from '@headlessui/react'
import { useDataCompanySigners } from "Data/CompanySigner";
import { transformResponseToList } from "../../../Utility/transformer";
import { useCompanyContext } from "../DetailCompany";
import ModalCreateOfficer from "./ModalCreateOfficer";
import { ChevronDownIcon, PencilAltIcon, PlusSmIcon, ShieldExclamationIcon, TrashIcon } from "@heroicons/react/outline";
import ModalUpdateOfficer from "./ModalUpdateOfficer";
import ModalDeleteOfficer from "./ModalDeleteOfficer";
import classNames from "classnames";

export default function DetailCompanyBrowseOfficer() {
    const { company } = useCompanyContext()
    const dataCompanySigners = useDataCompanySigners({ companyID: company.ID })
    const companySigners = transformResponseToList(dataCompanySigners.data)
    const [selectedCompanySigner, setSelectedCompanySigner] = useState(null)
    const [managementState, setManagementState] = useState(null)
    const [deleteType, setDeleteType] = useState(null)
    return (
        <Fragment>
            <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
                {companySigners.map(companySigner => (
                    <li key={companySigner.ID}>
                        <span className="group block">
                            <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="flex-shrink-0">
                                        <div className="h-12 w-12 bg-gray-500 rounded-full group-hover:opacity-75">

                                        </div>
                                    </div>
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                            <p className="text-sm font-medium text-purple-600 truncate">{companySigner.officerName}</p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span className="truncate">{companySigner.officerEmail}</span>
                                            </p>
                                        </div>
                                        <div className="hidden md:block">
                                            <div>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <CheckCircleIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                        aria-hidden="true"
                                                    />
                                                    Aktif
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2 items-center">
                                    <button type="button" onClick={() => setManagementState('Update') || setSelectedCompanySigner(companySigner)} className="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <PencilAltIcon className="h-4 w-4" aria-hidden="true" />
                                    </button>

                                    {companySigner.deletable && (
                                        <button type="button" onClick={() => setManagementState('Delete') || setDeleteType('full') || setSelectedCompanySigner(companySigner)} className={classNames("ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 ", companySigner.deletable ? "bg-rose-600 hover:bg-rose-700 focus:ring-rose-500" : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500")}>
                                            <TrashIcon className="h-4 w-4" aria-hidden="true" />
                                        </button>
                                    )}
                                    {!companySigner.deletable && (
                                        <div className="">
                                            <Popover className="relative">
                                                {({ open }) => (
                                                    <>
                                                        <Popover.Button type="button" className={classNames("ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 ", companySigner.deletable ? "bg-rose-600 hover:bg-rose-700 focus:ring-rose-500" : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500")}>
                                                            <TrashIcon className="h-4 w-4" aria-hidden="true" />
                                                        </Popover.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-200"
                                                            enterFrom="opacity-0 translate-y-1"
                                                            enterTo="opacity-100 translate-y-0"
                                                            leave="transition ease-in duration-150"
                                                            leaveFrom="opacity-100 translate-y-0"
                                                            leaveTo="opacity-0 translate-y-1"
                                                        >
                                                            <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                                    <div className="relative gap-1  w-screen max-w-sm  bg-white p-4 flex flex-row text-gray-500">
                                                                        <div className="mr-1">
                                                                            <ShieldExclamationIcon className="w-5 h-5" />
                                                                        </div>
                                                                        <span className="text-xs ">
                                                                            Tidak dapat dihapus karena ada naskah yang menggunakan akses ini. untuk dapat menghapus secara permanen bisa menghubungi administrator
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </Popover.Panel>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Popover>
                                        </div>
                                    )}

                                    <ChevronRightIcon
                                        className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </span>
                    </li>
                ))}
            </ul>
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <button
                        onClick={() => setManagementState('Create')}
                        type="button"
                        className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span>Tambah Penanggung Jawab</span>
                    </button>
                </div>
            </div>
            <ModalCreateOfficer
                show={managementState === 'Create'}
                company={company}
                onSuccess={() => setManagementState(null) || dataCompanySigners.refetch()}
                onClose={() => setManagementState(null)}
            />
            <ModalUpdateOfficer
                companySigner={managementState === 'Update' ? selectedCompanySigner : null}
                onSuccess={() => setSelectedCompanySigner(null) || dataCompanySigners.refetch()}
                onClose={() => setSelectedCompanySigner(null)}
            />
            <ModalDeleteOfficer
                companySigner={managementState === 'Delete' ? selectedCompanySigner : null}
                type={deleteType}
                onSuccess={() => setSelectedCompanySigner(null) || setDeleteType(null) || dataCompanySigners.refetch()}
                onClose={() => setSelectedCompanySigner(null) || setDeleteType(null)}
            />
        </Fragment>
    )
}