import { BadgeCheckIcon, CheckCircleIcon, CheckIcon, FolderOpenIcon, PaperAirplaneIcon, PlusSmIcon, RefreshIcon, SaveAsIcon, SaveIcon, SpeakerphoneIcon, TrashIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import { Fragment, useRef, useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { useParams } from "react-router-dom"
import { useAssignContractClauseGroups } from "Data/Contract"
import { diff } from 'deep-object-diff';
import { useContractContext } from "../ContractEditor"
import { sanitizeClauseGroups, transformClauseGroupsRaw, transformDraftEditorStateToHTML, transformResponseToSingle } from "../../../Utility/transformer"
import { Editor } from "react-draft-wysiwyg";
import { authenticationProvider } from "Provider/Authentication"
import { MailIcon, PlusCircleIcon, PlusIcon } from "@heroicons/react/solid"
import ContractEditorLayout from "Layout/ContractEditorLayout"
import ModalContractSignerReview from "./ModalContractSignerReview"
import ModalOpenTemplate from "./ModalOpenTemplate"
import ModalSaveTemplate from "./ModalSaveTemplate"
import parseHTML from 'html-react-parser';
import toast from 'react-hot-toast';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const defaultReviewAction = { assignOrder: null, type: null }
export default function AssignClauseOnContract() {
    const { id } = useParams()
    const { dataContract, contract } = useContractContext()
    const second = contract.contractSigners.find(contractSigner => contractSigner.order == 2)
    const isDone = contract.phaseClauseConstruction.isDone
    const isSentToA = contract.phaseClauseConstruction.lastReview[0].review?.type === 'Send'
    const isApprovedBySideA = contract.phaseClauseConstruction.lastReview[0].review?.type === 'Approve'
    const isRejectedBySideA = contract.phaseClauseConstruction.lastReview[0].review?.type === 'Reject'
    const isRejectedBySideB = contract.phaseClauseConstruction.lastReview[1].review?.type === 'Reject'
    const isEditable = !isDone && !isApprovedBySideA && ((authenticationProvider.isCompanySigner() && isSentToA) || (authenticationProvider.isContractOperator() && !isSentToA))
    const isAddableAndSubtractable = !isDone && !isSentToA && !isApprovedBySideA && !authenticationProvider.isCompanySigner()

    const assignContractClauseGroups = useAssignContractClauseGroups({
        onSuccess: () => {
            toast('Tersimpan', { icon: <CheckCircleIcon className="w-5 h-5 text-green-500" /> })
            dataContract.refetch().then((response) => {
                const newClauseGroups = transformResponseToSingle(response.data).clauseGroups
                setClauseGroups(transformClauseGroupsRaw(newClauseGroups))
            })
        }
    })
    const [clauseGroups, setClauseGroups] = useState(transformClauseGroupsRaw(contract.clauseGroups))
    const [newClauseGroupTitle, setNewClauseGroupTitle] = useState('')
    const [reviewAction, setReviewAction] = useState(defaultReviewAction)
    const [openTemplate, setOpenTemplate] = useState(false)
    const [saveClauseGroups, setSaveClauseGroups] = useState(null)
    const diffCount = Object.keys(diff(sanitizeClauseGroups(contract.clauseGroups), sanitizeClauseGroups(clauseGroups))).length

    const changeClauseGroupTitle = (index, value) => {
        const edited = [...clauseGroups]
        edited[index] = { ...edited[index], title: value }
        setClauseGroups(edited)
    }

    const changeClausalTitle = (groupIndex, clausalIndex, editorState) => {
        const editedGroup = [...clauseGroups]
        const editedClausal = [...editedGroup[groupIndex].clausal]

        editedClausal[clausalIndex] = { ...editedClausal[clausalIndex], editorState: editorState, content: transformDraftEditorStateToHTML(editorState) }
        editedGroup[groupIndex] = { ...editedGroup[groupIndex], clausal: editedClausal }
        setClauseGroups(editedGroup)
    }

    const removeClauseGroup = (index) => {
        const edited = [...clauseGroups]
        edited.splice(index, 1)
        setClauseGroups(edited)
    }

    const removeClausal = (groupIndex, clausalIndex) => {
        const editedGroup = [...clauseGroups]
        const editedClausal = [...editedGroup[groupIndex].clausal]
        editedClausal.splice(clausalIndex, 1)
        editedGroup[groupIndex] = { ...editedGroup[groupIndex], clausal: editedClausal }
        setClauseGroups(editedGroup)
    }

    const appendClauseGroup = (clauseGroup) => {
        const edited = [...clauseGroups]
        edited.push(clauseGroup)
        setClauseGroups(edited)

        setNewClauseGroupTitle('')
    }

    const appendClausal = (groupIndex, clausal) => {
        const editedGroup = [...clauseGroups]
        const editedClausal = [...editedGroup[groupIndex].clausal]
        editedClausal.push(clausal)
        editedGroup[groupIndex] = { ...editedGroup[groupIndex], clausal: editedClausal }
        setClauseGroups(editedGroup)
    }

    const submit = (event) => {
        event.preventDefault()
        assignContractClauseGroups.mutate({
            ID: id,
            clauseGroups: JSON.stringify(clauseGroups, null, 2)
        })
    }

    useHotkeys('ctrl+s', async (event, handler) => {
        await submit({ preventDefault: () => null })
        event.preventDefault()
    }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'], enableOnContentEditable: true, filterPreventDefault: false })

    const ActionMenus = () => {
        return (
            <div className="space-x-2">
                {!isDone && isApprovedBySideA && !authenticationProvider.isCompanySigner() && (
                    <button
                        type="button"
                        onClick={() => setReviewAction({ assignOrder: 2, type: 'Approve' })}
                        disabled={isSentToA}
                        className={classNames(
                            "inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                            isSentToA ? "bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-500" : "bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                        )}
                    >

                        <BadgeCheckIcon className={classNames("h-6 w-6 mr-2 rounded-md p-1 text-white", isSentToA ? "bg-gray-600" : "bg-indigo-600")} />
                        Persetujuan Terakhir
                    </button>
                )}
                {authenticationProvider.isContractOperator() && (
                    <button
                        type="button"
                        onClick={() => setReviewAction({ assignOrder: 2, type: 'Update' })}
                        className={classNames(
                            "inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                            (false) ? "bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-500" : "bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                        )}
                    >

                        <SpeakerphoneIcon className={classNames("h-6 w-6 mr-2 rounded-md p-1 text-white", (false) ? "bg-gray-600" : "bg-indigo-600")} />
                        Update
                    </button>
                )}
                {authenticationProvider.isContractOperator() && (
                    <button
                        type="button"
                        onClick={() => setSaveClauseGroups(JSON.stringify(clauseGroups, null, 2))}
                        className={classNames(
                            "inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                            (false) ? "bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-500" : "bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                        )}
                    >

                        <SaveAsIcon className={classNames("h-6 w-6 mr-2 rounded-md p-1 text-white", (false) ? "bg-gray-600" : "bg-indigo-600")} />
                        Simpan Template
                    </button>
                )}
                {!isDone && isSentToA && authenticationProvider.isCompanySigner() && (
                    <button
                        type="button"
                        onClick={() => setReviewAction({ assignOrder: 1, type: 'Approve' })}
                        disabled={isApprovedBySideA || isRejectedBySideA}
                        className={classNames(
                            "inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                            (isApprovedBySideA || isRejectedBySideA) ? "bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-500" : "bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                        )}
                    >

                        <BadgeCheckIcon className={classNames("h-6 w-6 mr-2 rounded-md p-1 text-white", (isApprovedBySideA || isRejectedBySideA) ? "bg-gray-600" : "bg-indigo-600")} />
                        Persetujuan
                    </button>
                )}

                {!isApprovedBySideA && (
                    <Fragment>
                        {!authenticationProvider.isCompanySigner() && (
                            <button
                                type="button"
                                onClick={() => setReviewAction({ assignOrder: 1, type: 'Send' })}
                                disabled={isSentToA}
                                className={classNames(
                                    "inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                                    isSentToA ? "bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-500" : "bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                                )}
                            >

                                <PaperAirplaneIcon className={classNames("h-6 w-6 mr-2 rounded-md p-1 text-white", isSentToA ? "bg-gray-600" : "bg-indigo-600")} />
                                {isSentToA ? 'Sudah Terkirim' : 'Kirim ke Pihak-1'}
                            </button>
                        )}
                        {isEditable && (
                            <button
                                type="submit"
                                disabled={assignContractClauseGroups.isLoading}
                                className={classNames(
                                    "inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                                    (assignContractClauseGroups.isLoading) ? "bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-500" : (
                                        diffCount > 0 ?
                                            "bg-yellow-100 hover:bg-yellow-200 text-yellow-700 focus:ring-yellow-500" :
                                            "bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                                    )

                                )}
                            >
                                {assignContractClauseGroups.isLoading && (<RefreshIcon className="h-6 w-6 mr-2 rounded-full bg-gray-600 p-1 text-white animate-spin" />)}
                                {!assignContractClauseGroups.isLoading && <SaveIcon className={classNames("h-6 w-6 mr-2 rounded-md p-1 text-white", "bg-indigo-600")} />}
                                {assignContractClauseGroups.isLoading ? 'Memproses' : (diffCount > 0 ? `Simpan ${diffCount} perubahan` : 'Simpan')}
                            </button>
                        )}
                    </Fragment>
                )}
            </div>
        )
    }

    return (
        <ContractEditorLayout contract={contract} appendBreadcrumbs={[{ name: 'Buat Naskah Baru', 'to': '/dashboard/contract/new' }]} className="">
            <form onSubmit={submit}>
                {isApprovedBySideA && (
                    <div className="mb-5">
                        <div className="flex flex-row items-center gap-2">
                            <BadgeCheckIcon className="h-7 w-7 text-green-600" /> Disetujui Pihak-1
                            {isDone && (
                                <Fragment>
                                    <BadgeCheckIcon className="h-7 w-7 text-green-600" /> Disetujui Pihak-2
                                </Fragment>
                            )}
                            {isRejectedBySideB && (
                                <Fragment>
                                    <PlusCircleIcon className="h-7 w-7 rotate-45 text-rose-600" /> Ditolak Pihak-2
                                </Fragment>
                            )}
                        </div>
                    </div>
                )}
                {isRejectedBySideA && (
                    <div className="mb-5">
                        <div className="flex flex-row items-center gap-2">
                            <PlusCircleIcon className="h-7 w-7 rotate-45 text-rose-600" /> Ditolak Pihak-1
                        </div>
                    </div>
                )}
                <div className="mb-10 flex justify-end gap-2 border-b border-indigo-200">
                    {
                        !isApprovedBySideA && !authenticationProvider.isCompanySigner() && (
                            <button
                                type="button"
                                onClick={() => setOpenTemplate(true)}
                                disabled={assignContractClauseGroups.isLoading}
                                className={classNames(
                                    "inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                                    (isSentToA || assignContractClauseGroups.isLoading) ? "bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-500" : (
                                        diffCount > 0 ?
                                            "bg-yellow-100 hover:bg-yellow-200 text-yellow-700 focus:ring-yellow-500" :
                                            "bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                                    )

                                )}
                            >
                                <FolderOpenIcon className={classNames("h-6 w-6 mr-2 rounded-md p-1 text-white", isSentToA ? "bg-gray-600" : "bg-indigo-600")} />
                                Gunakan Template Pasal yang Ada
                            </button>
                        )
                    }
                    <ActionMenus />
                </div>


                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    {
                        clauseGroups.length === 0 && authenticationProvider.isCompanySigner() && (
                            <div className="text-center py-52">
                                <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        vectorEffect="non-scaling-stroke"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                    />
                                </svg>
                                <h3 className="mt-2 text-sm font-medium text-gray-900">Belum ada Pasal yang dibuat</h3>
                                <p className="mt-1 text-sm text-gray-500">Anda dapat menunggu atau dapat menghubungi tim agar dapat diproses lebih cepat.</p>

                                {second && (
                                    <div className="mt-6">
                                        <a
                                            href={`mailto:${second.companySigner.officerEmail}`}
                                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Hubungi
                                        </a>
                                    </div>
                                )}


                            </div>
                        )
                    }
                    <ul role="list" className="divide-y divide-gray-200">
                        {clauseGroups.map((clauseGroup, clauseGroupIndex) => (
                            <li key={clauseGroupIndex}>
                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div key={clauseGroupIndex} className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 shadow-sm flex w-full flex-col">
                                        <div className="-ml-4 -mt-2 flex">
                                            <div className="ml-4 mt-2 flex gap-2 items-center w-full">
                                                <h3 className="text-lg h-10 w-10 rounded-full bg-indigo-600 justify-center items-center flex text-white">
                                                    {clauseGroupIndex + 1}.
                                                </h3>
                                                <input
                                                    type="text"
                                                    name={`clauseGroups[${clauseGroupIndex}][name]`}
                                                    id={`clauseGroups[${clauseGroupIndex}][name]`}
                                                    disabled={!isEditable}
                                                    className={classNames("w-full border-0 border-b border-transparent focus:ring-0 sm:text-sm md:text-xl", !isEditable ? "" : "focus:border-indigo-600")}
                                                    placeholder="Masukan judul pasal"
                                                    onChange={({ target: { value } }) => changeClauseGroupTitle(clauseGroupIndex, value)}
                                                    value={clauseGroup.title}
                                                />
                                            </div>
                                            <div className="ml-4 mt-2 flex-shrink-0">
                                                {isAddableAndSubtractable && (
                                                    <button
                                                        onClick={() => removeClauseGroup(clauseGroupIndex)}
                                                        type="button"
                                                        className=" relative hover:animate-bounce inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600  hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                                                    >
                                                        <TrashIcon className="w-4 h-4 " />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="pl-10">
                                            {clauseGroup.clausal.map((clausal, clausalIndex) => (
                                                <div key={clausalIndex} className="flex items-start space-x-4 my-10">
                                                    <div className="flex-shrink-0">
                                                        <span className="h-10 w-10 rounded-full bg-indigo-600 justify-center items-center flex text-xs text-white">
                                                            {clauseGroupIndex + 1}.{clausalIndex + 1}
                                                        </span>
                                                    </div>
                                                    <div className="min-w-0 flex-1">
                                                        <div className="relative">
                                                            <div className="border border-white rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                                                                <label htmlFor="comment" className="sr-only">
                                                                    Tambahkan pasal anda
                                                                </label>
                                                                {isEditable && (
                                                                    <Editor
                                                                        editorState={clausal.editorState}
                                                                        toolbarClassName="toolbarClassName"
                                                                        wrapperClassName="wrapperClassName"
                                                                        editorClassName="px-3"
                                                                        placeholder="Tambahkan pasal di sini"
                                                                        onEditorStateChange={(editorState) => changeClausalTitle(clauseGroupIndex, clausalIndex, editorState)}
                                                                    />
                                                                )}
                                                                {!isEditable && (
                                                                    parseHTML(clausal.content)
                                                                )}


                                                                {/* Spacer element to match the height of the toolbar */}
                                                                <div className="py-2" aria-hidden="true">
                                                                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                                                    <div className="py-px">
                                                                        <div className="h-9" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                                                                <div className="flex items-center space-x-5">

                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    {isAddableAndSubtractable && (
                                                                        <button
                                                                            onClick={() => removeClausal(clauseGroupIndex, clausalIndex)}
                                                                            type="button"
                                                                            className=" relative hover:animate-bounce inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600  hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                                                                        >
                                                                            <TrashIcon className="w-4 h-4 " />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            {isAddableAndSubtractable && (
                                                <NewClausalForm
                                                    clauseGroup={clauseGroup}
                                                    clauseGroupIndex={clauseGroupIndex}
                                                    clausalIndex={clauseGroup.clausal.length}
                                                    onConfirmInsertClausal={appendClausal}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </li>
                        ))}

                        {isAddableAndSubtractable && (
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 shadow-sm flex w-full flex-col">
                                    <div className="-ml-4 -mt-2 flex">
                                        <div className="ml-4 mt-2 flex gap-2 items-center w-full">
                                            <h3 className="text-lg h-10 w-10 rounded-full bg-gray-600 justify-center items-center flex text-white">
                                                {clauseGroups.length + 1}.
                                            </h3>
                                            <input
                                                type="text"
                                                value={newClauseGroupTitle}
                                                onChange={({ target: { value } }) => setNewClauseGroupTitle(value)}
                                                className="w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm md:text-xl"
                                                placeholder="Masukan judul pasal"
                                            />
                                        </div>
                                        <div className="ml-4 mt-2 flex-shrink-0">
                                            <button
                                                onClick={() => appendClauseGroup({ title: newClauseGroupTitle, clausal: [], contractID: contract.ID, ID: null })}
                                                type="button"
                                                className=" relative hover:animate-bounce inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600  hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                            >
                                                <PlusIcon className="w-4 h-4 " />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="pl-10">
                                    </div>
                                </div>
                            </div>
                        )}
                    </ul>
                </div>
                <div className="mt-10 flex justify-end gap-2">
                    <ActionMenus />
                </div>
            </form>
            <ModalContractSignerReview
                reviewAction={reviewAction}
                onSuccess={() => setReviewAction(defaultReviewAction) || dataContract.refetch()}
                onClose={() => setReviewAction(defaultReviewAction)}
            />
            <ModalOpenTemplate
                show={openTemplate}
                onSuccess={(template) => setOpenTemplate(false) || setClauseGroups(transformClauseGroupsRaw(template.clauseGroups))}
                onClose={() => setOpenTemplate(false)}
            />
            <ModalSaveTemplate
                saveClauseGroups={saveClauseGroups}
                onSuccess={(template) => setSaveClauseGroups(null)}
                onClose={() => setSaveClauseGroups(null)}
            />
        </ContractEditorLayout>
    )
}

const NewClausalForm = ({ clauseGroupIndex, clausalIndex, onConfirmInsertClausal, clauseGroup }) => {
    const [editorState, setEditorState] = useState(null)
    const ref = useRef()

    useHotkeys('shift+enter', async (event, handler) => {
        if (ref?.current.state.editorFocused) {
            onConfirmInsertClausal(clauseGroupIndex, { ID: null, content: transformDraftEditorStateToHTML(editorState), editorState, clauseGroupID: clauseGroup.ID })
        }
        event.preventDefault()
    }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'], enableOnContentEditable: true, filterPreventDefault: false })

    return (
        <div className="flex items-start space-x-4 my-10">
            <div className="flex-shrink-0">
                <span className="h-10 w-10 rounded-full bg-gray-600 justify-center items-center flex text-xs text-white">
                    {clauseGroupIndex + 1}.{clausalIndex + 1}
                </span>
            </div>
            <div className="min-w-0 flex-1">
                <div className="relative">
                    <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-gray-500 focus-within:ring-1 focus-within:ring-gray-500">
                        <label htmlFor="comment" className="sr-only">
                            Add your comment
                        </label>

                        <Editor
                            ref={ref}
                            initialContentState=''
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="px-3"
                            placeholder="Tambahkan pasal di sini"
                            onEditorStateChange={(newEditorState) => {
                                setEditorState(newEditorState)
                            }}
                        />

                        {/* Spacer element to match the height of the toolbar */}
                        <div className="py-2" aria-hidden="true">
                            {/* Matches height of button in toolbar (1px border + 36px content height) */}
                            <div className="py-px">
                                <div className="h-9" />
                            </div>
                        </div>
                    </div>

                    <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                        <div className="flex items-center space-x-5">

                        </div>
                        <div className="flex-shrink-0">
                            <button
                                onClick={() => {
                                    onConfirmInsertClausal(clauseGroupIndex, { ID: null, content: transformDraftEditorStateToHTML(editorState), editorState, clauseGroupID: clauseGroup.ID })
                                    setEditorState(null)
                                }}
                                type="button"
                                className=" relative hover:animate-bounce inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600  hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                            >
                                <PlusSmIcon className="w-4 h-4 " />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}