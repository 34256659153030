import { useQuery, useMutation } from "react-query"
import { requestGet, requestPost } from "Provider/RestClient"

export function useDataContractSigner(ID, token, { expands = [], onFetched = () => null }) {
    const path = `/contract/contract-signer/${ID}/${token}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onFetched })
}

export function useAssignSignatureContractSigner({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, token, signingImage, useExistingSignature }) => requestPost(`/contract/contract-signer/${ID}/${token}`, { signingImage, useExistingSignature }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}