import { Fragment, useMemo, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BadgeCheckIcon, ClipboardCopyIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { usePublishDraftJoin } from 'Data/DraftJoin'
import classNames from 'classnames'
import { transformResponseToSingle } from '../../../Utility/transformer'
import moment from 'moment'

const MAX_SIGNATURE_IMAGE_WIDTH = 424
const MAX_SIGNATURE_IMAGE_HEIGHT = 201;

export default function PublishDraftJoin({ draftJoin, onInvited, onClose }) {
    const cancelButtonRef = useRef(null)
    const [address, setAddress] = useState('')
    const [password, setPassword] = useState('')
    const [about, setAbout] = useState('')
    const [logo, setLogo] = useState(null)
    const publishDraftJoin = usePublishDraftJoin({ onSuccess: (response) => onInvited(transformResponseToSingle(response)) })

    const submit = () => {
        publishDraftJoin.mutate({
            ID: draftJoin.ID,
            logo, address, password,about
        })
    }

    const preview = useMemo(() => logo ? URL.createObjectURL(logo) : null, [logo])

    return (
        <Transition.Root show={draftJoin !== null} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <BadgeCheckIcon className="h-6 w-6 text-cyan-500" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Menyetujui
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Silakan isian di bawah ini terlebih dahulu untuk dapat menyetujui DUDI ini. Dengan menyetujui ini maka,
                                            Akan dibuatkan akses untuk PIC, DUDI akan diverifikasi sehingga dapat dilibatkan dalam proses pembuatan naskah
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='py-5 px-3'>
                                {draftJoin && (
                                    <Fragment>
                                        <DataValue label="Nama DUDI" value={draftJoin.companyName} />
                                        <DataValue label="Akses Email" value={draftJoin.officerEmail} />
                                        <DataValue label="Telepon PIC" value={draftJoin.officerPhone} />
                                    </Fragment>
                                )}
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            Alamat
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={'Silakan isi alamat perusahaan'}
                                            value={address}
                                            onChange={({ target: { value } }) => setAddress(value)}
                                        />
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={'Masukan password'}
                                            value={password}
                                            onChange={({ target: { value } }) => setPassword(value)}
                                        />
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            Tentang Perusahaan
                                        </label>
                                        <textarea
                                            rows={3}
                                            value={about}
                                            onChange={({ target: { value } }) => setAbout(value)}
                                            className="w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                                            placeholder="Tambahkan pasal di sini"
                                        />
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div onDragOver={(event) => event.preventDefault()}
                                        onDrop={(event) => {
                                            let fileInput = document.getElementById(`logo-upload`);

                                            // pretty simple -- but not for IE :(
                                            fileInput.files = event.dataTransfer.files;

                                            const dT = new DataTransfer();
                                            dT.items.add(event.dataTransfer.files[0]);
                                            fileInput.files = dT.files;

                                            setLogo(event.dataTransfer.files[0]);
                                            event.preventDefault();
                                        }}
                                        className="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input id="logo-upload" name="file-upload" type="file" className="sr-only" />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>

                                    <div className='flex justify-center items-center mt-10'>
                                        <img
                                            src={preview}
                                            style={{ resizeMode: 'contain' }}
                                            className="inline-block h-40 w-40 rounded-full ring-2"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    <button
                                        type="button"
                                        disabled={publishDraftJoin.isLoading}
                                        className={classNames(
                                            "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:w-auto sm:text-sm",
                                            publishDraftJoin.isLoading ? "bg-gray-600 text-white hover:bg-gray-700" : "bg-cyan-600 text-white hover:bg-cyan-700"
                                        )}
                                        onClick={submit}
                                    >
                                        {publishDraftJoin.isLoading && <RefreshIcon className='h-4 w-4 animate-spin text-white' />}
                                        {publishDraftJoin.isLoading ? 'Memproses ...' : 'Menyetujui'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


const DataValue = ({ label, value }) => (
    <div className='py-4'>
        <div className="relative border border-green-500  rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
            <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                {label}
            </label>
            <input
                type="text"
                disabled={true}
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                placeholder={'Silakan isi alamat perusahaan'}
                value={value}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
            </div>
        </div>
    </div>
)