
import { useQuery, useMutation } from "react-query"
import { requestGet, requestPost } from "Provider/RestClient"


export function useDataTemplateClauseDocuments({ sort = 'created', order = 'desc', expands = ['clauseGroups'], pageSize = 20, currentPage = 1 }) {
    const path = '/contract/clause/template'
    return useQuery(path, () => requestGet(path, { sort, order, pageSize, currentPage, expand: expands.join(',') }))
}

export function useSaveTemplate({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ clauseGroups, templateID, templateName }) => requestPost('/contract/clause/template', { clauseGroups, templateID, templateName }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}
