import { createContext, useContext, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom"

import NotFound from "../Static/NotFound";
import { transformResponseToSingle } from "../../Utility/transformer";
import { useDataCompany } from "Data/Company";
import DashboardHomeForCompany from "../Dashboard/DashboardHomeForCompany";
import { useAuth } from "..";
import DetailCompanyBrowseOfficer from "./DetailCompanyBrowseOfficer";

export const CompanyContext = createContext()

export function CompanyProvider({ children, company, dataCompany }) {
    return <CompanyContext.Provider value={{ company, dataCompany }}>{children}</CompanyContext.Provider>
}

export function useCompanyContext() {
    return useContext(CompanyContext)
}

export default function CompanyDetail() {
    const auth = useAuth()
    const companyID = auth ? auth.user.companySignerAccess.company.ID : null
    const dataCompany = useDataCompany(companyID, { expands: [], enabled: false })
    const company = transformResponseToSingle(dataCompany.data)
    useEffect(() => {
        if (companyID) {
            dataCompany.refetch()
        }
    }, [companyID])

    if (!company) return null
    return (
        <CompanyProvider company={company} dataCompany={dataCompany}>
            <Routes>
                <Route path="*" element={<DashboardHomeForCompany />} />
            </Routes>
        </CompanyProvider>
    )
}