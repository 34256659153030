import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { HomeIcon as HomeIconOutline } from "@heroicons/react/outline";
import { HomeIcon as HomeIconSolid } from "@heroicons/react/solid";
import ToggleIcon from "./ToggleIcon";
import classNames from "classnames";

export default function SidebarMenu({ to, icons = [], name, end }) {
    return (
        <NavLink to={to} end={end} className={({ isActive }) => classNames(
            isActive ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
        )}>
            {
                ({ isActive }) => (
                    <Fragment>
                        {
                            icons !== false &&
                            <ToggleIcon
                                activeIcon={icons.length >= 2 ? icons[1] : HomeIconSolid}
                                inactiveIcon={icons.length >= 1 ? icons[0] : HomeIconOutline}
                                isActive={isActive}
                                className={classNames(
                                    isActive ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden="true"
                            />
                        }

                        <span className='flex items-center justify-center'>
                            {name}
                        </span>
                    </Fragment>
                )
            }
        </NavLink>
    )
}