import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon, ClipboardCopyIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { InviteType, useInviteDraftJoin } from 'Data/DraftJoin'
import classNames from 'classnames'
import { useDataEmployees } from 'Data/Employee'
import { transformResponseToList, transformResponseToSingle } from '../../../Utility/transformer'

export default function InviteDraftJoin({ draftJoin, onInvited, onClose }) {
    const cancelButtonRef = useRef(null)
    const [keywordEmployee, setKeywordEmployee] = useState('')
    const [selectedInviteType, setSelectedInviteType] = useState(InviteType[0])
    const [location, setLocation] = useState('')
    const [date, setDate] = useState('')
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const dataEmployees = useDataEmployees({ keyword: keywordEmployee })
    const inviteDraftJoin = useInviteDraftJoin({ onSuccess: (response) => onInvited(transformResponseToSingle(response)) })
    const employees = transformResponseToList(dataEmployees.data)

    const submit = () => {
        inviteDraftJoin.mutate({
            ID: draftJoin.ID,
            type: selectedInviteType.id,
            location, date,
            employeeID: selectedEmployee.ID
        })

    }
    const searchEmployee = (event) => {
        event.preventDefault()
        dataEmployees.refetch()
    }

    return (
        <Transition.Root show={draftJoin !== null} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <CalendarIcon className="h-6 w-6 text-cyan-500" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Mengundang
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Silakan isian di bawah ini terlebih dahulu untuk dapat membuat audiensi
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='py-5 px-3'>

                                <div className='pb-5'>
                                    <RadioGroup value={selectedInviteType} onChange={setSelectedInviteType}>
                                        <RadioGroup.Label className="bg-white text-md font-medium text-gray-900"></RadioGroup.Label>

                                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                                            {InviteType.map((type) => (
                                                <RadioGroup.Option
                                                    key={type.id}
                                                    value={type}
                                                    className={({ checked, active }) =>
                                                        classNames(
                                                            checked ? 'border-transparent' : 'border-gray-300',
                                                            active ? 'ring-2 ring-indigo-500' : '',
                                                            'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                                        )
                                                    }
                                                >
                                                    {({ checked, active }) => (
                                                        <>
                                                            <div className="flex-1 flex">
                                                                <div className="flex flex-col">
                                                                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                        {type.name}
                                                                    </RadioGroup.Label>
                                                                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                                        {type.description}
                                                                    </RadioGroup.Description>

                                                                </div>
                                                            </div>
                                                            <CheckCircleIcon
                                                                className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                                aria-hidden="true"
                                                            />
                                                            <div
                                                                className={classNames(
                                                                    active ? 'border' : 'border-2',
                                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                                    'absolute -inset-px rounded-lg pointer-events-none'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    )}
                                                </RadioGroup.Option>
                                            ))}
                                        </div>
                                    </RadioGroup>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            Tanggal
                                        </label>
                                        <input
                                            type="date"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={selectedInviteType.id === 'Online' ? 'Silakan isi dengan link meeting' : 'Silakan masukan alamat lengkap pertemuan'}
                                            value={date}
                                            onChange={({ target: { value } }) => setDate(value)}
                                        />
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            {selectedInviteType.id === 'Online' ? 'Tautan' : 'Lokasi'}
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={selectedInviteType.id === 'Online' ? 'Silakan isi dengan link meeting' : 'Silakan masukan alamat lengkap pertemuan'}
                                            value={location}
                                            onChange={({ target: { value } }) => setLocation(value)}
                                        />
                                    </div>

                                </div>

                                <div>
                                    <form onSubmit={searchEmployee} className="mt-6 flex">
                                        <label htmlFor="email" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            type="text"
                                            name="keywordEmployee"
                                            id="keywordEmployee"
                                            value={keywordEmployee}
                                            onChange={({ target: { value } }) => setKeywordEmployee(value)}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="Ketik nama PIC untuk mencari"
                                        />
                                        <button
                                            type="submit"
                                            className="ml-4 flex-shrink-0 px-4 py-2 border border-transparent text-sm flex-row flex items-center gap-2 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <SearchIcon className='w-4 h-4' />
                                            Cari
                                        </button>
                                    </form>

                                    <RadioGroup value={selectedEmployee} onChange={setSelectedEmployee}>
                                        <RadioGroup.Label className="bg-white text-md font-medium text-gray-900"></RadioGroup.Label>

                                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                                            {employees.map((employee) => (
                                                <RadioGroup.Option
                                                    key={employee.ID}
                                                    value={employee}
                                                    className={({ checked, active }) =>
                                                        classNames(
                                                            checked ? 'border-transparent' : 'border-gray-300',
                                                            active ? 'ring-2 ring-indigo-500' : '',
                                                            'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                                        )
                                                    }
                                                >
                                                    {({ checked, active }) => (
                                                        <>
                                                            <div className="flex-1 flex">
                                                                <div className="flex flex-col">
                                                                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                        {employee.name}
                                                                    </RadioGroup.Label>
                                                                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                                        {employee.email}
                                                                    </RadioGroup.Description>
                                                                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                                                        {employee.phoneNumber}
                                                                    </RadioGroup.Description>

                                                                </div>
                                                            </div>
                                                            <CheckCircleIcon
                                                                className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                                aria-hidden="true"
                                                            />
                                                            <div
                                                                className={classNames(
                                                                    active ? 'border' : 'border-2',
                                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                                    'absolute -inset-px rounded-lg pointer-events-none'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    )}
                                                </RadioGroup.Option>
                                            ))}
                                        </div>
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    <button
                                        type="button"
                                        disabled={inviteDraftJoin.isLoading}
                                        className={classNames(
                                            "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:w-auto sm:text-sm",
                                            inviteDraftJoin.isLoading ? "bg-gray-600 text-white hover:bg-gray-700" : "bg-cyan-600 text-white hover:bg-cyan-700"
                                        )}
                                        onClick={submit}
                                    >
                                        {inviteDraftJoin.isLoading && <RefreshIcon className='h-4 w-4 animate-spin text-white' />}
                                        {inviteDraftJoin.isLoading ? 'Memproses ...' : 'Mengundang'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}