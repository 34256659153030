import DashboardLayout from "Layout/DashboardLayout"

import { useState } from 'react'
import { DocumentIcon, HomeIcon } from "@heroicons/react/outline"
import { Link, NavLink } from "react-router-dom"
import { useDataTemplateClauseDocuments } from "Data/TemplateClauseDocument"
import { transformResponseToList } from "../../../Utility/transformer"
import ModalViewTemplate from "./ModalViewTemplate"

export default function BrowseTemplate() {
    const dataTemplateClauseDocuments = useDataTemplateClauseDocuments({})
    const templates = transformResponseToList(dataTemplateClauseDocuments.data)

    const [selectedTemplate, setSelectedTemplate] = useState()
    const [selectedAction, setSelectedAction] = useState()

    return (
        <DashboardLayout>
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 flex">
                <div className="min-w-0 flex flex-row">
                    <div className="mr-10">
                        <h1 className="text-lg font-medium leading-6 antialiased text-gray-900 sm:truncate">
                            Manajemen Template Pasal
                        </h1>
                    </div>

                    <nav className="flex" aria-label="Breadcrumb">
                        <ol className="flex items-center space-x-4">
                            <li>
                                <div>
                                    <Link to="/" className="text-gray-400 hover:text-gray-500">
                                        <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                        <span className="sr-only">Home</span>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <svg
                                        className="flex-shrink-0 h-5 w-5 text-gray-300"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                    >
                                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                                    </svg>
                                    <NavLink
                                        to={"/dashboard/template"}
                                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    >
                                        Kelola
                                    </NavLink>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="mt-4 flex sm:mt-0 sm:ml-4 pl-10">
                    <div>

                    </div>
                </div>
            </div>
            <div>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="p-10">
                        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {templates.map((template) => (
                                <li
                                    key={template.ID}
                                    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
                                >
                                    <div className="flex-1 flex flex-col p-8">
                                        <h3 className="mt-6 text-gray-900 text-sm font-medium">{template.name}</h3>
                                        <dl className="mt-1 flex-grow flex flex-col justify-between">
                                            <dt className="sr-only">Spesifikasi</dt>
                                            <dd className="mt-3">
                                                <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                                    {template.clauseGroups.length} Pasal, {template.clauseGroups.map(clauseGroup => clauseGroup?.clausal?.length || 0).reduce((prev, current) => prev + current)} Ayat
                                                </span>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div>
                                        <div className="-mt-px flex divide-x divide-gray-200">
                                            <div className="w-0 flex-1 flex">
                                                <button
                                                    onClick={() => setSelectedTemplate(template) || setSelectedAction('view')}
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <DocumentIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                                    <span className="ml-3">Lihat</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <ModalViewTemplate
                template={selectedTemplate}
                show={selectedAction === 'view'}
                onClose={() => setSelectedAction(null) || setSelectedTemplate(null)}
            />
        </DashboardLayout>
    )
}