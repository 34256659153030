import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FolderOpenIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

export default function ModalViewTemplate({ show, onClose, template }) {
    const cancelButtonRef = useRef(null)
    
    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <FolderOpenIcon className="h-6 w-6 text-cyan-500" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {template?.name}
                                    </Dialog.Title>
                                   
                                </div>
                            </div>


                            <div className='py-5 px-3'>
                                
                                <div className='py-4 '>
                                    {template && (
                                        <div className='border-gray-300 border rounded-lg shadow-sm py-4'>
                                            <div className='mx-2'>
                                                {template.clauseGroups.map((clauseGroup, clauseGroupIndex) => (
                                                    <div className='ml-2 mb-3'>
                                                        <div className="flex items-center">
                                                            <span className="text-lg h-10 w-10 rounded-full bg-indigo-600 justify-center items-center flex text-white mr-3">
                                                                {clauseGroupIndex + 1}
                                                            </span>
                                                            <span className="w-full border-0 border-b border-transparent focus:ring-0 sm:text-sm md:text-sm">
                                                                {clauseGroup.title}
                                                            </span>
                                                        </div>
                                                        <div className='ml-10'>
                                                            {clauseGroup.clausal.map((clausal, clausalIndex) => (
                                                                <div className="flex items-start space-x-4  mb-3">
                                                                    <div className="flex-shrink-0">
                                                                        <span className="h-10 w-10 rounded-full bg-indigo-600 justify-center items-center flex text-xs text-white">
                                                                            {clauseGroupIndex + 1}.{clausalIndex + 1}
                                                                        </span>
                                                                    </div>
                                                                    <div className="min-w-0 flex-1">
                                                                        <div className="relative">
                                                                            <div
                                                                                className="border border-white rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                                                                                {clausal.content}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Tutup
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}