import { Link, NavLink } from 'react-router-dom'
import { useAuth } from 'App'
import { Disclosure, Transition } from '@headlessui/react'
import PortalLayout from 'Layout/PortalLayout'
import Pattern from 'Asset/Content/Home/632923be6b256531325d837e_flower.svg'
import { sponsors, benefits, collaborations, testimonis } from './content'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import classNames from 'classnames'
import 'react-photo-view/dist/react-photo-view.css';
import { Fragment } from 'react'

export default function Home() {
    const { user, signOut } = useAuth()

    return (
        <PortalLayout navBarTextClassName={{ invert: 'text-white', base: 'text-white' }}>
            <div className=''>
                <div className='relative' >
                    <div style={{ backgroundColor: '#66696a', backgroundImage: `url(${Pattern})`, backgroundRepeat: 'no-repeat', marginTop: -72 }} className="relative overflow-hidden" >
                        <div className='grid grid-cols-1 md:grid-cols-2 mx-5 md:mx-20 items-center z-10 pt-[140px] md:pt-0 min-h-screen'>
                            <div className='pr-0 md:pr-10'>
                                <div className=''>
                                    <h1 className=' text-[2rem] md:text-[64px] text-center md:text-left' style={{ fontFamily: 'MyriadPro', color: '#fbd024', lineHeight: '1em' }}>
                                        Kolaborasi Vokasi dan DUDI untuk SDM yang berdaya saing
                                    </h1>
                                </div>
                                <div className='mt-3 md:mt-10'>
                                    <h2 className='leading-6 italic font-bold text-center md:text-left' style={{ fontFamily: 'DINPro', fontSize: '18px', color: '#f2efc7', maxWidth: 450 }}>
                                        Mulailah bermitra dengan kami dan dapatkan pasar SDM terbaik yang ada di Indonesia
                                    </h2>
                                </div>

                                <div className='py-4 justify-center flex'>
                                    <Link style={{ backgroundColor: '#30789e', fontFamily: 'MyriadPro', fontSize: 24 }} className="text-white font-bold py-2 rounded-lg px-4" to={user ? "/dashboard" : "/join"}>
                                        Mulai Bermitra
                                    </Link>
                                </div>
                            </div>
                            <div style={{ height: 340 }}>

                                <PhotoProvider>
                                    <PhotoView src={require('Asset/Content/Home/6320473339548ed15bf3da48_Photo Penandatanganan perjanjian kerja sama 4.png')}>
                                        <img className='rounded-lg pt-24 md:pt-0 opacity-60' src={require('Asset/Content/Home/6320473339548ed15bf3da48_Photo Penandatanganan perjanjian kerja sama 4.png')} />
                                    </PhotoView>
                                </PhotoProvider>
                            </div>
                        </div>

                    </div>
                    <div className='py-32'>
                        <div className=''>
                            <h1 style={{ fontFamily: 'DINPro' }} className='text-center justify-center font-medium text-xl md:text-3xl'>
                                Terpercaya oleh lebih dari 500+ pelaku usaha dan pelaku Industri
                            </h1>
                            <div className='grid grid-cols-1 md:grid-cols-5 mt-10 md:mt-[100px] mb-32 md:mb-[300px]'>
                                {sponsors.map(sponsor => (
                                    <div className='mx-10 items-center justify-center flex ' >
                                        <img src={sponsor.url} className="w-44 mb-4" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='flex justify-center'>

                            <div className='flex relative justify-center items-center'>
                                <div  className="absolute border-4" style={{ top:-10, left:-40,  width: '315px', height: '60px', borderColor: '#30789e', borderRadius: '50%' }}>

                                </div>
                                <h1 className='text-3xl '>
                                    Benefit bagi DUDI
                                </h1>
                            </div>
                        </div>
                        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mx-10 mt-32 mb-32 md:md-[200px]">
                            {benefits.map((benefit, index) => (
                                <Disclosure as="li" key={index} className="col-span-1 rounded-lg">
                                    {({ open }) => (
                                        <Fragment>
                                            <Disclosure.Button as="div" className="w-full flex flex-col items-center justify-between text-center p-6 space-x-6 cursor-pointer">
                                                {benefit.icon}
                                                {benefit.title}
                                            </Disclosure.Button>
                                            <Transition
                                                show={open}
                                                enter="transform transition duration-[400ms]"
                                                enterFrom="opacity-0 rotate-[-120deg] scale-50"
                                                enterTo="opacity-100 rotate-0 scale-100"
                                                leave="transform duration-200 transition ease-in-out"
                                                leaveFrom="opacity-100 rotate-0 scale-100 "
                                                leaveTo="opacity-0 scale-95 "
                                            >
                                                <Disclosure.Panel static as="div">
                                                    <div className="-mt-px flex divide-x divide-gray-200">
                                                        <span style={{ color: '#30789e' }} className="font-semibold leading-8 justify-center items-center text-center">
                                                            {benefit.description}
                                                        </span>
                                                    </div>
                                                </Disclosure.Panel>
                                            </Transition>
                                        </Fragment>
                                    )}
                                </Disclosure>
                            ))}
                        </ul>
                    </div>
                    <div style={{ backgroundColor: '#66696a', backgroundImage: `url(${Pattern})`, backgroundRepeat: 'no-repeat', marginTop: -72 }} className="relative overflow-hidden" >
                        <div className='grid grid-cols-1 md:grid-cols-2 mx-5 md:mx-20 items-center z-10 pt-[140px] md:pt-[90px] pb-[30px] md:pb-[120px]'>
                            <div className='col-span-1'>
                                <img
                                    src={require('Asset/Content/Home/6321622946fcb862d1cad48a_Yuhu.png')}
                                    alt=""
                                    className=" inset-0 object-center object-cover rounded-lg"
                                    style={{ height: 650 }}
                                />
                            </div>
                            <div className='col-span-1 flex flex-col space-y-7 justify-center items-start h-full w-full  px-0 md:px-12 mt-24 md:mt-0'>
                                <h2 style={{ fontFamily: 'DINPro', fontSize: '20px' }} className='text-2xl font-medium'>Bentuk <span className='text-white'>kolaborasi DUDI</span> bersama <span className='text-white'>Satuan Pendidikan Vokasi</span></h2>

                                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                    {collaborations.map((collaboration, index) => (
                                        <div key={index} className={classNames(collaboration.className || 'col-span-1', "relative flex flex-col rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500")}>
                                            <div className="flex-shrink-0">
                                                {collaboration.icon}
                                            </div>
                                            <p className="text-center font-medium" style={{ fontFamily: 'DINPro', fontSize: '16px', color: '#30789e' }}>{collaboration.description}</p>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className='py-32'>
                        <div className='flex justify-center'>
                            <h1 className='text-3xl'>
                                Testimoni
                            </h1>


                        </div>
                        <ul role="list" className="grid grid-cols-1 gap-0 md:gap-40 sm:grid-cols-2 mx-10 mt-32">
                            {testimonis.map((testimoni, index) => (
                                <li key={index} className="col-span-1 rounded-lg  ">

                                    <div>
                                        <div className="-mt-px flex divide-x divide-gray-200 " >
                                            <span style={{ color: '#30789e' }} className="font-semibold leading-8">
                                                {testimoni.quota}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row items-start justify-between p-6 space-x-6">
                                        <div className=''>
                                            {testimoni.person.avatar}
                                        </div>
                                        <div className='flex-1'>
                                            <h4 className='text-md font-bold'>{testimoni.person.name}</h4>
                                            <span>{testimoni.person.position}</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </PortalLayout>
    )
}