import { useMutation, useQuery } from "react-query"
import { requestDelete, requestGet, requestPost } from "Provider/RestClient"

export function useDataCompanySigners({ sort = 'created', order = 'desc', expands = ['company'], pageSize = 20, currentPage = 1, keyword, companyID }) {
    const path = '/contract/company-signer'
    return useQuery(path, () => requestGet(path, { keyword, sort, order, pageSize, currentPage, expand: expands.join(','), companyID }))
}

export function useCreateCompanySigner({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ officerName, officerPosition, officerEmail, officerPhone, companyID, password }) => requestPost('/contract/company-signer', {  officerName, officerPosition, officerEmail, officerPhone, companyID, password }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useUpdateCompanySigner({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, officerName, officerPosition, officerEmail, officerPhone, password }) => requestPost(`/contract/company-signer/${ID}`, {  officerName, officerPosition, officerEmail, officerPhone, password }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useDeleteCompanySigner({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, type }) => requestDelete(`/contract/company-signer/${ID}`, { type }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}
