import { Link, useParams } from "react-router-dom"
import ContractEditorLayout from "Layout/ContractEditorLayout"
import { useContractContext } from "../ContractEditor"
import SignatureInput from "./SignatureInput"
import { BadgeCheckIcon, FingerPrintIcon, LinkIcon, RefreshIcon } from '@heroicons/react/outline'
import classNames from "classnames"
import { Fragment, useState } from "react"
import EmptyContractSigner from "./EmptyContractSigner"
import { authenticationProvider } from "Provider/Authentication"
import { Transition } from "@headlessui/react"



export default function AssignContractSignature() {
    const { dataContract, contract } = useContractContext()
    const first = contract.contractSigners.find(contractSigner => contractSigner.order == 1)
    const second = contract.contractSigners.find(contractSigner => contractSigner.order == 2)

    return (
        <ContractEditorLayout contract={contract} appendBreadcrumbs={[{ name: 'Buat Naskah Baru', 'to': '/dashboard/contract/new' }]} className="">
            <div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-2">
                    <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden flex">
                        <ContractSigning
                            signer={first}
                            order={1}
                            contract={contract}
                            disabled={authenticationProvider.isContractValidator()}
                            onSuccess={() => dataContract.refetch()}
                        />
                    </div>
                    <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden flex">
                        <ContractSigning
                            signer={second}
                            order={2}
                            contract={contract}
                            disabled={authenticationProvider.isCompanySigner() || authenticationProvider.isContractValidator()}
                            onSuccess={() => dataContract.refetch()}
                        />
                    </div>
                </div>
            </div>
        </ContractEditorLayout>
    )
}


const ContractSigning = ({ signer, order, contract, onSuccess, disabled = false }) => {
    const [copyState, setCopyState] = useState(null)
    const copyToClipboard = async (signer) => {
        setCopyState('Copying')
        const link = `${process.env.REACT_APP_BASE_URL_WEB}/signature/${signer.ID}/${signer.token}`
        await navigator.clipboard.writeText(link)
        setTimeout(() => {
            setCopyState('Copied')
            setTimeout(() => {
                setCopyState(null)
            }, 1000)
        }, 500)

    }
    return (
        <Fragment>
            
            {!signer && <Link to={`/dashboard/contract/${contract.ID}/update/assign-company`}><EmptyContractSigner /></Link>}
            {
                signer && (
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row">
                            <div className={classNames("rounded-md p-3", signer.signingContract ? "bg-indigo-500" : "bg-rose-500")}>
                                <FingerPrintIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className="ml-2">
                                <p className="text-sm font-medium text-gray-500 truncate">
                                    {signer.companySigner.officerName}
                                </p>
                                <p className="text-sm font-medium text-gray-500 truncate">
                                    {signer.companySigner.officerPosition} di  {signer.companySigner.company.name}
                                </p>
                            </div>
                            <div className="w-full justify-end items-end flex">
                                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                    <button

                                        onClick={() => copyToClipboard(signer)}
                                        type="button"
                                        className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                        <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        Salin Tautan
                                    </button>
                                    <div
                                        className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                        {copyState === 'Copying' && (
                                            <RefreshIcon
                                                className={classNames("h-5 w-5 animate-spin text-yellow-400")}
                                                aria-hidden="true"
                                            />
                                        )}
                                        {copyState !== 'Copying' && (
                                            <BadgeCheckIcon
                                                className={classNames("h-5 w-5 text-gray-400", ({
                                                    'Copying': 'text-yellow-400',
                                                    'Copied': 'text-green-400',
                                                })[copyState])}
                                                aria-hidden="true"
                                            />
                                        )}
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="flex pt-10">
                            <div className="relative w-full flex items-center justify-center py-10 border border-gray-300 rounded-md px-3 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                <label htmlFor="name" className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                    Tanda Tangan
                                </label>
                                <div className="w-full border-0 flex items-center justify-center" >
                                    <img src={signer.signingContract?.urlSigning} style={{ height: 224 }} />
                                </div>
                            </div>
                        </div>
                        {!disabled && (
                            <div className="pb-10 flex w-full">
                                <SignatureInput
                                    signer={signer}
                                    contract={contract}
                                    order={order}
                                    onSuccess={onSuccess}
                                />
                            </div>
                        )}
                    </div>
                )
            }
        </Fragment>
    )
}