
import { useQuery } from "react-query"
import { requestGet } from "Provider/RestClient"
import queryString from 'query-string'

export function useDataCompany(ID, { expands, onFetched = () => null, enabled = true }) {
    const path = `/contract/company/${ID}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onFetched, enabled })
}

export function useDataCompanies({ sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, published }) {
    const path = '/contract/company'
    const query = { sort, order, pageSize, currentPage, expand: expands.join(','), published: published !== null ? (published === false ? 'false' : (published === true ? 'true' : published)) : published }
    return useQuery(`${path}?${queryString.stringify(query)}`, () => requestGet(path, query))
}

export function useDataCompanySectors({ sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1 }) {
    const path = '/contract/company/sector'
    return useQuery(path, () => requestGet(path, { sort, order, pageSize, currentPage, expand: expands.join(',') }))
}

export const CompanySectorType = [
    { id: 1, title: 'DUDI Dalam Negeri', description: 'Lorem ipsum dolar sit amet' },
    { id: 2, title: 'Institusi Dalam Negeri', description: 'Lorem ipsum dolar sit amet' },
    { id: 3, title: 'Insitusi Luar Negeri', description: 'Lorem ipsum dolar sit amet' },
    { id: 4, title: 'Organisasi Dalam Negeri', description: 'Lorem ipsum dolar sit amet' },
]