
const KEY_AUTHENTICATION = 'authentication-key'
const KEY_ROLE = 'role-key'
/**
 * This represents some generic auth provider API, like Firebase.
 */
const authenticationProvider = {
    in(token, role, callback) {
        localStorage.setItem(KEY_AUTHENTICATION, token)
        localStorage.setItem(KEY_ROLE, role)
        if (callback) {
            setTimeout(callback, 100);
        }
    },
    out(callback) {
        localStorage.removeItem(KEY_AUTHENTICATION)
        localStorage.removeItem(KEY_ROLE)
        if (callback) {
            setTimeout(callback, 100);
        }
    },

    setRole(role, callback) {
        localStorage.setItem(KEY_ROLE, role)
        if (callback) {
            setTimeout(callback, 100);
        }
    },
    authenticated() {
        return this.token() !== null
    },
    token() {
        return localStorage.getItem(KEY_AUTHENTICATION)
    },
    role() {
        return localStorage.getItem(KEY_ROLE)
    },
    roleLabel(){
        return ({
            [ROLE_COMPANY_SIGNER]:'DUDI',
            [ROLE_CONTRACT_OPERATOR]:'Operator Kontrak',
            [ROLE_CONTRACT_VALIDATOR]:'Validator Kontrak',
        })[this.role()]
    },
    hasTokenThen(callback, defaultValue = null) {
        const token = localStorage.getItem(KEY_AUTHENTICATION)
        return token ? callback(token) : defaultValue
    },
    hasRoleThen(callback, defaultValue = null) {
        const role = localStorage.getItem(KEY_ROLE)
        return role ? callback(role) : defaultValue
    },
    is(compareRole) {
        return this.role() === compareRole
    },
    isContractOperator() {
        return this.is(ROLE_CONTRACT_OPERATOR)
    },
    isContractValidator() {
        return this.is(ROLE_CONTRACT_VALIDATOR)
    },
    isCompanySigner() {
        return this.is(ROLE_COMPANY_SIGNER)
    }
};
export const ROLE_CONTRACT_OPERATOR = 'Contract-Operator'
export const ROLE_CONTRACT_VALIDATOR = 'Contract-Validator'
export const ROLE_COMPANY_SIGNER = 'Company-Signer'

export { authenticationProvider, KEY_AUTHENTICATION };
