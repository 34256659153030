/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { formatDate } from '../Utility/formatter';
import { generateCalendarDates } from '../Utility/generator';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';

const PREVIOUS_YEARS = 3
const NEXT_YEARS = 20

const cursorToday = new Date()
const today = formatDate(new Date())

const years = [
    ...Array(PREVIOUS_YEARS).fill(cursorToday.getFullYear()).map((currentYear, index) => (currentYear - (PREVIOUS_YEARS - index))),
    cursorToday.getFullYear(),
    ...Array(NEXT_YEARS).fill(cursorToday.getFullYear()).map((currentYear, index) => (currentYear + index + 1))
]

export default function CalenderSelection({ onChange, defaultValues = [], disabled = false }) {
    const [startDate, setStartDate] = useState(defaultValues.length >= 1 ? defaultValues[0] : null)
    const [endDate, setEndDate] = useState(defaultValues.length >= 2 ? defaultValues[1] : null)
    const [cursorDate, setCursorDate] = useState(cursorToday)
    const [months, setMonths] = useState([])

    useEffect(() => {
        async function calculateDate() {
            const cursorMonth = await generateCalendarDates(new Date(cursorDate.getFullYear(), cursorDate.getMonth()))
            const cursorNextDate = new Date(cursorDate.getFullYear(), cursorDate.getMonth() + 1)
            const cursorNextMonth = await generateCalendarDates(new Date(cursorNextDate.getFullYear(), cursorNextDate.getMonth()))

            setMonths([
                {
                    name: moment(cursorDate).format('MMMM'),
                    days: cursorMonth.map(item => ({ date: formatDate(item.iso), isCurrentMonth: item.type === 'current', isToday: today === item.iso })),
                },
                {
                    name: moment(cursorNextDate).format('MMMM'),
                    days: cursorNextMonth.map(item => ({ date: formatDate(item.iso), isCurrentMonth: item.type === 'current', isToday: today === item.iso }))
                },

            ])
        }
        calculateDate()
    }, [cursorDate])


    function select(day) {
        if (!disabled) {
            if (startDate === null) {
                setStartDate(day.date)
                onChange([day.date, null])
                return
            }

            if (endDate == null) {
                setEndDate(day.date)
                onChange([startDate, day.date])
                return
            }

            setStartDate(day.date)
            setEndDate(null)
            onChange([startDate, null])
        }
    }

    function classNamesForDate(day) {
        if (!day.isCurrentMonth) {
            return 'bg-gray-50 text-gray-400'
        }
        if (startDate !== null) {
            if (endDate === null) {
                if (moment(day.date, 'YYYY-MM-DD').isSame(moment(startDate, 'YYYY-MM-DD'))) {
                    return 'bg-indigo-600 text-indigo-50'
                }
            } else {
                if (moment(day.date, 'YYYY-MM-DD').isSameOrAfter(moment(startDate, 'YYYY-MM-DD')) && moment(day.date, 'YYYY-MM-DD').isSameOrBefore(moment(endDate, 'YYYY-MM-DD'))) {
                    return 'bg-indigo-600 text-indigo-50'
                }
            }
        }
        return 'bg-white text-gray-900'
    }


    return (
        <div>
            <div className='flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'>
                <div className="w-72">
                    <Listbox value={cursorDate.getFullYear()} onChange={(value) => setCursorDate(new Date(value, cursorDate.getMonth()))}>
                        <div className="relative mt-1">
                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className="block truncate">{cursorDate.getFullYear()}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {years.map((year) => (
                                        <Listbox.Option
                                            key={year}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                }`
                                            }
                                            value={year}
                                        >
                                            {({ selected }) => (
                                                <Fragment>
                                                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                        {year}
                                                    </span>
                                                    {selected && (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
            </div>
            <div className="relative grid grid-cols-1 gap-x-14 md:grid-cols-2">
                <button
                    onClick={() => setCursorDate(new Date(cursorDate.getFullYear(), cursorDate.getMonth() - 1))}
                    type="button"
                    className="absolute -top-1 -left-1.5 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                    <span className="sr-only">Previous month</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                    onClick={() => setCursorDate(new Date(cursorDate.getFullYear(), cursorDate.getMonth() + 1))}
                    type="button"
                    className="absolute -top-1 -right-1.5 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                    <span className="sr-only">Next month</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                {months.map((month, monthIdx) => (
                    <section
                        key={monthIdx}
                        className={classNames(monthIdx === months.length - 1 && 'hidden md:block', 'text-center')}
                    >
                        <h2 className="font-semibold text-gray-900">{month.name}</h2>
                        <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                            <div>S</div>
                            <div>S</div>
                            <div>R</div>
                            <div>K</div>
                            <div>J</div>
                            <div>S</div>
                            <div>M</div>
                        </div>
                        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                            {month.days.map((day, dayIdx) => (
                                <button
                                    key={day.date}
                                    type="button"
                                    onClick={() => select(day)}
                                    className={classNames(
                                        classNamesForDate(day),
                                        dayIdx === 0 && 'rounded-tl-lg',
                                        dayIdx === 6 && 'rounded-tr-lg',
                                        dayIdx === month.days.length - 7 && 'rounded-bl-lg',
                                        dayIdx === month.days.length - 1 && 'rounded-br-lg',
                                        'relative py-1.5 hover:bg-gray-100 focus:z-10'
                                    )}
                                >
                                    <time
                                        dateTime={day.date}
                                        className={classNames(
                                            day.isCurrentMonth && day.isToday && 'bg-indigo-600 font-semibold text-white',
                                            'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
                                        )}
                                    >


                                        {day.date.split('-').pop().replace(/^0/, '')}
                                    </time>
                                </button>
                            ))}
                        </div>
                    </section>
                ))}
            </div>

        </div>
    )
}