import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BadgeCheckIcon, ClipboardCopyIcon, RefreshIcon, SearchIcon, TrashIcon, UserAddIcon, UserIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { transformResponseToSingle } from '../../../Utility/transformer'
import { useDeleteCompanySigner } from 'Data/CompanySigner'

export default function ModalDeleteOfficer({ companySigner, type, onSuccess, onClose }) {
    const cancelButtonRef = useRef(null)
    const deleteCompanySigner = useDeleteCompanySigner({ onSuccess: (response) => onSuccess(transformResponseToSingle(response)) })

    const submit = () => {
        deleteCompanySigner.mutate({
            ID: companySigner.ID,
            type
        })
    }

    return (
        <Transition.Root show={companySigner !== null} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <TrashIcon className="h-6 w-6 text-cyan-500" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {{
                                            'full': 'Menghapus Permanen Penanggung Jawab',
                                            'access': 'Menghapus Akses Penanggung Jawab',
                                        }[type]}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {{
                                                'full': 'Pastikan anda mengerti apa yang dilakukan, dengan menyetujui proses ini, data akan terhapus sepenuhnya dalam sistem. Apakah anda yakin?',
                                                'access': 'Pastikan anda mengerti apa yang dilakukan, dengan menyetujui proses ini, hanya akses saja yang akan terhapus sepenuhnya dalam sistem. Apakah anda yakin?',
                                            }[type]}
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='flex justify-end'>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    <button
                                        type="button"
                                        disabled={deleteCompanySigner.isLoading}
                                        className={classNames(
                                            "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:w-auto sm:text-sm",
                                            deleteCompanySigner.isLoading ? "bg-gray-600 text-white hover:bg-gray-700" : "bg-rose-600 text-white hover:bg-rose-700"
                                        )}
                                        onClick={submit}
                                    >
                                        {deleteCompanySigner.isLoading && <RefreshIcon className='h-4 w-4 animate-spin text-white' />}
                                        {deleteCompanySigner.isLoading ? 'Memproses ...' : 'Menghapus'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
