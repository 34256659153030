import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { RadioGroup } from '@headlessui/react'
import { BadgeCheckIcon, CheckCircleIcon, PaperAirplaneIcon, PlusCircleIcon, RefreshIcon, SpeakerphoneIcon } from '@heroicons/react/outline'
import { useValidateContract } from 'Data/Contract'
import { useContractContext } from '../ContractEditor'
import classNames from 'classnames'
import { authenticationProvider } from 'Provider/Authentication'

export default function ModalPostValidation({ validationBy, onClose, onSuccess }) {
    const { contract } = useContractContext()
    const cancelButtonRef = useRef(null)
    const validateContract = useValidateContract({
        onSuccess: (data) => {
            setNote('')
            setResponse('Update')
            onSuccess(data)
        }
    })
    const [note, setNote] = useState('')
    const [response, setResponse] = useState('Update')

    const submit = () => {
        validateContract.mutate({
            ID: contract.ID,
            type: response,
            by: validationBy.name,
            note
        })
    }
    return (
        <Transition.Root show={validationBy !== null} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <BadgeCheckIcon className="h-6 w-6 text-cyan-500" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Validasi
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Konfirmasi Validasi
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='py-5 px-3'>
                                <div className='pb-5'>

                                    <RadioGroup value={response} onChange={(value) => setResponse(value)}>
                                        <RadioGroup.Label className="bg-white text-md font-medium text-gray-900"></RadioGroup.Label>

                                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                                            <RadioGroup.Option value="Reject"
                                                className={({ checked, active }) =>
                                                    classNames(
                                                        checked ? 'border-transparent' : 'border-gray-300',
                                                        active ? 'ring-2 ring-rose-500' : '',
                                                        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                                    )
                                                }
                                            >
                                                {({ checked, active }) => (
                                                    <>
                                                        <div className="flex-1 flex">
                                                            <div className="flex flex-col">
                                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                    Menolak
                                                                </RadioGroup.Label>
                                                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                                    Menolak persetujuan dan akan memulai lagi proses konstruksi pasal dari awal.
                                                                </RadioGroup.Description>
                                                            </div>
                                                        </div>
                                                        <PlusCircleIcon
                                                            className={classNames('h-5 w-5 rotate-45', !checked ? 'h-5 w-5 text-gray-600' : 'text-rose-600')}
                                                            aria-hidden="true"
                                                        />
                                                        <div
                                                            className={classNames(
                                                                active ? 'border' : 'border-2',
                                                                checked ? 'border-rose-500' : 'border-transparent',
                                                                'absolute -inset-px rounded-lg pointer-events-none'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                            <RadioGroup.Option value="Approve"
                                                className={({ checked, active }) =>
                                                    classNames(
                                                        checked ? 'border-transparent' : 'border-gray-300',
                                                        active ? 'ring-2 ring-green-500' : '',
                                                        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                                    )
                                                }
                                            >
                                                {({ checked, active }) => (
                                                    <>
                                                        <div className="flex-1 flex">
                                                            <div className="flex flex-col">
                                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                    Menerima
                                                                </RadioGroup.Label>
                                                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                                    Menerima dan akan dilanjutkan pada proses selanjutnya
                                                                </RadioGroup.Description>
                                                            </div>
                                                        </div>
                                                        <CheckCircleIcon
                                                            className={classNames('h-5 w-5', !checked ? 'h-5 w-5 text-gray-600' : 'text-green-600')}
                                                            aria-hidden="true"
                                                        />
                                                        <div
                                                            className={classNames(
                                                                active ? 'border' : 'border-2',
                                                                checked ? 'border-green-500' : 'border-transparent',
                                                                'absolute -inset-px rounded-lg pointer-events-none'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        </div>
                                    </RadioGroup>

                                </div>

                                <div className='py-4'>
                                    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                        <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                            Pesan
                                        </label>
                                        <textarea
                                            className=" w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                            placeholder={'Silakan masukan pesan yang akan disampaikan, boleh juga kosong'}
                                            value={note}
                                            onChange={({ target: { value } }) => setNote(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    <button
                                        type="button"
                                        disabled={validateContract.isLoading}
                                        className={classNames(
                                            "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:w-auto sm:text-sm",
                                            validateContract.isLoading ? "bg-gray-600 text-white hover:bg-gray-700" : "bg-cyan-600 text-white hover:bg-cyan-700"
                                        )}
                                        onClick={submit}
                                    >
                                        {validateContract.isLoading && <RefreshIcon className='h-4 w-4 animate-spin text-white' />}
                                        {validateContract.isLoading ? 'Memproses ...' : 'Mengirim'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setResponse('Update') || onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}