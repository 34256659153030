import { createContext, Fragment, useContext, useEffect, useState } from 'react'
import { Disclosure, Listbox, Menu, Transition } from '@headlessui/react'
import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    BriefcaseIcon,
    CalendarIcon,
    CheckCircleIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CurrencyDollarIcon,
    IdentificationIcon,
    LinkIcon,
    MailIcon,
    PaperClipIcon,
    PencilIcon,
} from '@heroicons/react/solid'
import DashboardLayout from 'Layout/DashboardLayout'
import { useDataDraftJoin } from 'Data/DraftJoin'
import { Link, NavLink, Route, Routes, useLocation, useNavigate, useParams, useResolvedPath } from 'react-router-dom'
import { transformResponseToPagination, transformResponseToSingle } from '../../../Utility/transformer'
import moment from 'moment'
import classNames from 'classnames'
import DetailDraftCompanyBrowseOfficer from '../DetailDraftCompanyBrowseOfficer'
import DetailDraftCompanyBrowseContract from '../DetailDraftCompanyBrowseContract'
import { useDataContracts } from 'Data/Contract'
import InviteDraftJoin from '../BrowseDraftCompany/InviteDraftJoin'
import PublishDraftJoin from '../BrowseDraftCompany/PublishDraftJoin'
import { OfficeBuildingIcon } from '@heroicons/react/outline'
import { useDataCompanySigners } from 'Data/CompanySigner'

export const DraftCompanyContext = createContext()

export function DraftCompanyProvider({ children, draftJoin, dataDraftJoin }) {
    return <DraftCompanyContext.Provider value={{ draftJoin, dataDraftJoin }}>{children}</DraftCompanyContext.Provider>
}

export function useDraftJoinContext() {
    return useContext(DraftCompanyContext)
}

export default function DetailDraftCompany() {
    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const dataDraftJoin = useDataDraftJoin(id, { expands: [] })
    const dataCompanySigners = useDataCompanySigners({ companyID: id })
    const paginationCompanySigners = transformResponseToPagination(dataCompanySigners.data)
    const draftJoin = transformResponseToSingle(dataDraftJoin.data)
    const dataContracts = useDataContracts({ companyID: draftJoin?.published?.companyID, enabled: false, expands: ['contractSigners'] })
    const paginationContracts = transformResponseToPagination(dataContracts.data)
    const [selectedInvite, setSelectedInvite] = useState(null)
    const [selectedPublish, setSelectedPublish] = useState(null)


    useEffect(() => {
        if (draftJoin?.published) {
            dataContracts.refetch()
        }
    }, [draftJoin])

    if (!draftJoin) return null

    const routes = [
        { name: 'Informasi', to: `/dashboard/company/draft/${id}`, count: null },
        { name: 'Naskah Kerjasama', to: `/dashboard/company/draft/${id}/contract`, count: (draftJoin.published ? paginationContracts.itemCount : null) },
        { name: 'Daftar Penanggung Jawab', to: `/dashboard/company/draft/${id}/officer`, count: paginationCompanySigners.itemCount },
    ]

    return (
        <DashboardLayout>
            <div className="min-h-full">
                <header className="bg-gray-50 py-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                        <div className="flex-1 min-w-0">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div>
                                            <Link to="/dashboard/company/draft" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                DUDI Terverifikasi
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <Link to="/dashboard/company/draft" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                DUDI Terrdaftar
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <Link to={`/dashboard/company/draft/${draftJoin.ID}`} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                Detail
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                                {draftJoin.companyName}
                            </h1>
                            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <OfficeBuildingIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    {draftJoin.companySector.name}
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <IdentificationIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    {draftJoin.companyCode}
                                </div>

                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    {draftJoin.companyTaxIdentification}
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Mendaftar di {moment(draftJoin.created, 'YYYY-MM-DD HH:mm:ss').fromNow()} pada {moment(draftJoin.created, 'YYYY-MM-DD HH:mm:ss').format('D MMMM, YY')}
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 flex xl:mt-0 xl:ml-4">
                            {false && (
                                <Fragment>
                                    <span className=" sm:block">
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                                        >
                                            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Edit
                                        </button>
                                    </span>

                                    <span className="hidden sm:block ml-3">
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                                        >
                                            <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            View
                                        </button>
                                    </span>
                                </Fragment>
                            )}

                            <span className="sm:ml-3 relative z-0">
                                <Listbox>
                                    {({ open }) => (
                                        <>
                                            <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                                            <div className="relative">
                                                <div className={classNames("inline-flex shadow-sm rounded-md divide-x", draftJoin.published ? "divide-purple-600" : "divide-gray-600")}>
                                                    <div className={classNames("relative z-0 inline-flex shadow-sm rounded-md divide-x", draftJoin.published ? "divide-purple-600" : "divide-gray-600")}>
                                                        <div className={classNames("relative inline-flex items-center py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white", draftJoin.published ? "bg-purple-500" : "bg-gray-500")}>
                                                            {draftJoin.published && <CheckIcon className="h-5 w-5" aria-hidden="true" />}
                                                            <p className="ml-2.5 text-sm font-medium">
                                                                {draftJoin.published ? 'Aktif' : 'Belum Aktif'}
                                                            </p>
                                                        </div>
                                                        <Listbox.Button className={classNames("relative inline-flex items-center p-2 rounded-l-none rounded-r-md text-sm font-medium text-white focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50", draftJoin.published ? "bg-purple-500 focus:ring-purple-500 hover:bg-purple-600" : "bg-gray-500 focus:ring-gray-500 hover:bg-gray-600")}>
                                                            <span className="sr-only">Aksi</span>
                                                            <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                        </Listbox.Button>
                                                    </div>
                                                </div>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="origin-top-right absolute left-0 mt-2 -mr-1 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                                                        <Listbox.Option onClick={() => setSelectedPublish(draftJoin.published ? null : draftJoin)} className={'cursor-default select-none relative p-4 text-sm'}>
                                                            <div className="flex flex-col">
                                                                <div className="flex justify-between">
                                                                    <p className={draftJoin.published ? 'font-semibold' : 'font-normal'}>
                                                                        Verifikasi
                                                                    </p>
                                                                    {draftJoin.published ? (
                                                                        <span className={'text-purple-500'}>
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                                <p className={classNames('text-gray-500', 'mt-2')}>
                                                                    Aktifkan dan berikan akses terhadap DUDI yang terdaftar
                                                                </p>
                                                            </div>
                                                        </Listbox.Option>
                                                        <Listbox.Option onClick={() => setSelectedInvite(draftJoin.invited ? null : draftJoin)} className={'cursor-default select-none relative p-4 text-sm'}>
                                                            <div className="flex flex-col">
                                                                <div className="flex justify-between">
                                                                    <p className={draftJoin.invited ? 'font-semibold' : 'font-normal'}>
                                                                        Undang
                                                                    </p>
                                                                    {draftJoin.invited ? (
                                                                        <span className={'text-purple-500'}>
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                                <p className={classNames('text-gray-500', 'mt-2')}>
                                                                    Mengundang DUDI untuk audiensi
                                                                </p>
                                                            </div>
                                                        </Listbox.Option>
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </span>

                            {/* Dropdown */}
                            <Menu as="span" className="ml-3 relative sm:hidden">
                                <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                    More
                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                >
                                                    Edit
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                >
                                                    View
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </header>

                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <h2 className="text-lg font-medium text-gray-900">Candidates</h2>

                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                </label>
                                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                                    defaultValue={routes.find((route) => route.to === location.pathname)?.to}
                                    onChange={({ target: { value } }) => navigate(value)}
                                >

                                    {routes.map(route =>
                                        <option key={route.to} value={route.to}>
                                            {route.name}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-200">
                                    <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                                        {routes.map(route =>
                                            <NavLink key={route.to} to={route.to} end className={({ isActive }) => classNames(
                                                isActive
                                                    ? 'border-purple-500 text-purple-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                            )}>

                                                {({ isActive }) => (
                                                    <Fragment>
                                                        {route.name}
                                                        {route.count !== null && route.count > 0 && (
                                                            <span className={classNames(
                                                                isActive ? 'bg-purple-100 text-purple-600' : 'bg-gray-100 text-gray-900',
                                                                'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                            )}
                                                            >
                                                                {route.count}
                                                            </span>
                                                        )}
                                                    </Fragment>
                                                )}

                                            </NavLink>
                                        )}
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <DraftCompanyProvider draftJoin={draftJoin} dataDraftJoin={dataDraftJoin}>
                            <Routes>
                                <Route index element={<Information />} />
                                <Route path='officer' element={<DetailDraftCompanyBrowseOfficer />} />
                                <Route path='contract' element={<DetailDraftCompanyBrowseContract />} />
                            </Routes>
                        </DraftCompanyProvider>
                    </div>
                </main>
            </div>
            <InviteDraftJoin
                draftJoin={selectedInvite}

                onInvited={() => {
                    dataDraftJoin.refetch()
                    setSelectedInvite(null)
                }}
                onClose={() => setSelectedInvite(null)}
            />
            <PublishDraftJoin
                draftJoin={selectedPublish}
                onInvited={() => {
                    dataDraftJoin.refetch()
                    setSelectedPublish(null)
                }}
                onClose={() => setSelectedPublish(null)}
            />
        </DashboardLayout>
    )
}

const Information = () => {
    const { draftJoin } = useDraftJoinContext()
    return (
        <div className='mx-3 my-10'>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Informasi Dasar</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi yang diisi saat Bergabung</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Nama Perusahaan</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {draftJoin.companyName}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">NIB DUDI</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {draftJoin.companyCode}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">NPWP DUDI</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {draftJoin.companyTaxIdentification}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Bidang DUDI
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {draftJoin.companySector.name}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            {draftJoin.invited && (
                <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Informasi Undangan Audiensi</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi yang diisi saat Mengundang Audiensi</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Jenis Audiensi</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {draftJoin.invited.type}
                                </dd>
                            </div>
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Tanggal</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {moment(draftJoin.invited.date, 'YYYY-MM-DD').format('dddd, D MMM YYYY')}
                                </dd>
                            </div>
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Lokasi</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {draftJoin.invited.location}
                                </dd>
                            </div>
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Kontak
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {draftJoin.invited.contactName} ({draftJoin.invited.contactPhone})
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            )}
        </div>
    )
}