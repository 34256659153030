import { Link, NavLink } from 'react-router-dom'
import { useAuth } from 'App'
import PortalLayout from 'Layout/PortalLayout'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { descriptions } from './content'

export default function AboutUs() {
    const { user, signOut } = useAuth()

    return (
        <PortalLayout backgroundColor="white" navBarTextClassName={{ invert: 'text-indigo-800', base: 'text-gray-600' }}>
            <div className='min-h-screen mx-10 my-32'>
                <div className='grid grid-cols-1 md:grid-cols-2 relative'>
                    <div className='absolute border-t-[6px] w-20 -top-10 border-gray-900'></div>
                    <h1 style={{ fontFamily: 'DINPro', fontSize: '44px', width: '300px' }} className="font-bold">
                        Gambaran Umum Direktorat Mitras DUDI
                    </h1>
                    <span style={{ fontFamily: 'DINPro', fontSize: '21px' }} className="font-medium mt-6 md:mt-0">
                        Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri (Dit. Mitras DUDI) berada dibawah naungan Direktorat Jenderal Pendidikan Vokasi sebagaimana diatur dalam Permendikbud No. 9 Tahun 2020. Pendidikan vokasi adalah salah satu jalur pendidikan yang diharapkan mampu mendorong pencetakan Sumber Daya Manusia (SDM) produktif dan berkualitas yang sesuai dengan perkembangan kebutuhan Dunia Usaha dan Dunia Industri (DUDI). Dit. Mitras DUDI adalah wujud nyata dari komitmen Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi dalam komitmen untuk melakukan sinergi dengan DUDI sebagai pasar bagi lulusan pendidikan vokasi.
                    </span>
                </div>
                <div className='flex w-full mt-32'>
                    <PhotoProvider>
                        <PhotoView src={require('Asset/Content/About-Us/6327e3f2e6b8033f6cf8e25d_Dudi Awards.jpeg')}>
                            <img className='rounded-xl shadow-xl w-full' src={require('Asset/Content/About-Us/6327e3f2e6b8033f6cf8e25d_Dudi Awards.jpeg')} />
                        </PhotoView>
                    </PhotoProvider>
                </div>
                <div className='relative mt-32 md:mt-[360px]'>
                    <div className='absolute border-t-[6px] w-20 -top-10 border-gray-900'></div>
                    <h1 style={{ fontFamily: 'DINPro', fontSize: '44px' }} className="font-bold">
                        Tentang Direktorat Mitras DUDI
                    </h1>
                    <table className='flex md:table mt-10'>
                        <tbody className='space-y-6 md:space-y-0'>
                            {descriptions.map((description, index) => (
                                <tr key={index} className='flex flex-col md:table-row'>
                                    <td className='flex md:table-cell border-0 md:border-b border-gray-800 py-3'>
                                        <h2 style={{ fontFamily: 'DINPro', color: '#30789e' }} className="font-semibold text-[18px] md:text-[36px]">
                                            {description.title}
                                        </h2>
                                    </td>
                                    <td className='flex md:table-cell px-0 md:px-10 border-b border-gray-800 font-medium py-3' style={{ fontFamily: 'DINPro' }}>
                                        {description.content}
                                    </td>
                                    <td className='hidden md:table-cell p-5' style={{ maxWidth: '820px' }}>
                                        <PhotoProvider>
                                            <PhotoView src={description.imageSource}>
                                                <img className='rounded-xl shadow-xl' src={description.imageSource} />
                                            </PhotoView>
                                        </PhotoProvider>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </PortalLayout>
    )
}