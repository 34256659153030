import { useState, useRef, Fragment } from "react";
import { Tab } from '@headlessui/react'
import SignaturePad from "react-signature-canvas";

import moment from "moment";
import classNames from "classnames";
import { FingerPrintIcon, UploadIcon } from "@heroicons/react/outline";
import { useAssignContractSigning } from "Data/Contract";
import { transformResponseToSingle } from "../../../Utility/transformer";


const MAX_SIGNATURE_IMAGE_WIDTH = 424
const MAX_SIGNATURE_IMAGE_HEIGHT = 201;

export default function SignatureInput({ signer, order, contract, onSuccess }) {
    const assignContractSigning = useAssignContractSigning({
        onSuccess: (response) => {
            const signingContract = transformResponseToSingle(response)
            onSuccess(signingContract)
            clear()
        }
    })
    const [imageURL, setImageURL] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const signatureCanvas = useRef({});

    const clear = () => {
        setImageURL(null);
        setImageFile(null);
        signatureCanvas.current.clear();
    };

    const save = () => {
        signatureCanvas.current.getTrimmedCanvas().height = 100;
        signatureCanvas.current.getTrimmedCanvas().width = 100;

        const imageElement = document.createElement("img");
        imageElement.src = signatureCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png");

        imageElement.onload = function (event) {
            const canvas = document.createElement("canvas");
            canvas.width = MAX_SIGNATURE_IMAGE_WIDTH;
            canvas.height = MAX_SIGNATURE_IMAGE_HEIGHT;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height);

            const srcEncoded = ctx.canvas.toDataURL(event.target, "image/png");

            // Convert DATA URL to BLOB File
            ctx.canvas.toBlob((blob) => {
                let file = new File(
                    [blob],
                    `${moment().format("YYYY/MM/DD")}/signer=${signer.ID}.png`,
                    { type: "image/png" }
                );
                setImageFile(file);
            }, "image/png");
            // End Convert
            setImageURL(srcEncoded);
        };
    };



    const createImageCanvas = (file) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = function (event) {
            const imageElement = document.createElement("img");
            imageElement.src = event.target.result;

            // load in image id  = output
            imageElement.onload = function (e) {
                const canvas = document.createElement("canvas");
                canvas.width = MAX_SIGNATURE_IMAGE_WIDTH;
                canvas.height = MAX_SIGNATURE_IMAGE_HEIGHT;

                const ctx = canvas.getContext("2d");
                ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

                const srcEncoded = ctx.canvas.toDataURL(e.target, "image/png");

                // Convert DATA URL to BLOB File
                ctx.canvas.toBlob((blob) => {
                    let file = new File(
                        [blob],
                        `${moment().format("YYYY/MM/DD")}/signer=${signer.ID}.png`,
                        { type: "image/png" }
                    );
                    setImageFile(file);
                }, "image/png");
                // End Convert

                document.querySelector("#output").src = srcEncoded;
            };
        };
    };

    const processImage = () => {
        const file = document.querySelector("#signatureFile").files[0];

        if (!file) return;
        createImageCanvas(file);
    };

    const generateLink = async () => {
        await navigator.clipboard.writeText(
            `${window.location.origin}/assign?token=${signer.token}`
        )
    }

    const submit = (useExistingSignature = false) => {
        assignContractSigning.mutate({ ID: contract.ID, order, signingImage: useExistingSignature ? null : imageFile, useExistingSignature })
    }

    return (
        <div>
            <Tab.Group>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Pilih Metode
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        defaultValue={1}>
                        <option key={1}>Gambar</option>
                        <option key={2}>Unggah</option>
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <Tab.List as="nav" className="-mb-px flex space-x-8" aria-label="Tabs">
                            <Tab as="a" className={({ selected }) => classNames(
                                selected
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                            )}>
                                <FingerPrintIcon
                                    className={classNames(
                                        'text-gray-400 group-hover:text-gray-500',
                                        '-ml-0.5 mr-2 h-5 w-5'
                                    )}
                                    aria-hidden="true"
                                />
                                Gambar
                            </Tab>
                            <Tab as="a" className={({ selected }) => classNames(
                                selected
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                            )}>
                                <UploadIcon
                                    className={classNames(
                                        'text-gray-400 group-hover:text-gray-500',
                                        '-ml-0.5 mr-2 h-5 w-5'
                                    )}
                                    aria-hidden="true"
                                />
                                Unggah
                            </Tab>
                        </Tab.List>
                        <div className="flex justify-end gap-2">
                            {signer.companySigner.signing && (
                                <button
                                    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2  bg-indigo-600 hover:bg-indigo-500 text-indigo-100 focus:ring-indigo-600"
                                    onClick={() => submit(true)}
                                >
                                    <FingerPrintIcon className="h-3 w-3 mr-1" />
                                    {assignContractSigning.isLoading ? 'Memproses...' : 'Setujui Dijital'}
                                </button>
                            )}
                            <button
                                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2  bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                                onClick={() => submit()}
                            >
                                {assignContractSigning.isLoading ? 'Memproses...' : 'Simpan Tanda Tangan'}
                            </button>

                            <button
                                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded   focus:outline-none focus:ring-2 focus:ring-offset-2  bg-indigo-100 hover:bg-indigo-200 text-indigo-700 focus:ring-indigo-500"
                                onClick={clear}
                            >
                                Ulangi
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-3">
                    <Tab.Panels>
                        <Tab.Panel>
                            <canvas width="623" style={{ width: '100%', minHeight: 1, touchAction: 'none' }} height="1"></canvas>
                            <div className="flex w-full flex-col" >
                                <SignaturePad
                                    onEnd={() => save()}
                                    ref={signatureCanvas}
                                    canvasProps={{
                                        className: "signatureCanvas ",
                                        style: {
                                            border: "1px dashed grey",
                                            width: "100%",
                                            minHeight: 200,
                                        },
                                    }}
                                />

                                <h4 className="text-center ">Pratinjau hasil tanda tangan</h4>
                                <div
                                    className="w-full bg-gray-100 rounded-md p-2"
                                    style={{
                                        height: imageURL ? "auto" : MAX_SIGNATURE_IMAGE_HEIGHT,
                                    }}
                                >
                                    {imageURL ? (
                                        <div className="p-2" style={{
                                            border: "1px dashed grey",
                                            backgroundColor: "#fff",
                                        }}>
                                            <img
                                                src={imageURL}
                                                width={MAX_SIGNATURE_IMAGE_HEIGHT}
                                                height={MAX_SIGNATURE_IMAGE_WIDTH}
                                                alt="my signature"
                                                style={{
                                                    display: "block",
                                                    margin: "0 auto",
                                                    width: MAX_SIGNATURE_IMAGE_WIDTH,
                                                    height: MAX_SIGNATURE_IMAGE_HEIGHT,
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <p style={{ textAlign: "center", color: "grey" }}>
                                            Hasil Tanda Tangan di sini
                                        </p>
                                    )}
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <canvas width="623" style={{ width: '100%', minHeight: 1, touchAction: 'none' }} height="1"></canvas>
                            <div className="flex w-full flex-col" >
                                <div
                                    onDragOver={(event) => event.preventDefault()}
                                    onDrop={(event) => {
                                        let fileInput = document.getElementById(`signatureFile`);

                                        // pretty simple -- but not for IE :(
                                        fileInput.files = event.dataTransfer.files;

                                        const dT = new DataTransfer();
                                        dT.items.add(event.dataTransfer.files[0]);
                                        fileInput.files = dT.files;

                                        setImageFile(event.dataTransfer.files[0]);
                                        createImageCanvas(event.dataTransfer.files[0]);

                                        event.preventDefault();
                                    }}
                                    className="w-full flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" style={{ height: 224 }}>
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                                <span>Upload a file</span>
                                                <input type="file"
                                                    id="signatureFile"
                                                    onChange={({ target: { files } }) => {
                                                        setImageFile(files[0]);
                                                        processImage();
                                                    }} className="sr-only" />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>

                                <h4 className="text-center ">Pratinjau hasil tanda tangan</h4>

                                <div className="bg-gray-100 p-2" style={{ height: 221 }}>
                                    <div className="bg-white w-full border-2 border-gray-300 border-dashed rounded-md flex items-center justify-center" >
                                        <img id="output" className="" />
                                    </div>
                                </div>
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </div>
            </Tab.Group>
        </div>
    )
}