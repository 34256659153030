import { Fragment } from "react";
import { useDraftJoinContext } from "../DetailDraftCompany";
import { useDataContracts } from "Data/Contract";
import { transformResponseToList } from "../../../Utility/transformer";
import { SwitchVerticalIcon } from "@heroicons/react/outline";
import { ProgressVertical } from "../../../Component";

export default function DetailDraftCompanyBrowseContract() {
    const { draftJoin } = useDraftJoinContext()
    const dataContracts = useDataContracts({ companyID: draftJoin.published?.companyID, expands: ['contractSigners'] })
    const contracts = draftJoin.published ? transformResponseToList(dataContracts.data) : []
    return (
        <Fragment>
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Pihak
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Judul
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Progress
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {contracts.map(contract => {
                                        const firstSigner = contract.contractSigners.find(contractSigner => contractSigner.order == 1)
                                        const secondSigner = contract.contractSigners.find(contractSigner => contractSigner.order == 2)

                                        return (
                                            <tr key={contract.ID}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {firstSigner && (
                                                        <div className="flex items-center">
                                                            <div className="flex-shrink-0 h-10 w-10">
                                                                <img className="h-10 w-10 rounded-full" src={firstSigner.companySigner.company.urlLogo} alt="" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <div className="text-sm font-medium text-gray-900">{firstSigner.companySigner.company.name}</div>
                                                                <div className="text-sm text-gray-900">{firstSigner.companySigner.officerName}</div>
                                                                <div className="text-sm text-gray-500">{firstSigner.companySigner.officerEmail}</div>
                                                            </div>

                                                        </div>
                                                    )}


                                                    {secondSigner && (
                                                        <Fragment>
                                                            {firstSigner && (
                                                                <div className="flex items-center">
                                                                    <SwitchVerticalIcon className="ml-3 my-3 h-4 w-4 text-green-400" />
                                                                </div>
                                                            )}
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 h-10 w-10">
                                                                    <img className="h-10 w-10 rounded-full" src={secondSigner.companySigner.company.urlLogo} alt="" />
                                                                </div>
                                                                <div className="ml-4">
                                                                    <div className="text-sm font-medium text-gray-900">{secondSigner.companySigner.company.name}</div>
                                                                    <div className="text-sm text-gray-900">{secondSigner.companySigner.officerName}</div>
                                                                    <div className="text-sm text-gray-500">{secondSigner.companySigner.officerEmail}</div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )}

                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-md text-gray-900">{contract.name}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <ProgressVertical
                                                        items={[
                                                            { name: 'Pembuatan Naskah', to: `/dashboard/contract/${contract.ID}/update`, description: 'Pembuatan Naskah dan informasi dasar' },
                                                            { name: 'Penunjukan', to: `/dashboard/contract/${contract.ID}/update/assign-company`, description: 'Penunjukan Pihak-1 dan Pihak-2' },
                                                            { name: 'Konstruksi Pasal', to: `/dashboard/contract/${contract.ID}/update/assign-clause`, description: 'Proses konstruksi pasal dari kedua pihak' },
                                                            { name: 'Tanda Tangan', to: `/dashboard/contract/${contract.ID}/update/assign-signature`, description: 'Tanda tangan kedua belah pihak' },
                                                            { name: 'Nomor Akhir', to: `/dashboard/contract/${contract.ID}/update/assign-number`, description: 'Penentuan Nomor Akhir dari Kontrak' },
                                                        ]}
                                                        currentStep={contract.currentPhase}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}