import ContractLayout from '../ContractLayout';
import { Fragment } from 'react'
import { ArrowLeftIcon, BackspaceIcon, CalendarIcon, ClockIcon } from "@heroicons/react/outline";
import moment from 'moment';
import { Progress } from '../../Component';
import { Link } from 'react-router-dom';

export default function ContractEditorLayout({ contract = null, appendBreadcrumbs, children }) {
    const created = contract ? moment(contract.created, 'YYYY-MM-DD HH:mm:ss') : moment()
    const updated = contract ? moment(contract.updated, 'YYYY-MM-DD HH:mm:ss') : null

    return (
        <ContractLayout appendBreadcrumbs={appendBreadcrumbs} className="">
            <div className="py-10">
                <header></header>
                <main>
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6 relative">
                                <div className='absolute right-6'>
                                    {contract && (
                                        <Link
                                            to={`/dashboard/contract/${contract.ID}`}
                                            className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                                        >
                                            <ArrowLeftIcon className='w-4 h-4' />
                                            <span className="hidden md:block ml-4">Balik ke Detail</span>
                                        </Link>
                                    )}

                                </div>
                                <div className="mb-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Naskah Kerjasama{contract ? `: ${contract.name}` : ''}</h3>

                                    <div className='flex flex-col gap-1 md:flex-row mt-1 text-xs'>
                                        <div className='flex flex-row'>
                                            <span className="hidden md:block">Dibuat pada</span>
                                            <time className="mx-2 flex flex-row items-center" dateTime={created.format('YYYY-MM-DD HH:mm:ss')}>
                                                <CalendarIcon className="w-4 h-4 mr-1" /> {created.format('D MMMM, YY')}
                                                <ClockIcon className="w-4 h-4 mr-1 ml-2" /> {created.format('HH:mm')}
                                            </time>
                                        </div>

                                        {
                                            updated && (
                                                <div className='flex flex-row'>
                                                    <span className="hidden md:block">Diubah terakhir pada</span>
                                                    <time className="ml-2 flex flex-row items-center" dateTime={updated.format('YYYY-MM-DD HH:mm:ss')}>
                                                        <CalendarIcon className="w-4 h-4 mr-1" /> {updated.format('D MMMM, YY')}
                                                        <ClockIcon className="w-4 h-4 mr-1 ml-2" /> {updated.format('HH:mm')}
                                                    </time>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <Progress
                                    currentStep={contract?.currentPhase}
                                    items={[
                                        { name: 'Informasi Dasar', to: contract ? `/dashboard/contract/${contract.ID}/update` : '/dashboard/contract/new' },
                                        { name: 'Penunjukan', to: `/dashboard/contract/${contract?.ID}/update/assign-company`, disabled: contract === null },
                                        { name: 'Konstruksi Pasal', to: `/dashboard/contract/${contract?.ID}/update/assign-clause`, disabled: contract === null },
                                        { name: 'Validasi', to: `/dashboard/contract/${contract?.ID}/update/validation`, disabled: contract === null },
                                        { name: 'Tanda Tangan', to: `/dashboard/contract/${contract?.ID}/update/assign-signature`, disabled: contract === null },
                                        { name: 'Nomor Akhir', to: `/dashboard/contract/${contract?.ID}/update/assign-number`, disabled: contract === null },
                                    ]}
                                />
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                {children}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </ContractLayout>
    )
}
