import { useDataContracts, ContractPhases } from "Data/Contract"
import { transformResponseToList, transformResponseToPagination } from "../../../Utility/transformer"
import TableLoader from "./TableLoader"
import { PencilAltIcon } from "@heroicons/react/outline"
import { useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import ContractLayout from "Layout/ContractLayout"
import { Pagination } from '../../../Component'
import { authenticationProvider } from "Provider/Authentication"


export default function BrowseContract() {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page')
    const show = searchParams.get('show')

    const dataContracts = useDataContracts({ expands: ['cooperationDirector', 'contractSigners'], currentPage: searchParams.get('page'), pageSize: show })
    const contracts = transformResponseToList(dataContracts.data)
    const pagination = transformResponseToPagination(dataContracts.data)

    useEffect(() => {
        dataContracts.refetch()
    }, [page, show])

    return (
        <ContractLayout>
            <div className='p-10'>
                <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">Naskah</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    Daftar naskah yang terdaftar dalam sistem.
                                </p>
                            </div>
                            {authenticationProvider.isContractOperator() && (
                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    <Link
                                        to="/dashboard/contract/new"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                    >
                                        Buat naskah Baru
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Nama Naskah
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                        >
                                            Pihak 1
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                        >
                                            Pihak 2
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Status
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Aksi</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {dataContracts.isLoading ? <TableLoader /> :
                                        contracts.map((contract) => {
                                            const signerFirst = contract.contractSigners.find(contractSigner => contractSigner.order == 1)
                                            const signerSecond = contract.contractSigners.find(contractSigner => contractSigner.order == 2)

                                            return (
                                                <tr key={contract.ID}>
                                                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                        <Link to={`/dashboard/contract/${contract.ID}`}>
                                                            <div className='font-extralight'>
                                                                {contract.typeLabel}
                                                            </div>
                                                            {contract.name}

                                                            <dl className="font-normal lg:hidden">
                                                                <dt className="sr-only">Pihak 1</dt>
                                                                <dd className="mt-1 truncate text-gray-700">
                                                                    {
                                                                        signerFirst && (
                                                                            <Company company={signerFirst.companySigner.company} />
                                                                        )
                                                                    }
                                                                </dd>
                                                                <dt className="sr-only sm:hidden">Pihak 2</dt>
                                                                <dd className="truncate text-gray-500 sm:hidden">
                                                                    {
                                                                        signerSecond && (
                                                                            <Company company={signerSecond.companySigner.company} />
                                                                        )
                                                                    }
                                                                </dd>
                                                            </dl>
                                                        </Link>
                                                    </td>
                                                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                                        {
                                                            signerFirst && (
                                                                <Company company={signerFirst.companySigner.company} />
                                                            )
                                                        }
                                                    </td>
                                                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                        {
                                                            signerSecond && (
                                                                <Company company={signerSecond.companySigner.company} />
                                                            )
                                                        }
                                                    </td>
                                                    <td className="px-3 py-4 text-sm text-gray-500">
                                                        <Progress contract={contract} />
                                                    </td>
                                                    <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <Link to={`/dashboard/contract/${contract.ID}/update`}>
                                                            <span className='text-indigo-600 hover:text-indigo-900 hidden md:block'>
                                                                Ubah
                                                            </span>
                                                            <div className='bg-indigo-600 rounded-full p-2 flex justify-center items-center md:hidden'>
                                                                <PencilAltIcon className='w-4 h-4 text-white ' />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>
                            <Pagination pagination={pagination} onPaginationChange={({ page, show }) => setSearchParams({ page, show })} />
                        </div>
                    </div>
                </div>
            </div>
        </ContractLayout>
    )
}

function Company({ company }) {
    return (
        <div className="flex items-center">
            <div className='hidden lg:block'>
                <div className="h-10 w-10 flex-shrink-0 mr-4">
                    <img className="h-10 w-10 rounded-full" src={company.urlLogo} alt="" />
                </div>
            </div>
            <div className="">
                <div className="font-light md:font-medium text-gray-900 ">{company.name}</div>
                <div className="text-gray-500 hidden lg:block">{company.companySector.name}</div>
            </div>
        </div>
    )
}

function Progress({ contract }) {
    return (
        <nav className="flex items-center justify-center" aria-label="Progress">
            <p className="text-sm font-medium">
                Tahapan {contract.currentPhase} dari {ContractPhases.length}
            </p>
            <ol role="list" className="ml-8 items-center space-x-5 hidden xl:flex">
                {ContractPhases.map((phase, phaseIndex) => (
                    <li key={phase.name}>
                        {
                            phaseIndex < contract.currentPhase ?
                                <a href={phase.contructUrl(contract)} className="block w-2.5 h-2.5 bg-indigo-600 rounded-full hover:bg-indigo-900">
                                    <span className="sr-only">{phase.name}</span>
                                </a> :
                                phaseIndex === contract.currentPhase ?
                                    <a href={phase.contructUrl(contract)} className="relative flex items-center justify-center" aria-current="step">
                                        <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                                            <span className="w-full h-full rounded-full bg-indigo-200" />
                                        </span>
                                        <span className="relative block w-2.5 h-2.5 bg-indigo-600 rounded-full" aria-hidden="true" />
                                        <span className="sr-only">{phase.name}</span>
                                    </a> :
                                    <a href={phase.contructUrl(contract)} className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400">
                                        <span className="sr-only">{phase.name}</span>
                                    </a>
                        }
                    </li>
                ))}
            </ol>
        </nav>
    )
}
