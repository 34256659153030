export const sponsors = [
    { url: require('Asset/Content/Home/6322b067bdbb2cab00db39a2_Kadin.png') },
    { url: require('Asset/Content/Home/6322af04299e7f04861b6bde_logo.png') },
    { url: require('Asset/Content/Home/6322afa3df6ee21b8a806371_BUMN.png') },
    { url: require('Asset/Content/Home/6322af03299e7f70f71b6bdd_FHCI.png') },
    { url: require('Asset/Content/Home/6322af037142b285a6cc44e8_HKI.png') },
]

export const benefits = [
    {
        title: <h1 style={{ color: '#30789e' }} className="font-semibold text-xl"><span className='text-2xl font-bold'>Garansi Talenta</span> berkualitas</h1>,
        description: 'DUDI akan mendapatkan SDM Vokasi yang siap kerja karena mereka sudah melalui proses pendidikan dan pelatihan yang memenuhi standar industri.',
        icon: <img className='h-32 mb-3' src={require('Asset/Content/Home/6322b25604777c1ab9fbc4c3_guarantee.png')} />
    },
    {
        title: <h1 style={{ color: '#30789e' }} className="font-semibold text-xl"><span className='text-2xl font-bold'>Efisiensi </span> biaya pelatihan</h1>,
        description: 'Melalui kerja sama dengan satuan pendidikan vokasi, DUDI hanya mengeluarkan biaya yg lebih ringan karena tidak perlu mendirikan lembaga pelatihan yang baru.',
        icon: <img className='h-32 mb-3' src={require('Asset/Content/Home/632156c8b30702268dc80449_reduce-cost.png')} />
    },
    {
        title: <h1 style={{ color: '#30789e' }} className="font-semibold text-xl">Mendukung <span className='text-2xl font-bold italic'>supply chain</span></h1>,
        description: <span>Satuan Pendidikan Vokasi siap menjadi <span className='italic'>supply chain</span> dalam menyediakan calon tenaga kerja dengan berbagai macam jenis keahlian sesuai kebutuhan DUDI.</span>,
        icon: <img className='h-32 mb-3' src={require('Asset/Content/Home/632156c79298aa692a1bc7f4_supply-chain.png')} />
    },
    {
        title: <h1 style={{ color: '#30789e' }} className="font-semibold text-xl">Insentif <span className='text-2xl font-bold italic'>Super Tax Deduction </span> (<span className='italic'>StD</span>)</h1>,
        description: <span>DUDI akan mendapatkan keringanan membayar pajak dalam skema <span className='italic'>Super Tax Deduction</span> (<span className='italic'>STD</span>) sesuai Peraturan Kementerian Keuangan (Permenkeu).</span>,
        icon: <img className='h-32 mb-3' src={require('Asset/Content/Home/632156c7fa4234cbc4a0a4a9_taxes.png')} />
    },
    {
        title: <h1 style={{ color: '#30789e' }} className="font-semibold text-xl"><span className='text-2xl font-bold'>Memenuhi </span> kebutuhan SDM Industri</h1>,
        description: <span>Melalui kerja sama ini kebutuhan industri dapat terpenuhi, baik dalam skema <span className="italic">Talent Pool</span>, Lini Produksi, maupun <span className='italic'>Corporate Social Responsibility</span> (<span className='italic'>CSR</span>).</span>,
        icon: <img className='h-32 mb-3' src={require('Asset/Content/Home/6322b2eefc66995bd48b5caf_industry.png')} />
    },
]


export const collaborations = [
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218ca06f7379dd20d32f16_curiculum.png')} />,
        description: "Penyusunan dan Penyelarasan Kurikulum",
    },
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218ca06b18c74247543a0b_PBL.png')} />,
        description: <span className='italic'>Project Based Learning/PjBL</span>,
    },
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218ca0dbc9545873acfe6a_teacher.png')} />,
        description: "Penyediaan guru tamu dari industri",
    },
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218ca07ccb9912a600efc6_magang.png')} />,
        description: "Praktik Kerja Lapangan (PKL)",
    },
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218ca05eab7456bfc3a04a_certificate.png')} />,
        description: "Sertifikasi kompetensi berstandar industri bagi lulusan dan pendidik",
    },
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218c9f0dc0fb85e5e08979_pelatihan guru.png')} />,
        description: "Pelatihan guru/dosen/instruktur dari industri",
    },
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218ca0c5fa8cdc4b08a2a2_tefa.png')} />,
        description: <span>Pengembangan <span className='italic'>teaching factory</span> melalui riset terapan</span>,
    },
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218ca02cd2b38704265cbd_Shake hand.png')} />,
        description: "Penyerapan lulusan pendidikan vokasi",
    },
    {
        icon: <img className='h-12 mb-3' src={require('Asset/Content/Home/63218ca02ab71d82194957ce_scholarship.png')} />,
        description: "Kolaborasi lainnya sesuai dengan kebutuhan industri",
        className: 'col-span-1 md:col-span-2 '
    },
]

export const testimonis = [
    {
        person: {
            avatar: <img className='inline-block h-14 w-14 rounded-full' src={require('Asset/Content/Home/63328185bf5aae1b62680f17_Talent Growth.png')} />,
            name: 'Darling Febriani (Talent Growth)',
            position: 'CEO & CO-Founder',
        },
        quota: 'Sungguh sebuah milestone dan juga kebanggan dapat berkolaborasi dengan MitrasDUDI satuan Vokasi di Indonesia. Melalui kolaborasi ini besar harapan kami dapat semakin banyak mencetak talenta-talenta sesuai dengan kebutuhan industri darisegi skill maupun attitude'
    },
    {
        person: {
            avatar: <img className='inline-block h-14 w-14 rounded-full' src={require('Asset/Content/Home/6320505c47fbafe5de21619d_Erajaya.png')} />,
            name: 'Jimmy Perangin A. (Chief of Human Capital, GA, Legal & CSR)',
            position: 'Erajaya Group',
        },
        quota: 'Kami berharap kompetensi guru dan murid nantinya akan semakin berkembang juga semakin sesuai dengantuntutan dunia kerja, yang ujungnya nantinya lulusannya daripada SMK ini bisa masuk bekerja ke bidang-bidang yang saat ini dikerjakan oleh Erajaya'
    },
    {
        person: {
            avatar:<span className="inline-flex items-center justify-center h-14 w-14 rounded-full bg-gray-500"><span className="text-xl font-medium leading-none text-white">M</span></span>,
            name: 'Matthew (PT Borine Technology Indonesia)',
            position: 'HRD Manager',
        },
        quota: 'Saya berharap dari melalui MoU ini ada sinergi yang lebih efektif dan lebih efisien bagi dunia usaha dan juga dunia pendidikan kita supaya anak-anak generasi kita ini dapat bekerja dengan efektif dan efisien juga di dalam dunia kerja'
    },
    {
        person: {
            avatar: <img className='inline-block h-14 w-14 rounded-full' src={require('Asset/Content/Home/63328282f5e8fb0aff63d544_CP Bahari.jpg')} />,
            name: 'Roni Kausyar (Central Pertiwi Bahari)',
            position: 'Head of HR',
        },
        quota: 'Kami mengharapkan terbentuknya kerjasama yang baik dan saling menguntungkan antara dunia usaha dengan pemerintah, sehingga dapat menunjang keberlangsungan usaha kedepannya'
    },
]