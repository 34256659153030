import { useMutation, useQuery } from "react-query"
import { requestPost, requestGet } from "Provider/RestClient"

export function useMutationLogin({ onSuccess }) {
    const mutation = useMutation(({ id, password }) => requestPost('/access/login', { username: id, password }), {
        onSuccess: (response) => onSuccess(response),
    })
    return mutation
}


export function useLoggedUser({ onSuccess, onError,  enabled = true }) {
    const data = useQuery('/access/account', () => requestGet('/access/account'), {
        enabled: enabled,
        onError: (error) => onError ? onError(error) : null,
        onSuccess: (response) => onSuccess(response),
    })
    return data
}