import DashboardLayout from "Layout/DashboardLayout";
import { ArrowSmUpIcon } from '@heroicons/react/solid'
import { BadgeCheckIcon, LibraryIcon } from '@heroicons/react/outline'
import classNames from "classnames";
import { useDataCompanies } from "Data/Company";
import { useDataDraftJoins } from "Data/DraftJoin";
import { transformResponseToList, transformResponseToPagination } from "../../../Utility/transformer";
import { Link } from "react-router-dom";
import moment from "moment";

export default function BrowseCompanyPublished() {
    const dataPublishedCompanies = useDataCompanies({ published: true })
    const dataNonPublishedCompanies = useDataCompanies({ published: false })
    const dataDraftJoins = useDataDraftJoins({})
    const companies = transformResponseToList(dataPublishedCompanies.data)
    const dataPublishedCompaniesPagination = transformResponseToPagination(dataPublishedCompanies.data)
    const dataNonPublishedCompaniesPagination = transformResponseToPagination(dataNonPublishedCompanies.data)
    const dataDraftJoinsPagination = transformResponseToPagination(dataDraftJoins.data)
    return (
        <DashboardLayout>
            <div className="container mx-auto sm:px-6 lg:px-8 mt-20">
                <div>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        <div className="relative bg-indigo-500 pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                            <dt>
                                <div className="absolute bg-white rounded-md p-3">
                                    <BadgeCheckIcon className="h-6 w-6 text-indigo-500" aria-hidden="true" />
                                </div>
                                <p className="ml-16 text-sm font-medium text-white truncate">
                                    Total DUDI terverifikasi
                                </p>
                            </dt>
                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                <p className="text-2xl font-semibold text-white">
                                    {dataPublishedCompaniesPagination.itemCount}
                                </p>
                                <p
                                    className={classNames(
                                        'text-green-600 hidden',
                                        'ml-2 flex items-baseline text-sm font-semibold'
                                    )}
                                >
                                    <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />

                                    <span className="sr-only">Bertambah sebanyak</span>
                                    2
                                </p>
                                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                        <span className="font-medium text-gray-600 hover:text-gray-500">
                                            {' '}
                                            Liat semua<span className="sr-only">Total Instansi terverifikasi</span>
                                        </span>
                                    </div>
                                </div>
                            </dd>
                        </div>
                        <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                            <dt>
                                <div className="absolute bg-indigo-500 rounded-md p-3">
                                    <LibraryIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                                    Total DUDI mendaftar
                                </p>
                            </dt>
                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">
                                    {dataDraftJoinsPagination.itemCount}
                                </p>
                                <p
                                    className={classNames(
                                        'text-green-600 hidden',
                                        'ml-2 flex items-baseline text-sm font-semibold'
                                    )}
                                >
                                    <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />

                                    <span className="sr-only">Bertambah sebanyak</span>
                                    2
                                </p>
                                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                        <Link to="/dashboard/company/draft" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            {' '}
                                            Liat semua<span className="sr-only">Total Instansi terverifikasi</span>
                                        </Link>
                                    </div>
                                </div>
                            </dd>
                        </div>
                        <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                            <dt>
                                <div className="absolute bg-indigo-500 rounded-md p-3">
                                    <LibraryIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                                    Total non-DUDI
                                </p>
                            </dt>
                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">
                                    {dataNonPublishedCompaniesPagination.itemCount}
                                </p>
                                <p
                                    className={classNames(
                                        'text-green-600 hidden',
                                        'ml-2 flex items-baseline text-sm font-semibold'
                                    )}
                                >
                                    <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />

                                    <span className="sr-only">Bertambah sebanyak</span>
                                    2
                                </p>
                                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                        <Link to="/dashboard/company/non" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            {' '}
                                            Liat semua<span className="sr-only">Total Instansi terverifikasi</span>
                                        </Link>
                                    </div>
                                </div>
                            </dd>
                        </div>
                    </dl>
                </div>

                <div className="flex flex-col mt-10">
                    <h1 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Daftar DUDI Terverifikasi</h1>
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-2">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Title
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Tipe
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Status
                                            </th>
                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {companies.map((company) => (
                                            <tr key={company.ID}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-10 w-10">
                                                            <img className="h-10 w-10 rounded-full" src={company.urlLogo} alt="" />
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">{company.name}</div>
                                                            <div className="text-sm text-gray-500">{company.companySector.name}</div>
                                                            <div className="text-sm text-gray-500">{company.country}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">{company.created}</div>
                                                    <div className="text-xs text-gray-900">{moment(company.created, 'YYYY-MM-DD HH:mm:ss').fromNow()}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">{company.typeLabel}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        Active
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                                        <Link
                                                            to={`/dashboard/company/${company.ID}`}
                                                            className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                        >
                                                            Lihat
                                                        </Link>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}