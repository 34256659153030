import { useQuery, useMutation } from "react-query"
import { requestGet, requestPost, requestDelete } from "Provider/RestClient"
import queryString from 'query-string'

export function useDataContract(ID, { expands, onFetched = () => null }) {
    const path = `/contract/contract/${ID}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onFetched })
}

export function useDataContracts({ sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, companyID, enabled = true }) {
    const path = `/contract/contract`
    const key = `${path}?${queryString.stringify(sort, order, pageSize, currentPage, companyID)}`
    return useQuery(key, () => requestGet(path, { sort, order, pageSize, currentPage:currentPage || 1, expand: expands.join(','), companyID }), { enabled })
}

export function useDataContractClassifications({ sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, companyID, enabled = true }) {
    const path = '/contract/contract-classification'
    return useQuery(path, () => requestGet(path, { sort, order, pageSize, currentPage, expand: expands.join(','), companyID }), { enabled })
}

export function useCreateContract({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ type, contractStart, contractEnd, name, location, classificationIDs }) => requestPost('/contract/contract', { type, contractStart, contractEnd, name, location, classificationIDs }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useUpdateContract({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, type, contractStart, contractEnd, name, location, classificationIDs }) => requestPost(`/contract/contract/${ID}`, { type, contractStart, contractEnd, name, location, classificationIDs }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useAssignContractSigner({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, order, companySignerID }) => requestPost(`/contract/contract/${ID}/signer/${order}`, { companySignerID }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useRemoveContractSigner({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, order }) => requestDelete(`/contract/contract/${ID}/signer/${order}`), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useAssignContractSigning({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, order, signingImage, useExistingSignature }) => requestPost(`/contract/contract/${ID}/signer/${order}/signature`, { signingImage, useExistingSignature }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useAssignContractNumber({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, order, number }) => requestPost(`/contract/contract/${ID}/signer/${order}/number`, { number }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useAssignContractClauseGroups({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, clauseGroups }) => requestPost(`/contract/contract/${ID}/clause-groups`, { clauseGroups }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useAssignContractReview({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, order, type, note, validationInvitationNumber }) => requestPost(`/contract/contract/${ID}/signer/${order}/review`, { type, note, validationInvitationNumber }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useDataExportContracts(ID, { onFetched = () => null }) {
    const path = `/contract/contract/${ID}/export`
    return useQuery(path, () => requestGet(path, {}), { onSuccess: onFetched })
}

export function useExportContract({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID }) => requestPost(`/contract/contract/${ID}/export`), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}

export function useValidateContract({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, by, type, note }) => requestPost(`/contract/contract/${ID}/validation`, { by, type, note }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}


export const ContractTypes = [
    { id: 1, title: 'MoU', description: 'Memorandum of Understanding atau Nota Kesepahaman' },
    { id: 2, title: 'PKS', description: 'Perjanjian Kerja Sama', },
]

export const ContractClassifications = [
    { id: 1, title: 'PTVP' },
    { id: 2, title: 'SMK' },
    { id: 3, title: 'Suslat' },
    { id: 4, title: 'TU', disabled: true },
]

export const ContractValidationBys = [
    { id: 1, title: 'Koordinator HTK' },
    { id: 2, title: 'Koordinator Kerja Sama dan Humas' },
    { id: 3, title: 'Dirjen Diksi' },
    { id: 4, title: 'Sekretaris Ditjen Diksi' },
]

export const ContractPhases = [
    { name: 'Informasi Dasar', contructUrl: (contract, baseURL = '/dashboard') => `${baseURL}/contract/${contract.ID}/update` },
    { name: 'Penunjukan', contructUrl: (contract, baseURL = '/dashboard') => `${baseURL}/contract/${contract.ID}/update/assign-company` },
    { name: 'Konstruksi Pasal', contructUrl: (contract, baseURL = '/dashboard') => `${baseURL}/contract/${contract.ID}/update/assign-clause` },
    { name: 'Validasi', contructUrl: (contract, baseURL = '/dashboard') => `${baseURL}/contract/${contract.ID}/update/validation` },
    { name: 'Tanda Tangan', contructUrl: (contract, baseURL = '/dashboard') => `${baseURL}/contract/${contract.ID}/update/assign-signature` },
    { name: 'Nomor Akhir', contructUrl: (contract, baseURL = '/dashboard') => `${baseURL}/contract/${contract.ID}/update/assign-number` },
]