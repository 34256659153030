import { useMutation } from "react-query"
import { requestPost } from "Provider/RestClient"

export function useSubmitContactUs({ onSuccess = () => null, onError  = () => null}) {
    const mutation = useMutation(({ name, phone, email, subject, message }) => requestPost('/support/contact-us', { name, phone, email, subject, message }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError(error)
    })
    return mutation
}
