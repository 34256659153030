import { createContext, Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
    CalendarIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    LinkIcon,
    LocationMarkerIcon,
    PencilIcon,
} from '@heroicons/react/solid'
import DashboardLayout from 'Layout/DashboardLayout'
import { Link, NavLink, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import DashboardHomeForCompanyBrowseOfficer from '../DashboardHomeForCompanyBrowseOfficer'
import { useDataContracts } from 'Data/Contract'
import { useCompanyContext } from '../../Company/CompanyDetail'
import { onlineManager } from 'react-query'


export default function DashboardHomeForCompany() {
    const navigate = useNavigate()
    const location = useLocation()
    const { company } = useCompanyContext()
    const companyID = company.ID
    const dataContracts = useDataContracts({ companyID: companyID, enabled: false, expands: ['contractSigners'] })

    const routes = [
        { name: 'Informasi', to: `/dashboard`, count: null },
        { name: 'Daftar Penanggung Jawab', to: `/dashboard/my/officer`, count: 1 },
    ]

    return (
        <DashboardLayout>
            <div className="min-h-full">
                <header className="bg-gray-50 py-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                        <div className="flex-1 min-w-0">
                            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                                {company.name}
                            </h1>
                            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    {company.address}
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Terdafar pada {moment(company.created, 'YYYY-MM-DD HH:mm:ss').fromNow()} pada {moment(company.created, 'YYYY-MM-DD HH:mm:ss').format('D MMMM, YY')}
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 flex xl:mt-0 xl:ml-4">
                            {false && (
                                <Fragment>
                                    <span className=" sm:block">
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                                        >
                                            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Edit
                                        </button>
                                    </span>

                                    <span className="hidden sm:block ml-3">
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                                        >
                                            <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            View
                                        </button>
                                    </span>
                                </Fragment>
                            )}



                            {/* Dropdown */}
                            <Menu as="span" className="ml-3 relative sm:hidden">
                                <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                    More
                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                >
                                                    Edit
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                >
                                                    View
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </header>

                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <h2 className="text-lg font-medium text-gray-900">Candidates</h2>

                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                </label>
                                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                                    defaultValue={routes.find((route) => route.to === location.pathname)?.to}
                                    onChange={({ target: { value } }) => navigate(value)}
                                >

                                    {routes.map(route =>
                                        <option key={route.to} value={route.to}>
                                            {route.name}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-200">
                                    <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                                        {routes.map(route =>
                                            <NavLink key={route.to} to={route.to} end className={({ isActive }) => classNames(
                                                isActive
                                                    ? 'border-purple-500 text-purple-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                            )}>

                                                {({ isActive }) => (
                                                    <Fragment>
                                                        {route.name}
                                                        {route.count !== null && route.count > 0 && (
                                                            <span className={classNames(
                                                                isActive ? 'bg-purple-100 text-purple-600' : 'bg-gray-100 text-gray-900',
                                                                'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                            )}
                                                            >
                                                                {route.count}
                                                            </span>
                                                        )}
                                                    </Fragment>
                                                )}

                                            </NavLink>
                                        )}
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <Routes>
                            <Route index element={<Information />} />
                            <Route path='officer' element={<DashboardHomeForCompanyBrowseOfficer />} />
                        </Routes>
                    </div>
                </main>
            </div>

        </DashboardLayout>
    )
}

const Information = () => {
    const { company } = useCompanyContext()
    return (
        <div className='mx-3 my-10'>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Informasi Dasar</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi yang diisi saat Bergabung</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Nama Perusahaan</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {company.name}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Alamat</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {company.address}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Bidang Usaha</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {company.companyTypeLabel}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    )
}